import React, { ChangeEvent, useState } from "react";
import styles from "./style.module.scss";
import { MultiSelect, Textarea } from "../../../../components";
import { IQuestion } from "../../../../../interfaces/IAssessmentModals";
import { Button } from "../../../../components";
import { Formik, Form } from "formik";
import { v4 as uuidv4 } from "uuid";
import cancel_icon from "../../../../../assests/svg/cancel_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assests/svg/delete_icon.svg";
import {
  QuestionsInitialValue,
  QuestionsInitialValueObjective,
  AddQuestionsSchemaObjective,
  AddQuestionsSchemaSubjective,
  QuestionsInitialValueList,
  QuestionsInitialValueMultiOptionSelection,
} from "./validator";
import { ReactComponent as CancelBtn } from "../../../../../assests/svg/close.svg";
import { ReactComponent as UpdateIcon } from "../../../../../assests/svg/update_icon.svg";
import { ReactComponent as SearchIcon } from "../../../../../assests/svg/search_icon.svg";
import { REQUEST_TYPE, RQ_KEYS } from "../../../../../utils/constants";
import { useQueryClient } from "react-query";
import { _useMutation } from "../../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../../utils/api";
import { useParams, useSearchParams } from "react-router-dom";
import { Modal } from "@mui/material";
import { multiValueLabelCSS } from "react-select/dist/declarations/src/components/MultiValue";

const ManageQuestions: React.FC<IQuestion> = ({
  onClickCancel,
  heading,
  type,
  data,
  article_id,
  chapter_id,
  openState,
  followupData,
}) => {
  const [questionType, setQuestionType] = useState("Objective");

  console.log("questionType", questionType);
  const isFollowup = followupData?.is_followup;
  const followupOptionId = followupData?.option_id;

  console.log("follow up data", followupData, isFollowup, followupOptionId);

  console.log("data in questions modal", data);

  const option1 =
    type === "UPDATE" && data?.options.length > 0
      ? {
          id: data.options[0].question_number,
          option: data.options[0].option_text,
          option_description: data.options[0].option_description,
        }
      : {
          id: 1,
          option: "NULL",
          option_description: "NULL",
        };

  const option2 =
    type === "UPDATE" && data?.options.length > 0
      ? {
          id: data.options[1].question_number,
          option: data.options[1].option_text,
          option_description: data.options[1].option_description,
        }
      : {
          id: 1,
          option: "NULL",
          option_description: "NULL",
        };

  const options =
    type === "UPDATE" && data?.options.length > 2
      ? data.options.slice(2).map((option: any) => ({
          id: option.option_id,
          option: option.option_text,
          option_description: option.option_description,
        }))
      : [];

  const editQuestionInitialValue =
    data?.question_type === "Objective"
      ? {
          question: data?.question,
          risk_level: data?.risk_level,
          question_type: "Objective",
          question_guidance: data?.question_guidance,
          option1,
          option2,
          options,
        }
      : data?.question_type === "Multi-Option Selection"
      ? {
          question: data?.question,
          risk_level: data?.risk_level,
          question_type: "Multi-Option Selection",
          question_guidance: data?.question_guidance,
          option1,
          option2,
          options,
        }
      : {
          question: data?.question,
          risk_level: data?.risk_level,
          question_type: data?.question_type,
          question_guidance: data?.question_guidance,
          option1,
          option2,
          options,
        };

  console.log("editQuestion initial value", editQuestionInitialValue);

  // const { assessment_id } = useParams();
  const [searchParams] = useSearchParams();
  const assessment_id = searchParams.get("assessment_id");

  const queryClient = useQueryClient();
  const getChaptersMutate = _useMutation({ queryKey: RQ_KEYS.ALL_CHAPTERS });

  const onSuccess = async () => {
    queryClient.refetchQueries({ queryKey: RQ_KEYS.ALL_CHAPTERS });
    // await getChaptersMutate.mutate({
    //   url: API_ROUTE.GET_CHAPTERS_LIST + `/${assessment_id}`,
    //   requestType: REQUEST_TYPE._GET,
    // });
    onClickCancel();
  };
  const updateQuestionMutate = _useMutation({ onSuccess });
  const addQuestionMutate = _useMutation({ onSuccess });

  const handleAddQuestion = (values: any, setFieldValue: any) => {
    const newOptions = [
      ...values.options,
      {
        id: uuidv4(),
        option: "",
        option_description: "",
      },
    ];
    setFieldValue("options", newOptions);
  };

  const handleDeleteOption = (values: any, setFieldValue: any, id: string) => {
    const newOptions = values.options.filter((option: any) => option.id !== id);

    console.log("values in delete option", values, newOptions, id);

    setFieldValue("options", newOptions, true);
  };

  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.addChapterHeader}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>
          <Formik
            initialValues={
              type === "CREATE"
                ? questionType === "Objective"
                  ? QuestionsInitialValueObjective
                  : questionType === "Multi-Option Selection"
                  ? QuestionsInitialValueMultiOptionSelection
                  : questionType === "Subjective"
                  ? QuestionsInitialValue
                  : questionType === "List"
                  ? QuestionsInitialValueList
                  : QuestionsInitialValue
                : editQuestionInitialValue
            }
            validationSchema={
              questionType === "Objective" ||
              questionType === "Multi-Option Selection"
                ? AddQuestionsSchemaObjective
                : AddQuestionsSchemaSubjective
            }
            enableReinitialize={true}
            onSubmit={async (values) => {
              queryClient.setQueryData(RQ_KEYS.LOADING, true);
              console.log("manage questions submit", values);
              const newOptions = values.options.map(
                (option: any, index: number) => ({
                  ...option,
                  option_number: index + 3,
                })
              );
              const option1 = { ...values.option1, option_number: 1 };
              const option2 = { ...values.option2, option_number: 2 };
              if (type === "CREATE") {
                await addQuestionMutate.mutate({
                  url: API_ROUTE.CREATE_QUESTION,
                  requestType: REQUEST_TYPE._POST,
                  requestData: {
                    ...values,
                    option1,
                    option2,
                    options: newOptions,
                    article_id: article_id,
                    assessment_id,
                    chapter_id,
                    isFollowup,
                    followupOptionId,
                    parent_question_id:
                      followupData?.parent_question_id || null,
                  },
                });
              } else {
                await updateQuestionMutate.mutate({
                  url: API_ROUTE.UPDATE_QUESTION,
                  requestType: REQUEST_TYPE._PATCH,
                  requestData: {
                    ...values,
                    option1,
                    option2,
                    options: newOptions,
                    article_id: article_id,
                    assessment_id,
                    chapter_id,
                    question_id: data?.question_id,
                  },
                });
              }
              onClickCancel();
            }}
          >
            {({ handleChange, isValid, values, setFieldValue, handleBlur }) => {
              console.log("values", values);
              return (
                <div className={styles.addChapterRow}>
                  <Form>
                    <div className={styles.userLogin}>
                      <MultiSelect
                        name="question_type"
                        label="Question Type"
                        isRequired={true}
                        placeholder="Select"
                        options={[
                          { value: "Subjective", label: "Subjective" },
                          { value: "Objective", label: "Objective" },
                          { value: "List", label: "List" },
                          {
                            value: "Multi-Option Selection",
                            label: "Multi-Option Selection",
                          },
                        ]}
                        setFieldValue={setFieldValue}
                        onChange={(e: string) => {
                          handleChange(e);
                          setQuestionType(e);
                        }}
                        isMulti={false}
                        defaultValue={{
                          value: values?.question_type,
                          label: values?.question_type,
                        }}
                        values={
                          values.question_type
                            ? {
                                value: values?.question_type,
                                label: values?.question_type,
                              }
                            : ""
                        }
                      />
                      {values?.question_type === "Subjective" && (
                        <div>
                          <div className={styles.userLoginInner}>
                            <Textarea
                              cols={30}
                              rows={3}
                              name="question"
                              label="Question"
                              placeholder="Write a question"
                              isRequired={true}
                              width="100%"
                              padding="5px 10px"
                              maxHeight="155px"
                              onChange={handleChange}
                              value={values.question}
                            />
                          </div>
                          <div className={styles.userLoginInner}>
                            <Textarea
                              cols={30}
                              rows={3}
                              name="question_guidance"
                              label="Question Guidance"
                              placeholder="Type here.."
                              isRequired={false}
                              width="100%"
                              padding="5px 10px"
                              maxHeight="155px"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.question_guidance}
                            />
                          </div>
                          <div className="paddb-5">
                            <MultiSelect
                              name="risk_level"
                              label="Risk Level"
                              //   defaultValue={values?.risk_level}
                              isRequired={false}
                              placeholder="Select"
                              menuPlacement="top"
                              defaultValue={{
                                value: values?.risk_level,
                                label: values?.risk_level,
                              }}
                              options={[
                                { value: "Low", label: "Low" },
                                { value: "Medium", label: "Medium" },
                                { value: "Severe", label: "Severe" },
                              ]}
                              setFieldValue={setFieldValue}
                              onChange={handleChange}
                              values={
                                values.risk_level
                                  ? {
                                      value: values?.risk_level,
                                      label: values?.risk_level,
                                    }
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      )}
                      {values?.question_type === "List" && (
                        <div>
                          <div className={styles.userLoginInner}>
                            <Textarea
                              cols={30}
                              rows={3}
                              name="question"
                              label="Question"
                              placeholder="Write a question"
                              isRequired={true}
                              width="100%"
                              padding="5px 10px"
                              maxHeight="155px"
                              onChange={handleChange}
                              value={values.question}
                            />
                          </div>
                          <div className={styles.userLoginInner}>
                            <Textarea
                              cols={30}
                              rows={3}
                              name="question_guidance"
                              label="Question Guidance"
                              placeholder="Type here.."
                              isRequired={false}
                              width="100%"
                              padding="5px 10px"
                              maxHeight="155px"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.question_guidance}
                            />
                          </div>
                          <div className="paddb-5">
                            <MultiSelect
                              name="risk_level"
                              label="Risk Level"
                              //   defaultValue={values?.risk_level}
                              isRequired={false}
                              placeholder="Select"
                              menuPlacement="top"
                              defaultValue={{
                                value: values?.risk_level,
                                label: values?.risk_level,
                              }}
                              options={[
                                { value: "Low", label: "Low" },
                                { value: "Medium", label: "Medium" },
                                { value: "Severe", label: "Severe" },
                              ]}
                              setFieldValue={setFieldValue}
                              onChange={handleChange}
                              values={
                                values.risk_level
                                  ? {
                                      value: values?.risk_level,
                                      label: values?.risk_level,
                                    }
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      )}
                      {(values?.question_type === "Objective" ||
                        values?.question_type === "Multi-Option Selection") && (
                        <div className={styles.objectiveQuestions}>
                          <div className={styles.userLoginInner}>
                            <Textarea
                              cols={30}
                              rows={3}
                              name="question"
                              label="Question"
                              placeholder="Write a question"
                              isRequired={true}
                              width="100%"
                              padding="5px 10px"
                              maxHeight="155px"
                              onChange={handleChange}
                              value={values.question}
                            />
                          </div>
                          <div className={styles.userLoginInner}>
                            <Textarea
                              cols={30}
                              rows={3}
                              name="question_guidance"
                              label="Question Guidance"
                              placeholder="Type here.."
                              isRequired={false}
                              width="100%"
                              padding="5px 10px"
                              maxHeight="155px"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.question_guidance}
                            />
                          </div>
                          <div className="paddb-5">
                            <MultiSelect
                              name="risk_level"
                              label="Risk Level"
                              //   defaultValue={values?.risk_level}
                              isRequired={false}
                              placeholder="Select"
                              menuPlacement="top"
                              defaultValue={{
                                value: values?.risk_level,
                                label: values?.risk_level,
                              }}
                              options={[
                                { value: "Low", label: "Low" },
                                { value: "Medium", label: "Medium" },
                                { value: "Severe", label: "Severe" },
                              ]}
                              setFieldValue={setFieldValue}
                              onChange={handleChange}
                              values={
                                values.risk_level
                                  ? {
                                      value: values?.risk_level,
                                      label: values?.risk_level,
                                    }
                                  : ""
                              }
                            />
                          </div>
                          <Textarea
                            cols={30}
                            rows={3}
                            name="option1.option"
                            label="Option 1"
                            placeholder="Write an option"
                            isRequired={true}
                            width="100%"
                            padding="5px 10px"
                            maxHeight="155px"
                            onChange={handleChange}
                            value={values?.option1?.option}
                          />
                          <Textarea
                            cols={30}
                            rows={3}
                            name="option1.option_description"
                            label="Option 1 Guidance"
                            placeholder="Type here.."
                            isRequired={false}
                            width="100%"
                            padding="5px 10px"
                            maxHeight="155px"
                            onChange={handleChange}
                            value={values?.option1?.option_description}
                          />

                          <Textarea
                            cols={30}
                            rows={3}
                            name="option2.option"
                            label="Option 2"
                            placeholder="Write an option"
                            isRequired={true}
                            width="100%"
                            padding="5px 10px"
                            maxHeight="155px"
                            onChange={handleChange}
                            value={values?.option2?.option}
                          />
                          <Textarea
                            cols={30}
                            rows={3}
                            name="option2.option_description"
                            label="Option 2 Guidance"
                            placeholder="Type here.."
                            isRequired={false}
                            width="100%"
                            padding="5px 10px"
                            maxHeight="155px"
                            onChange={handleChange}
                            value={values?.option2?.option_description}
                          />

                          {values.options &&
                            values.options.length > 0 &&
                            values?.options?.map(
                              (option: any, index: number) => (
                                <div key={option.id}>
                                  <Textarea
                                    cols={30}
                                    rows={3}
                                    name={`options[${index}].option`}
                                    label={`Option ${index + 3}`}
                                    placeholder="Write an option"
                                    isRequired={true}
                                    width="100%"
                                    padding="5px 10px"
                                    maxHeight="155px"
                                    onChange={handleChange}
                                    value={values?.options[index]?.option}
                                  />
                                  <Textarea
                                    cols={30}
                                    rows={3}
                                    name={`options[${index}].option_description`}
                                    label={`Option ${index + 3} Guidance`}
                                    placeholder="Type here.."
                                    isRequired={false}
                                    width="100%"
                                    padding="5px 10px"
                                    maxHeight="155px"
                                    onChange={handleChange}
                                    value={
                                      values?.options[index]?.option_description
                                    }
                                  />
                                  <div
                                    style={{
                                      marginTop: 10,
                                      display: "flex",
                                      justifyContent: "right",
                                    }}
                                  >
                                    <Button
                                      Icon={<DeleteIcon fill="#ffffff" />}
                                      label="Delete"
                                      id="add"
                                      background="red"
                                      type="button"
                                      onClick={() =>
                                        handleDeleteOption(
                                          values,
                                          setFieldValue,
                                          option.id
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )
                            )}
                          <div
                            style={{ display: "flex", justifyContent: "right" }}
                          >
                            <Button
                              label=" + Add more options"
                              id="add"
                              type="button"
                              onClick={() =>
                                handleAddQuestion(values, setFieldValue)
                              }
                            />
                          </div>
                        </div>
                      )}

                      {/* <div className={styles.userLoginInner}>
                      <InputField
                        name="question_number"
                        type="text"
                        alignItems="left"
                        label="Question Number"
                        placeholder="Enter question number"
                        isRequired={true}
                        display="flex"
                        fontWeight="600"
                        fontSize="12px"
                        gap="5px"
                        value={values.question_number}
                        onChange={handleChange}
                      />
                    </div> */}
                      {/* <div className={styles.userLoginInner}>
                      <Textarea
                        cols={30}
                        rows={3}
                        name="no_task"
                        label='"No " Task'
                        placeholder="Enter a Task"
                        isRequired={true}
                        width="100%"
                        padding="5px 10px"
                        maxHeight="155px"
                        onChange={handleChange}
                        value={values.no_task}
                      />
                    </div> */}
                      {/* <div className={styles.userLoginInner}>
                      <Checkbox
                        name="check_similar_questions"
                        type="checkbox"
                        label="Is similar to another question"
                        isRequired={false}
                        flexDirection="row-reverse"
                        fontSize="13px"
                        gap="5px"
                        onChange={handleChange}
                        value={values?.check_similar_questions}
                      />
                    </div> */}
                      {/* {values && values?.check_similar_questions && (
                      <div className={styles.userLoginInner}>
                        <Textarea
                          cols={30}
                          rows={3}
                          name="similerQuestions"
                          label="Similar to"
                          placeholder="Start typing question..."
                          isRequired={true}
                          width="100%"
                          padding="5px 10px"
                          maxHeight="155px"
                          onChange={handleChange}
                          value={values.similerQuestions}
                          Icon={
                            <SearchIcon
                              fill="var(--textColor)"
                              className="svg_image_icon"
                            />
                          }
                        />
                      </div>
                    )} */}
                    </div>
                    <div className={styles.inviteUserBtn}>
                      <Button
                        label="Cancel"
                        id="cancelbtn"
                        borderRadius="5px"
                        fontWeight="bold"
                        background="var(--whiteColor)"
                        border="1px solid var(--secondaryColor)"
                        color="var(--secondaryColor)"
                        // maxWidth="100px"
                        fontSize="var(--secondaryFont)"
                        Icon={
                          <CancelBtn
                            fill="#0097ce"
                            style={{ maxWidth: "10px" }}
                          />
                        }
                        onClick={onClickCancel}
                      />
                      <Button
                        label={
                          type === "CREATE"
                            ? "+ Add Question"
                            : " Update Question"
                        }
                        id="addChapterbtn"
                        // fontWeight="bold"
                        color="var(--whiteColor)"
                        // maxWidth="150px"
                        fontSize="var(--secondaryFont)"
                        border="none"
                        type="submit"
                        Icon={
                          type !== "CREATE" && (
                            <UpdateIcon
                              fill="#ffffff"
                              style={{ maxWidth: "15px" }}
                            />
                          )
                        }
                      />
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default ManageQuestions;
