import React from "react";
import styles from "./style.module.scss";
import { Dropdown, InputField, MultiSelect } from "../../../../components";
import { IInviteUser } from "../../../../../interfaces/IAssessmentModals";
import { Button } from "../../../../components";
import { Formik, Form } from "formik";
import { ReactComponent as SendInviteIcon } from "../../../../../assests/svg/email.svg";
import { ReactComponent as UpdateIcon } from "../../../../../assests/svg/update_icon.svg";
import { ReactComponent as CancelIcon } from "../../../../../assests/svg/cancel_icon.svg";
import { ReactComponent as CancelBtn } from "../../../../../assests/svg/close.svg";

import { InviteVendorInitialValue, InviteVendorSchema } from "./validator";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../../services/useAxiosQuery";
import {
  LIMIT,
  REQUEST_TYPE,
  RQ_KEYS,
  USER_ROLES,
} from "../../../../../utils/constants";
import { useQueryClient } from "react-query";
import { API_ROUTE } from "../../../../../utils/api";
import Toast from "../../../../components/Toast";
import { ITOAST_STATE } from "../../../../../interfaces/IToastState";
import { Modal } from "@mui/material";

const InviteOrgVendorPopup: React.FC<IInviteUser> = ({
  onClickCancel,
  heading,
  onSubmit,
  data,
  type,
  openState,
}) => {
  console.log("data in invite org user popup", data);
  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });
  const organizationData: any = _getQueryState({
    key: RQ_KEYS.USER_ORGANIZATION,
  });
  console.log("branchlocationsl", organizationData?.branch_locations);
  // useApiQuery({
  //   queryKey: RQ_KEYS.USER_ORGANIZATION,
  //   requestType: REQUEST_TYPE._GET,
  //   url: `${API_ROUTE.GET_USER_ORGANIZATION}`,
  // });
  const locations = useApiQuery({
    queryKey: RQ_KEYS.LOCATION_BY_ORGID,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_LOCATION_BY_ORGID}/${userAuthData?.user_org_id}`,
  });
  console.log("locations", locations);

  /* IMPROVEMENTS DONE>>  Call Refresh Query to fetch the latest data */

  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });

  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div>
          <div className={styles.invitePopupRow1}>
            <p>{heading}</p>
            <CancelIcon className="svg_image_icon" onClick={onClickCancel} />
          </div>
          <Toast toastState={toastState} />
          <Formik
            initialValues={type === "CREATE" ? InviteVendorInitialValue : data}
            validationSchema={InviteVendorSchema}
            onSubmit={async (values) => {
              /* IMPROVEMENTS DONE>>  GET API Does not worked */
              onSubmit && onSubmit(values, organizationData);
            }}
          >
            {({ handleChange, isValid, handleBlur, setFieldValue, values }) => {
              return (
                <div className={styles.invitePopupRow2}>
                  <Form className={styles.invitePopupForm}>
                    <div className={styles.userLogin}>
                      <div className={styles.userLoginInner}>
                        <InputField
                          name="first_name"
                          alignItems="left"
                          type="text"
                          label="First Name"
                          placeholder="Enter First Name"
                          isRequired={true}
                          value={values?.first_name}
                          display="flex"
                          fontWeight="600"
                          fontSize="12px"
                          gap="5px"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className={styles.userLoginInner}>
                        <InputField
                          name="last_name"
                          alignItems="left"
                          type="text"
                          label="Last Name"
                          placeholder="Enter Last Name"
                          isRequired={false}
                          value={values?.last_name}
                          display="flex"
                          fontWeight="600"
                          fontSize="12px"
                          gap="5px"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className={styles.userLoginInner}>
                        <InputField
                          name="email"
                          type="text"
                          alignItems="left"
                          label="Vendor's Email"
                          placeholder="Email ID"
                          isRequired={true}
                          value={values?.email}
                          display="flex"
                          fontWeight="600"
                          fontSize="12px"
                          gap="5px"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={type === "CREATE" ? false : true}
                        />
                      </div>
                      {/* <div className={styles.editOrgInput}>
                      <MultiSelect
                        label="Role"
                        name="role"
                        placeholder="Select"
                        values={
                          values?.role
                            ? { value: values.role, label: values.role }
                            : null
                        }
                        isRequired
                        options={USER_ROLES.filter(
                          (role: any) => role?.parent === "ORG_ADMIN"
                        ).map((role: any) => ({
                          value: role.name,
                          label: role.name,
                        }))}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        isMulti={false}
                      ></MultiSelect>
                    </div> */}
                      {/* <div className={styles.userLoginInner}>
                        <InputField
                          name="vendor_org_name"
                          type="text"
                          alignItems="left"
                          label="Organization Name"
                          placeholder="Organization Name"
                          isRequired={true}
                          value={values?.vendor_org_name}
                          display="flex"
                          fontWeight="600"
                          fontSize="12px"
                          gap="5px"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={type === "CREATE" ? false : true}
                        />
                      </div> */}
                      <div className={styles.userLoginInner}>
                        <MultiSelect
                        label="Office Location"
                        name="branch_name"
                        menuPlacement='top'
                        placeholder="Select Office Location"
                        isRequired
                        values={
                          values?.branch_name
                            ? {
                                value: values.branch_name,
                                label: values.branch_name,
                              }
                            : null
                        }
                        options={organizationData?.branch_locations?.map(
                          (loc: any) => ({
                            label: loc.branch_name,
                            value: loc.branch_name,
                          })
                        )}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        isMulti={false}
                      ></MultiSelect>
                      </div>
                    </div>
                    <div className={styles.inviteUserBtn}>
                      <Button
                        label="Cancel"
                        id="cancelbtn"
                        fontWeight="bold"
                        type="button"
                        // maxWidth="100px"
                        fontSize="var(--secondaryFont)"
                        background="var(--whiteColor)"
                        border="1px solid var(--secondaryColor)"
                        color="var(--secondaryColor)"
                        Icon={
                          <CancelBtn
                            fill="#0097ce"
                            style={{ maxWidth: "12px" }}
                          />
                        }
                        onClick={onClickCancel}
                      />
                      <Button
                        label={
                          type === "CREATE" ? "Send Invitation" : "Update Vendor"
                        }
                        id="invite user btn"
                        fontWeight="bold"
                        color="var(--whiteColor)"
                        // maxWidth="180px"
                        fontSize="var(--secondaryFont)"
                        type="submit"
                        Icon={
                          type === "CREATE" ? (
                            <SendInviteIcon fill="#ffffff" />
                          ) : (
                            <UpdateIcon fill="#ffffff" />
                          )
                        }
                        loading={loadingState}
                        disabled={!isValid || loadingState}
                      />
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
          </div>
          
        </div>
      </div>
      </Modal>
      );
};

      export default InviteOrgVendorPopup;
