import queryString from "query-string";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AssignIcon } from "../../../../../assests/svg/assign.svg";
import { ReactComponent as BackIcon } from "../../../../../assests/svg/backArrow.svg";
import { ReactComponent as EditIcon } from "../../../../../assests/svg/edit_icon.svg";
import { ReactComponent as PlusIcon } from "../../../../../assests/svg/plus.icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assests/svg/delete_icon.svg";
import { ReactComponent as SaveIcon } from "../../../../../assests/svg/save_close.svg";
import { ReactComponent as View } from "../../../../../assests/svg/view_icon.svg";
import { Breadcrumb } from "../../../../../common/Parts";
import AssignAssessmentAndOptions from "../../../../../common/Parts/ModalPopups/QuestionScreenModals/AssignAssessmentAndOptions/index";
import { Button, Table } from "../../../../../common/components";
import LoadingHoc from "../../../../../common/components/LoadingHoc";
import ProgressBar from "../../../../../common/components/ProgressBar";
import Toast from "../../../../../common/components/Toast";
// import { IAssignModal } from "../../../../../interfaces/IModal";
// import { ITOAST_STATE } from "../../../../../interfaces/IToastState";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../../utils/api";
import {
  APP_PATHS,
  APP_ROLES,
  ASSESSMENT_MODAL_NAMES,
  QUESTION_SCREEN_MODAL_NAMES,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../../utils/constants";
import styles from "./style.module.scss";
import DeleteEntry from "../../../../../common/Parts/ModalPopups/AssessmentModals/DeleteEntry";
import { calcBIAProgress } from "../../../../../utils/helpers";
import CustomizedMenus from "../../../../../common/components/MenuList";

const ViewChaptersAndAssignee = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const parsedQuery = queryString.parse(window.location.search);
  const assessment_id = window.atob(parsedQuery?.assessment_id as string);
  const isAssignAssessment = parsedQuery?.isAssignAssessment as string;
  // console.log(">>>>>>> Parsed Query:", parsedQuery);
  console.log("isAssignAssessment", isAssignAssessment);
  // const assessment_id = parsedQuery?.assessment_id;
  //   parsedQuery?.assessment_id && atob(parsedQuery?.assessment_id as string);
  // const assessment_id =
  //   parsedQuery?.assessment_id && atob(parsedQuery?.assessment_id as string);
  console.log(
    "assessment_id inside  ViewChaptersAndAssignee",
    parsedQuery,
    parsedQuery?.assessment_id,
    assessment_id
  );
  const [showModal, setModal] = React.useState<any>({
    state: false,
    modalName: "",
    data: null,
    type: "Task",
  });

  // const [filteredChapters , setFilteredChapters] =React.useState<any>([]);

  // const { mutate: getChapterCompletionStatus } = _useMutation({
  //   onSuccess: (data: any) => {
  //     console.log("ONSUCCESS", data);
  //   },
  // });

  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });
  console.log({ userAuthData });

  let apiUrl =  (isAssignAssessment && isAssignAssessment === 'true') ? `${API_ROUTE.GET_ASSESSMENT_BY_ID}/${assessment_id}/${isAssignAssessment}` : `${API_ROUTE.GET_ASSESSMENT_BY_ID}/${assessment_id}`;
console.log("api>>>>>>",apiUrl)
  let { data: assessment }: any = useApiQuery({
    requestType: REQUEST_TYPE._GET,
    url: apiUrl,
    staleTime: 0,
    queryKey: RQ_KEYS.ASSESSMENT_BY_IDS,
  });
  console.log("veiw assigned", assessment);

  let { data: chapters }: any = useApiQuery({
    queryKey: RQ_KEYS.ALL_CHAPTERS,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_ASSESSMENT_CHAPTERS}/${assessment_id}`,
  });
  console.log({ chapters });

  let { data: answerTable }: any = useApiQuery({
    queryKey: RQ_KEYS.ANSWER_TABLE_BY_CHAPTER,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_ANSWER_TABLE_BY_CHAPTER}?chapter_id=${chapters?.[0]?.assign_chapter_id}`,
    enabled: !!chapters?.[0]?.assign_chapter_id,
  });

  console.log("answer table in veiw assigneded", answerTable);

  const isSaved =
    answerTable?.length > 0 &&
    answerTable?.every(
      ({ assigned_status }: any) => assigned_status === "Saved"
    );

  const isSubmitted = answerTable?.every(
    ({ assigned_status }: any) => assigned_status === "Submitted"
  );

  const isSavedOrSubmitted = answerTable?.every(
    ({ assigned_status }: any) =>
      assigned_status === "Saved" || assigned_status === "Submitted"
  );

  const {
    mutate: updateTableAnswerStatus,
    isLoading: updateTableAnswerStatusLoading,
  } = _useMutation({
    onSuccess: () => {
      queryClient
        .refetchQueries({ queryKey: RQ_KEYS.ANSWER_TABLE_BY_CHAPTER })
        .then();
    },
    queryKey: RQ_KEYS.UPDATE_TABLE_ANSWER_STATUS,
  });

  const firstChapter = chapters?.toSorted(
    (a: any, b: any) => a.assign_chapter_id - b.assign_chapter_id
  )[0];

  let showViewButton = true;

  if (
    userAuthData &&
    userAuthData?.role === APP_ROLES.BRANCH_ADMIN &&
    chapters &&
    chapters.length > 0
  ) {
    chapters = chapters.map((chapter: any) => {
      if (chapter?.chapterAssignees.length > 1) {
        const assignedBool = chapter?.chapterAssignees.filter(
          (assignees: any) => assignees.user_id === userAuthData.user_id
        );

        if (assignedBool.length <= 0) {
          // showViewButton = false;
          chapter.showAssignButton = false;
        } else {
          // showViewButton = true;
          chapter.showAssignButton = false;
        }
      } else if (chapter?.chapterAssignees.length === 1) {
        const assignedBool = chapter?.chapterAssignees.filter(
          (assignees: any) => assignees.user_id === userAuthData?.user_id
        );

        if (assignedBool.length <= 0) {
          // showViewButton = false;
          chapter.showAssignButton = false;
        } else {
          chapter.showAssignButton = true;
        }
      }
      return chapter;
    });
  } else if (
    userAuthData?.role === APP_ROLES.ORG_ADMIN ||
    userAuthData?.role === APP_ROLES.AUDITOR
  ) {
    chapters = chapters?.map((chapter: any) => {
      chapter.showAssignButton = true;
      return chapter;
    });
  }

  // filter chapters for vendor user and org user
  if (
    userAuthData?.role === APP_ROLES.VENDOR_USER ||
    userAuthData?.role === APP_ROLES.ORG_USER
  ) {
    console.log("if vendor or user then chapters", chapters);
    chapters = chapters?.filter((chapter: any) => {
      const isMyAssessment = chapter.chapterAssignees.filter(
        (assignee: any) => assignee.user_id === userAuthData?.user_id
      );
      return isMyAssessment?.length > 0;
    });
  }

  const organizationData: any = _getQueryState({
    key: RQ_KEYS.USER_ORGANIZATION,
  });
  const chapterLoadingState: any = _getQueryState({
    key: `${RQ_KEYS.ALL_CHAPTERS}_loading`,
  });

  const { data: assessmentTypes, isLoading: assessmentTypesIsLoading } =
    useApiQuery({
      queryKey: RQ_KEYS.ASSESSMENT_TYPES,
      requestType: REQUEST_TYPE._GET,
      url: API_ROUTE.GET_ASSESSMENT_TYPES,
    });
  console.log("assessmentTypes", assessmentTypes, assessmentTypesIsLoading);

  const assessmentType = useMemo(() => {
    const foundAssessmentType =
      assessment &&
      assessmentTypes?.length > 0 &&
      assessmentTypes.find(
        (type: any) => type?.assessment_type === assessment?.assessment_name
      );

    if (foundAssessmentType) {
      return foundAssessmentType;
    } else {
      return null;
    }
  }, [assessment, assessmentTypes]);

  console.log("assessmentType", assessmentType);

  const { mutate: cloneArticle, isLoading: cloneArticleIsLoading } =
    _useMutation({
      queryKey: RQ_KEYS.CLONE_CHAPTER,
      onSuccess: () => {
        queryClient.refetchQueries({ queryKey: RQ_KEYS.ALL_CHAPTERS }).then();
      },
    });

  const handleCloneArticle = () => {
    cloneArticle({
      url: API_ROUTE.CLONE_CHAPTER,
      requestType: REQUEST_TYPE._POST,
      requestData: { chapter: chapters[0], chapters_length: chapters.length },
    });
  };

  const isBIA =
    assessment?.assessment_name === "Business Impact Analysis (BIA)";

  const filteredChapters = useMemo(() => {
    if (
      userAuthData?.role !== APP_ROLES.AUDITOR ||
      userAuthData?.role !== APP_ROLES.OBOEDIO_ADMIN
    ) {
      return chapters?.filter(
        (chapter: any) => chapter.total_question_count > 0
      );
    } else {
      return chapters;
    }
  }, [chapters]);
  console.log("🚀 ~ filteredChapters ~ filteredChapters:", filteredChapters);

  const completed_question_count_bia =
    isBIA && chapters
      ? calcBIAProgress(chapters?.[0], answerTable)?.completed_question_count
      : null;

  const total_question_count_bia =
    isBIA && chapters ? chapters[0]?.total_question_count : null;
  console.log(
    "completed_question_count_bia",
    completed_question_count_bia,
    total_question_count_bia
  );

  return (
    <div
      className={`manageAssessmentContainer ${styles.manageAssessmentContainer}`}
    >
      {/* <Toast toastState={toastState} /> */}
      <div className="contentHeader">
        <div className={styles.backBtn}>
          <Button
            label=""
            id="backBtn"
            color="var(--blackColor)"
            background="transparent"
            fontSize="var(--secondaryFont)"
            onClick={() => {
              // navigate(`${APP_PATHS.ASSESSMENT_LIST}`);
              navigate(`${APP_PATHS.ASSESSMENT_DASHBOARD}`);
            }}
            border="none"
            Icon={<BackIcon className="svg_image_icon" />}
          />
        </div>
        <Breadcrumb
          pageName={assessment && assessment?.assessment_name}
          // path={["Assessments", assessment && assessment?.assessment_name]}
          showBtn="true"
          // org_name={organizationData?.org_name}
          //  action={{ buttonOne: <Toast toastState={toastState} /> }}
        />
      </div>
      <div className={styles.topBtnContainer}>
        {userAuthData?.role === APP_ROLES.ORG_ADMIN && (
          <Button
            label="Assign Assessment"
            id="assignToBtn"
            onClick={() => {
              setModal({
                state: true,
                modalName: QUESTION_SCREEN_MODAL_NAMES.ASSIGN,
                type: "Assign_Assessment",
                data: {
                  assessment_name: assessment?.assessment_name,
                  assessment_id,
                  assessmentType,
                },
              });
            }}
            Icon={<AssignIcon fill="#ffffff" />}
          />
        )}
        {assessment?.assessment_name ===
          "Record of Processing Activities(ROPA)" && (
          <div className={styles.ropaButtonContainer}>
            <Button
              label="Add another ROPA business function"
              id="add_ropa"
              type="button"
              Icon={
                <PlusIcon
                  fill="#ffffff"
                  width={50}
                  height={50}
                  className="svg_image_icon"
                />
              }
              padding="6px 20px"
              loading={cloneArticleIsLoading}
              disabled={cloneArticleIsLoading}
              onClick={handleCloneArticle}
            />
          </div>
        )}
      </div>
      <div className={styles.manageAssessmentBody}>
        <div className={styles.adminAssessmentContent}>
          <div className={styles.adminAssessmentTable}>
            <LoadingHoc isLoading={chapterLoadingState}>
              {filteredChapters?.length > 0 ? (
                <Table
                  tableHeaders={[
                    "Chapter ID",
                    "Chapter Name",
                    "Assigned To",
                    "Progress Status",
                  ]}
                  tableData={
                    filteredChapters &&
                    filteredChapters.length > 0 &&
                    filteredChapters.map((item: any, chapterIndex: number) => {
                      let newItem = item;
                      console.log(
                        "🚀 ~ filteredChapters.map ~ newItem:",
                        newItem
                      );
                      // if (
                      //   assessment?.assessment_name ===
                      //     "Business Impact Analysis (BIA)" &&
                      //   answerTable?.length > 0
                      // ) {
                      //   newItem = calcBIAProgress(item, answerTable);
                      // }
                      // console.log("new Item", newItem);

                      // const chapterIDWithIndex = `C${chapterIndex + 1}`;

                      return {
                        chapter_id: newItem.chapter_number
                          ? newItem.chapter_number
                          : `${chapterIndex + 1}`,
                        assign_chapter_id: newItem.assign_chapter_id,
                        chapter_name: newItem.chapter_name,
                        another_assessment_entry_created_by:
                          newItem.another_assessment_entry_created_by,
                        assignee:
                          newItem?.chapterAssignees &&
                          newItem?.chapterAssignees?.length > 0 ? (
                            <div>
                              {newItem?.chapterAssignees.map(
                                ({ assignee }: any, index: number) => (
                                  <div key={assignee}>
                                    {assignee}
                                    {index < newItem.chapterAssignees.length - 1
                                      ? ","
                                      : ""}
                                  </div>
                                )
                              )}
                            </div>
                          ) : null,

                        showAssignButton: newItem.showAssignButton,
                        chapter_completion: (
                          <>
                            <ProgressBar
                              fullStatus={
                                (newItem?.chapter_name === "Detail"
                                  ? newItem?.relevantFieldCount /
                                    newItem?.total_question_count
                                  : newItem?.completed_question_count /
                                    newItem?.total_question_count) *
                                  100 ==
                                100
                                  ? true
                                  : false
                              }
                              progress={`${
                                (newItem?.chapter_name === "Detail"
                                  ? newItem?.relevantFieldCount /
                                    newItem?.total_question_count
                                  : newItem?.completed_question_count /
                                    newItem?.total_question_count) * 100
                              }%`}
                            />{" "}
                            <p
                              className={styles.progressStatusText}
                              style={{ textAlign: "center" }}
                            >{`  ${
                              newItem?.chapter_name === "Detail"
                                ? newItem?.relevantFieldCount
                                : newItem?.completed_question_count
                            } / ${newItem?.total_question_count}`}</p>
                          </>
                        ),
                        chapterAssignees: newItem?.chapterAssignees,
                      };
                    })
                  }
                  hideColumns={[
                    "assessment_id",
                    "disabled",
                    "showAssignButton",
                    "assign_chapter_id",
                    "another_assessment_entry_created_by",
                    "chapterAssignees",
                  ]}
                  showActionWithColumns={[]}
                  action={{
                    name: "Action",
                    buttonOne: (data: any) => {
                      return (
                        <Button
                          label={
                            userAuthData?.role === APP_ROLES.OBOEDIO_ADMIN ||
                            userAuthData?.role === APP_ROLES.AUDITOR
                              ? "View"
                              : "Edit"
                          }
                          id="assignToBtn"
                          disabled={
                            !showViewButton
                            //  ||
                            // (assessment?.assessment_name ===
                            //   "Business Impact Analysis (BIA)" &&
                            //   isSubmitted &&
                            //   userAuthData?.role !== APP_ROLES.ORG_ADMIN)
                          }
                          Icon={
                            userAuthData?.role === APP_ROLES.OBOEDIO_ADMIN ||
                            userAuthData?.role === APP_ROLES.AUDITOR ? (
                              <View fill="#ffffff" />
                            ) : (
                              <EditIcon fill="#ffffff" />
                            )
                          }
                          onClick={() => {
                            queryClient.removeQueries({
                              queryKey: RQ_KEYS.ALL_CHAPTERS,
                            });
                            queryClient.removeQueries({
                              queryKey: RQ_KEYS.QUESTIONS,
                            });
                            queryClient.removeQueries({
                              queryKey: RQ_KEYS.REMEDIATION_TASKS,
                            });
                            queryClient.removeQueries({
                              queryKey: RQ_KEYS.DOCUMENTS,
                            });

                            // remove previous question index value
                            localStorage.removeItem("questionIndex");

                            // navigate(
                            //   `${APP_PATHS.QUESTION_SCREEN}?assessment_id=${
                            //     assessment_id && btoa(assessment_id)
                            //   }&chapter_id=${
                            //     data?.assign_chapter_id &&
                            //     btoa(data?.assign_chapter_id)
                            //   }`
                            // );

                            if (
                              userAuthData?.role === APP_ROLES.ORG_ADMIN ||
                              userAuthData?.role === APP_ROLES.BRANCH_ADMIN ||
                              userAuthData?.role === APP_ROLES.AUDITOR ||
                              userAuthData?.role === APP_ROLES.VENDOR_USER ||
                              userAuthData?.role === APP_ROLES.ORG_USER
                              // userAuthData?.role === APP_ROLES.OBOEDIO_ADMIN
                            ) {
                              navigate(
                                `${APP_PATHS.QUESTION_SCREEN}?assessment_id=${
                                assessment_id && btoa(assessment_id)
                              }&chapter_id=${
                                data?.assign_chapter_id &&
                                btoa(data?.assign_chapter_id)
                              }`
                              );
                            }else if( userAuthData?.role === APP_ROLES.OBOEDIO_ADMIN){
                              navigate(
                                `${APP_PATHS.QUESTION_SCREEN}?assessment_id=${
                                assessment_id && btoa(assessment_id)
                              }&chapter_id=${
                                data?.assign_chapter_id &&
                                btoa(data?.assign_chapter_id)
                              }&isAssignAssessment=true`
                              );
                            }

                          }
                        
                        }
                        />
                      );
                    },
                    buttonTwo: (data: any) => {
                      if (
                        userAuthData?.role !== APP_ROLES.VENDOR_USER &&
                        userAuthData?.role !== APP_ROLES.ORG_USER &&
                        userAuthData?.role !== APP_ROLES.OBOEDIO_ADMIN &&
                        userAuthData?.role !== APP_ROLES.AUDITOR
                      ) {
                        console.log(
                          "data in button 2",
                          data,
                          data?.chapterAssignees
                        );
                        if (
                          data?.chapterAssignees &&
                          data?.chapterAssignees?.length > 0
                        ) {
                          return (
                            <CustomizedMenus
                              actions={[
                                {
                                  label: "Re-Assign",
                                  icon: <AssignIcon fill="var(--whiteColor)" />,
                                  condition: true,
                                  onClick: () => {
                                    setModal({
                                      state: true,
                                      modalName:
                                        QUESTION_SCREEN_MODAL_NAMES.ASSIGN,
                                      type: "Chapter_Reassign",
                                      data: {
                                        assessment_id: assessment_id,
                                        assessment_name:
                                          assessment &&
                                          assessment?.assessment_name,
                                        chapter_name: data?.chapter_name,
                                        chapter_id: data?.assign_chapter_id,
                                      },
                                    });
                                  },
                                },
                              ]}
                            />
                          );
                        } else {
                          return (
                            <CustomizedMenus
                              actions={[
                                {
                                  label: "Assign to",
                                  icon: <AssignIcon fill="var(--whiteColor)" />,
                                  condition: true,
                                  onClick: () => {
                                    setModal({
                                      state: true,
                                      modalName:
                                        QUESTION_SCREEN_MODAL_NAMES.ASSIGN,
                                      type: "Chapter",
                                      data: {
                                        assessment_id: assessment_id,
                                        assessment_name:
                                          assessment &&
                                          assessment?.assessment_name,
                                        chapter_name: data?.chapter_name,
                                        chapter_id: data?.assign_chapter_id,
                                      },
                                    });
                                  },
                                },
                              ]}
                            />
                          );
                        }
                      }
                    },
                    buttonThree: (data: any) => {
                      console.log("data in delete button", data);

                      const ableToDelete =
                        assessment?.assessment_name ===
                          "Record of Processing Activities" &&
                        (data?.another_assessment_entry_created_by ===
                          userAuthData?.user_id ||
                          userAuthData?.role === APP_ROLES.ORG_ADMIN) &&
                        data?.assign_chapter_id !==
                          firstChapter.assign_chapter_id;
                      return (
                        <>
                          {ableToDelete ? (
                            <Button
                              label="Delete Entry"
                              background="red"
                              id="deleteToBtn"
                              // disabled={!showViewButton}
                              Icon={<DeleteIcon fill="#ffffff" />}
                              onClick={() => {
                                setModal({
                                  state: true,
                                  modalName:
                                    ASSESSMENT_MODAL_NAMES.DELETE_CHAPTER,
                                  type: "DELETE",
                                  data,
                                });
                              }}
                            />
                          ) : null}
                        </>
                      );
                    },
                    // buttonFour: (data: any) => {
                    //   console.log("data in submit button", data);

                    //   const isBIA =
                    //     assessment?.assessment_name ===
                    //     "Business Impact Analysis (BIA)";

                    //   return (
                    //     <>
                    //       {isBIA ? (
                    //         <Button
                    //           label="Submit"
                    //           // background="
                    //           id="submitToBtn"
                    //           disabled={
                    //             updateTableAnswerStatusLoading ||
                    //             (isSubmitted &&
                    //               userAuthData?.role !== APP_ROLES.ORG_ADMIN)
                    //           }
                    //           Icon={<SaveIcon fill="#ffffff" />}
                    //           onClick={() => {
                    //             updateTableAnswerStatus({
                    //               url: API_ROUTE.UPDATE_TABLE_ANSWER_STATUS,
                    //               requestType: REQUEST_TYPE._POST,
                    //               requestData: {
                    //                 assigned_chapter_id:
                    //                   chapters?.[0]?.assign_chapter_id,
                    //                 assigned_status: "Submitted",
                    //               },
                    //             });
                    //           }}
                    //         />
                    //       ) : null}
                    //     </>
                    //   );
                    // },
                  }}
                />
              ) : (
                <div className={styles.noChapters}>No Chapters Found.</div>
              )}
            </LoadingHoc>
          </div>
        </div>
      </div>

      {showModal &&
        showModal.state &&
        showModal.modalName === QUESTION_SCREEN_MODAL_NAMES.ASSIGN && (
          <AssignAssessmentAndOptions
            openState={showModal}
            type={showModal.type}
            heading={
              showModal.type === "Task"
                ? "Assign The Task"
                : showModal.type === "Question"
                ? "Assign The Question"
                : showModal.type === "Chapter"
                ? "Assign Chapter"
                : showModal.type === "Task_Reassign"
                ? "Re-Assign the Task"
                : showModal.type === "Question_Reassign"
                ? "Re-Assign The Question"
                : showModal.type === "Chapter_Reassign"
                ? "Re-Assign Chapter"
                : ""
                ? "Assign_Assessment"
                : "Assign Assessment"
            }
            onClickCancel={() => {
              setModal((prev: any) => ({ ...prev, state: false }));
            }}
            data={showModal?.data}
            modalName={showModal.modalName}
          />
        )}
      {showModal &&
        showModal.state &&
        showModal.modalName === ASSESSMENT_MODAL_NAMES.DELETE_CHAPTER && (
          <DeleteEntry
            openState={showModal}
            heading="Delete Entry"
            type={showModal?.type}
            data={showModal?.data}
            onClickCancel={() => {
              setModal((prev: any) => ({ ...prev, state: false }));
            }}
          />
        )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 5,
        }}
      >
        {isBIA &&
          isSavedOrSubmitted &&
          !isSubmitted &&
          completed_question_count_bia >= total_question_count_bia && (
            <p className={styles.submittedText}>
              You have successfully completed the assessment. You can now
              proceed to submit your assessment.
            </p>
          )}

        {isBIA &&
          answerTable?.length > 0 &&
          isSubmitted &&
          completed_question_count_bia >= total_question_count_bia && (
            <p className={styles.submittedText}>
              You have successfully submitted the assessment.
            </p>
          )}
        {answerTable?.length > 0 &&
        isBIA &&
        !chapterLoadingState &&
        completed_question_count_bia >= total_question_count_bia &&
        isSavedOrSubmitted ? (
          <Button
            label="Submit"
            // background="
            id="submitToBtn"
            disabled={updateTableAnswerStatusLoading || isSubmitted}
            Icon={<SaveIcon fill="#ffffff" />}
            onClick={() => {
              updateTableAnswerStatus({
                url: API_ROUTE.UPDATE_TABLE_ANSWER_STATUS,
                requestType: REQUEST_TYPE._POST,
                requestData: {
                  assigned_chapter_id: chapters?.[0]?.assign_chapter_id,
                  assigned_status: "Submitted",
                },
              });
            }}
          />
        ) : null}
      </div>
    </div>
  );
};
export default ViewChaptersAndAssignee;
