import React from "react";
import styles from "./style.module.scss";
import { Dropdown, InputField, SearchBar, Table } from "../../../components";
import { Button } from "../../../components";
import cancel_icon from "../../../../assests/svg/cancel_icon.svg";
import { ReactComponent as CancelBtn } from "../../../../assests/svg/close.svg";
import { ReactComponent as UnselectedIcon } from "../../../../assests/svg/upload_device2.svg";
import { ReactComponent as Library } from "../../../../assests/svg/library.svg";
import { ReactComponent as DeviceIcon } from "../../../../assests/svg/upload_device.svg";
import { ReactComponent as Cloud } from "../../../../assests/svg/uploadDoc.svg";
import { ReactComponent as UploadIcon } from "../../../../assests/svg/upload_icons.svg";
import RadioInput from "../../../components/RadioInput";
import { IQuestion } from "../../../../interfaces/IQuestion";
import UploadDoc from "./UploadDoc/UploadDoc";
import { APP_ROLES, REQUEST_TYPE, RQ_KEYS } from "../../../../utils/constants";
import { useQueryClient } from "react-query";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../utils/api";
import SelectDoc from "./SelectDoc/SelectDoc";
import { Modal } from "@mui/material";
import UploadDocAdmin from "./UploadDoc/UploadDocAdmin";

const UploadFile: React.FC<any> = ({
  heading,
  fileName,
  onClickCancel,
  date,
  userName,
  setShowDocument,
  org_assign_assessment_id,
  uploadImage,
  uploadImageLoading,
  selectDoc,
  uploadSelectLoading,
  openState,
}) => {
  console.log("in upload file", org_assign_assessment_id);
  const [showDevice, setShowDevice] = React.useState<boolean>(true);

  const queryClient = useQueryClient();

  let { data: regions }: any = useApiQuery({
    queryKey: RQ_KEYS.REGIONS,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_REGIONS}`,
  });

  const userData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  const handleSubmitUploadTemplate = (values: any, { replace }: any) => {
    console.log("values in upload template", values);
    const { image, document_category, document_type, region } = values;
    let fd = new FormData();
    fd.append("image", image as any);
    fd.append("document_type", document_type);
    fd.append("document_category", document_category);
    fd.append("region", region ? region : null);
    fd.append("isReplace", replace ? "true" : "false");
    // uploadImage({
    //   url: `${API_ROUTE.UPLOAD_DOCUMENT}`,
    //   Headers: { "Content-Type": "multipart/form-data", Accept: "*/*" },
    //   requestType: REQUEST_TYPE._POST,
    //   requestData: fd,
    // });
    uploadImage({
      url: `${API_ROUTE.UPLOAD_ASESSEMENT_IMAGE}/${org_assign_assessment_id}`,
      Headers: { "Content-Type": "multipart/form-data", Accept: "*/*" },
      requestType: REQUEST_TYPE._POST,
      requestData: fd,
    });
  };

  const handleSubmitSelectDoc = (document_id: any) => {
    selectDoc({
      url: `${API_ROUTE.SELECT_DOCUMENT}/${org_assign_assessment_id}`,
      requestType: REQUEST_TYPE._POST,
      requestData: { document_id },
    });
  };

  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.uploadFileHeader}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>

          <div className={styles.uploadFileBody}>
            <div className={styles.uploadFileBodyInner}>
              <div className={styles.optionContainer}>
                <div
                  className={showDevice ? styles.tabs : styles.shadow}
                  onClick={() => setShowDevice(true)}
                >
                  <DeviceIcon
                    style={{
                      maxWidth: 25,
                      maxHeight: 23,
                      fill: showDevice ? "var(--primaryColor)" : "grey",
                    }}
                  />
                  <p>Select from Device</p>
                </div>
                <div
                  className={showDevice ? styles.shadow : styles.tabs}
                  onClick={() => setShowDevice(false)}
                >
                  <Library
                    style={{
                      fill: showDevice ? "grey" : "var(--primaryColor)",
                      maxWidth: 25,
                      maxHeight: 23,
                    }}
                  />
                  <p>Select from Document Library</p>
                </div>
              </div>

              {showDevice ? (
                <div className={styles.uploadFileRow}>
                  {userData?.role === APP_ROLES.ORG_ADMIN ? (
                    <UploadDocAdmin
                      title="Document"
                      heading="Upload Document"
                      onClickCancel={() => {
                        queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
                          message: "",
                          toastType: "",
                        });
                        setShowDocument(false);
                      }}
                      handleSubmitUploadTemplate={handleSubmitUploadTemplate}
                      uploadImageLoading={uploadImageLoading}
                      regions={regions && regions}
                    />
                  ) : (
                    <UploadDoc
                      title="Document"
                      heading="Upload Document"
                      onClickCancel={() => {
                        queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
                          message: "",
                          toastType: "",
                        });
                        setShowDocument(false);
                      }}
                      handleSubmitUploadTemplate={handleSubmitUploadTemplate}
                      uploadImageLoading={uploadImageLoading}
                    />
                  )}
                </div>
              ) : (
                <SelectDoc
                  onClickCancel={() => setShowDocument(false)}
                  handleSubmitSelectDoc={handleSubmitSelectDoc}
                  uploadSelectLoading={uploadSelectLoading}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadFile;
