import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingHoc: React.FC<any> = ({
  isLoading,
  children,
}: {
  isLoading: boolean;
  children: any;
}) => {
  return isLoading ? (
   
    <div
      className="spinnerContainer"
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow:'visible'
      }}
    >
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    children
  );
};

export default LoadingHoc;
