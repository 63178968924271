const itemsOutOfComplianceColumns = [
  { header: "Assessment Date", key: "assessment_date" },
  { header: "Assessment Name", key: "assessment" },
  { header: "Item Description", key: "item_description" },
  { header: "Compliance Status", key: "compliance_status" },
  {
    header: "Remediation Tasks Outstanding",
    key: "remediation_tasks_outstanding",
  },
  { header: "Risk Score", key: "risk_score" },
  { header: "Task Owner", key: "task_owner" },
];

const itemsInComplianceColumns = [
  { header: "Assessment Date", key: "assessment_date" },
  { header: "Assessment Name", key: "assessment" },
  { header: "Item Description", key: "item_description" },
  { header: "Compliance Status", key: "compliance_status" },
  {
    header: "Remediation Tasks Outstanding",
    key: "remediation_tasks_outstanding",
  },
  { header: "Risk Score", key: "risk_score" },
  { header: "Task Owner", key: "task_owner" },
];

const remediationTasksOutstandingColumns = [
  { header: "Assessment Date", key: "assessment_date" },
  { header: "Assessment Name", key: "regulation" },
  { header: "Item Description", key: "item_description" },
  { header: "Due Date", key: "due_date" },
  { header: "Risk Score", key: "risk_score" },
  { header: "Compliance Status", key: "compliance_status" },
  { header: "Task Owner", key: "task_owner" },
];

const riskAssessmentColumns = [
  { header: "Assessment Date", key: "assessment_date" },
  { header: "Assessment Name", key: "regulation" },
  { header: "Risk Description", key: "risk_description" },
  { header: "Risk Score", key: "risk_score" },
  { header: "Mitigation Actions", key: "mitigation_actions" },
  { header: "Compliance Status", key: "compliance_status" },
  { header: "Assessment Owner", key: "assessment_owner" },
];

const assessmentStatusColumns = [
  { header: "Assessment Date", key: "assessment_date" },
  { header: "Assessment Name", key: "regulation" },
  { header: "Total Items Assessed", key: "total_items_assessed" },
  { header: "Items In Compliance", key: "items_in_compliance" },
  { header: "Items Out of Compliance", key: "items_out_of_compliance" },
  {
    header: "Remediation Tasks Outstanding",
    key: "remediation_tasks_outstanding",
  },
  { header: "Risk Score", key: "risk_score" },
  { header: "Compliance Status", key: "compliance_status" },
  { header: "Task Owner", key: "task_owner" },
];

export const worksheetColumns = {
  itemsOutOfComplianceColumns,
  itemsInComplianceColumns,
  remediationTasksOutstandingColumns,
  riskAssessmentColumns,
  assessmentStatusColumns,
};
