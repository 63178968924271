import React from "react";
import { Bar } from "react-chartjs-2";

interface IBarchartProp {
  chartData?: any;
  title?: string;
}

const BarChart: React.FC<IBarchartProp> = ({ chartData, title }) => {
  return (
    <div>
      <h4>{title}</h4>
      <Bar
        data={chartData}
        options={{
          plugins: {
            title: {
              display: false,
              text: "Users Gained between 2016-2020",
            },
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};

export default BarChart;
