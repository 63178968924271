import React, { useEffect, useRef, useState } from "react";
import fileDownload from "js-file-download";
import { Breadcrumb } from "../../../../common/Parts";
import styles from "./style.module.scss";
import { Button, SearchBar, Table } from "../../../../common/components";
import {
  APP_ROLES,
  ASSESSMENT_MODAL_NAMES,
  AWS_DOCUMENT_BASE,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../utils/constants";
import { Dropdown } from "../../../../common/components";
import { ReactComponent as DownloadIcon } from "../../../../assests/svg/download.svg";
import { ReactComponent as DeleteIcon } from "../../../../assests/svg/delete_icon.svg";
import { ReactComponent as UploadIcon } from "../../../../assests/svg/upload_icons.svg";
import { Spinner } from "react-bootstrap";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../utils/api";
import { useQueryClient } from "react-query";
import Toast from "../../../../common/components/Toast";
import { ITOAST_STATE } from "../../../../interfaces/IToastState";
import { IModal } from "../../../../interfaces/IModal";
import DeleteTemplate from "../../../../common/Parts/ModalPopups/AssessmentModals/DeleteTemplate";
import UploadDocument from "../../../../common/Parts/ModalPopups/UploadDocument";
import useDebounce from "../../../../hooks/useDebounce";
import { localeDate } from "../../../../utils/helpers";
import DeleteDocument from "../../../../common/Parts/ModalPopups/AssessmentModals/DeleteDocumentModal";
import { Pagination } from "../../../../common/components/Pagination";

const LIMIT = 5;

const VendorDocuments = () => {
  const countRef = useRef(0);
  const [showDocument, setShowDocument] = React.useState(false);
  const [searchKey, setSearchKey] = React.useState<string>("null");
  const [templateType, setTemplateType] = React.useState<string>("all");
  const [templateCategory, setTemplateCategory] = React.useState<string>("all");
  const [showDeleteModal, setDeleteModal] = React.useState<IModal>({
    state: false,
    modalName: "",
    data: null,
    type: "DELETE",
  });

  const [myDocDataLimit, setMyDocDataLimit] = useState<any>({
    limit: LIMIT,
    offset: 0,
  });

  const [orgDocDataLimit, setOrgDocDataLimit] = useState<any>({
    limit: LIMIT,
    offset: 0,
  });

  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  // const toastState: ITOAST_STATE | any = _getQueryState({
  //   key: RQ_KEYS.TOAST_STATE,
  // });

  const userData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  useEffect(() => {
    countRef.current++;
  }, []);

  const queryClient = useQueryClient();
  const { data: assessmentTypes } = useApiQuery({
    queryKey: RQ_KEYS.ASSESSMENT_TYPES,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_ASSESSMENT_TYPES,
  });

  const assessmentTypesList = assessmentTypes?.map(
    (type: { assessment_type: string }) => ({
      name: type.assessment_type,
      value: type.assessment_type,
    })
  );
  let templates: any = useApiQuery({
    queryKey: RQ_KEYS.VENDOR_DOCUMENTS,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_VENDOR_DOCUMENTS}?search=${searchKey}&type=all&category=all&limit=${LIMIT}&offset=${myDocDataLimit.offset}`,
  });

  console.log("templates", templates);

  let { data: orgDocuments, isLoading: orgDocumentsIsLoading }: any =
    useApiQuery({
      queryKey: RQ_KEYS.ORG_DOCUMENTS,
      requestType: REQUEST_TYPE._GET,
      url: `${API_ROUTE.GET_ORG_DOCUMENTS}?search=${searchKey}&type=all&category=all&limit=${LIMIT}&offset=${orgDocDataLimit.offset}`,
    });

  const getTemplates = _useMutation({ queryKey: RQ_KEYS.VENDOR_DOCUMENTS });

  const getOrgDocuments = _useMutation({ queryKey: RQ_KEYS.ORG_DOCUMENTS });

  const searchQuery = useDebounce(searchKey, 1000);

  React.useMemo(() => {
    if (countRef.current === 0) {
      return;
    }
    if (searchQuery || searchKey.length < 0) {
      getTemplates.mutate({
        url: `${API_ROUTE.GET_VENDOR_DOCUMENTS}?search=${searchKey}&type=${templateType}&category=${templateCategory}&limit=${LIMIT}&offset=${myDocDataLimit.offset}`,
        requestType: REQUEST_TYPE._GET,
      });
      getOrgDocuments.mutate({
        url: `${API_ROUTE.GET_ORG_DOCUMENTS}?search=${searchKey}&type=${templateType}&category=${templateCategory}&limit=${LIMIT}&offset=${orgDocDataLimit.offset}`,
        requestType: REQUEST_TYPE._GET,
      });
    } else if (searchKey.length === 0) {
      getTemplates.mutate({
        url: `${API_ROUTE.GET_VENDOR_DOCUMENTS}?search=null&type=${templateType}&category=${templateCategory}&limit=${LIMIT}&offset=${myDocDataLimit.offset}`,
        requestType: REQUEST_TYPE._GET,
      });
      getOrgDocuments.mutate({
        url: `${API_ROUTE.GET_ORG_DOCUMENTS}?search=null&type=${templateType}&category=${templateCategory}&limit=${LIMIT}&offset=${orgDocDataLimit.offset}`,
        requestType: REQUEST_TYPE._GET,
      });
    }
  }, [searchQuery, templateCategory, templateType]);

  const totalCount = templates?.data?.totalCount;
  const data =
    templates?.data?.data?.length > 0
      ? templates?.data?.data?.map(
          ({
            file_original_name,
            file_created_at,
            file_signed_url,
            document_id,
            document_type,
            document_category,
            uploaded_by,
          }: any) => {
            return {
              file_original_name,
              document_type,
              document_category,
              // file_created_at: localeDate(file_created_at),
              uploaded_by,
              file_created_at: localeDate(file_created_at),
              file_signed_url,
              document_id,
            };
          }
        )
      : [];

  const onUploadDeleteSuccess = () => {
    queryClient.refetchQueries({ queryKey: RQ_KEYS.TEMPLATES }).then();
  };

  const handleDeleteImage = (document_id: any) => {
    setDeleteModal({
      state: true,
      modalName: ASSESSMENT_MODAL_NAMES.DELETE_DOCUMENT,
      type: "DELETE",
      data: document_id,
    });
  };

  const { mutate: uploadImage, isLoading: uploadImageLoading } = _useMutation({
    onSuccess: () => {
      queryClient
        .refetchQueries({ queryKey: RQ_KEYS.VENDOR_DOCUMENTS })
        .then((data: any) => {
          setShowDocument(false);
        });
    },
    queryKey: RQ_KEYS.UPLOAD_VENDOR_DOCUMENT,
  });

  const handleSubmitUploadTemplate = (values: any) => {
    console.log("values in upload template", values);
    const { image, document_category, document_type } = values;
    let fd = new FormData();
    fd.append("image", image as any);
    fd.append("document_type", document_type);
    fd.append("document_category", document_category);
    uploadImage({
      url: `${API_ROUTE.UPLOAD_VENDOR_DOCUMENT}`,
      Headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
      requestType: REQUEST_TYPE._POST,
      requestData: fd,
    });
  };

  const onPaginzationChangeMyDoc = ({ offset }: any) => {
    setMyDocDataLimit((prev: any) => ({
      ...prev,
      offset,
    }));
    getTemplates.mutate({
      url: `${API_ROUTE.GET_VENDOR_DOCUMENTS}?search=${searchKey}&type=${templateType}&category=${templateCategory}&limit=${LIMIT}&offset=${offset}`,
      requestType: REQUEST_TYPE._GET,
    });
  };

  return (
    <React.Fragment>
      <div
        className={`manageAssessmentContainer ${styles.manageAssessmentContainer}`}
      >
        <div className={styles.breadCrumb}>
          <Breadcrumb pageName="Documents" 
          // path={["Dashboard", "Documents"]} 
          />
          {(userData?.role === APP_ROLES.ORG_ADMIN ||
            userData?.role === APP_ROLES.BRANCH_ADMIN) && (
            <div className={styles.fileInput}>
              <Button
                Icon={<UploadIcon fill="#ffffff" />}
                label="Upload Document"
                id="addDocLibrary"
                onClick={() => setShowDocument(true)}
              />
            </div>
          )}
        </div>

        <div className={styles.manageAssessmentBody}>
          <div className={styles.assessmentRow}>
            <div className={styles.filtrSearchDiv}>
              <Dropdown
                label="Document type"
                dropdownData={[
                  { name: "All", value: "all" },
                  {
                    name: "Policies",
                    value: "Policies",
                  },
                  {
                    name: "Procedures",
                    value: "Procedures",
                  },
                  {
                    name: "Diagrams",
                    value: "Diagrams",
                  },
                ]}
                onClick={(data) => {
                  setTemplateType(data.value);
                }}
              />
              <Dropdown
                label="Document Category"
                dropdownData={
                  assessmentTypesList && assessmentTypesList.length > 0
                    ? [{ name: "All", value: "all" }, ...assessmentTypesList]
                    : [{ name: "All", value: "all" }]
                }
                onClick={(data) => {
                  console.log("data in filter", data);
                  setTemplateCategory(data.value);
                }}
              />
            </div>
            <div className={styles.searchContainer}>
              <SearchBar
                type="text"
                placeholder="Search"
                isRequired={false}
                name="searchBar"
                display="flex"
                onChange={(event: any) => setSearchKey(event.target.value)}
              />
            </div>
          </div>

          {userData?.role === APP_ROLES.VENDOR_USER && (
            <div className={styles.assessmentheader}>
              <h4>My Documents</h4>
            </div>
          )}
          <div className={styles.assessmentMain}>
            {!loadingState && !templates.isLoading ? (
              <>
                <Table
                  tableHeaders={[
                    "Document",
                    "Type",
                    "Category",
                    "Uploaded By",
                    "Date Uploaded",
                  ]}
                  tableData={data}
                  hideColumns={["document_id", "file_signed_url", "file_type"]}
                  // showActionWithColumns={[]}
                  action={{
                    name: "Action",
                    // buttonOne: (data: any) => {
                    //   return (
                    //     <ViewIcon
                    //       fill="#000000"
                    //       className="svg_image_icon"
                    //       onClick={() => {
                    //         // navigate(
                    //         //   `${APP_PATHS.CHAPTER}/${data?.assessment_id}`
                    //         // );
                    //       }}
                    //     />
                    //   );
                    // },
                    buttonOne: (data: any) => {
                      return (
                        <DownloadIcon
                          fill="#000000"
                          className="svg_image_icon"
                          onClick={() => {
                            console.log("data in button one", data);
                            fileDownload(
                              `${AWS_DOCUMENT_BASE}${data.file_signed_url}`,
                              data.file_original_name
                            );
                            // navigate(
                            //   `${APP_PATHS.CHAPTER}/${data?.assessment_id}`
                            // );
                          }}
                        />
                      );
                    },
                    buttonTwo:
                      userData && userData.role === APP_ROLES.ORG_ADMIN
                        ? (data: any) => {
                            console.log("data in button two", data);
                            return (
                              <DeleteIcon
                                fill="#000000"
                                className="svg_image_icon"
                                onClick={() =>
                                  handleDeleteImage(data.assessment_document_id)
                                }
                              />
                            );
                          }
                        : null,
                  }}
                />
                <Pagination
                  data={data}
                  totalCount={totalCount}
                  onPaginzationChange={onPaginzationChangeMyDoc}
                  setDataLimit={setMyDocDataLimit}
                  dataLimit={myDocDataLimit}
                />
              </>
            ) : (
              <div className={styles.assesmentLoader}>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!loadingState && !templates.isLoading && data?.length === 0 && (
              <div style={{ margin: "30px", textAlign: "center" }}>
                No Documents Found
              </div>
            )}
          </div>
        </div>
        {/* {showDocument && (
          <UploadFile onClickCancel={() => setShowDocument(false)} />
        )} */}
        {/* <Toast toastState={toastState} /> */}
      </div>
      {showDeleteModal &&
        showDeleteModal.state &&
        showDeleteModal.modalName ===
          ASSESSMENT_MODAL_NAMES.DELETE_DOCUMENT && (
          <DeleteDocument
            openState={showDeleteModal}
            heading="Delete Document"
            type={showDeleteModal?.type}
            data={showDeleteModal.data}
            onClickCancel={() => {
              setDeleteModal((prev) => ({ ...prev, state: false }));
            }}
            onSuccess={onUploadDeleteSuccess}
          />
        )}
      <UploadDocument
        heading="Upload Document"
        openState={showDocument}
        Title="Document"
        onClickCancel={() => {
          queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
            message: "",
            toastType: "",
          });
          setShowDocument(false);
        }}
        handleSubmitUploadTemplate={handleSubmitUploadTemplate}
        uploadImageLoading={uploadImageLoading}
      />
    </React.Fragment>
  );
};

export default VendorDocuments;
