import React from "react";
import { Breadcrumb } from "../../../../../common/Parts";
import styles from "./style.module.scss";
import { Button, Table } from "../../../../../common/components";
import { ReactComponent as BackIcon } from "../../../../../assests/svg/backArrow.svg";
import {
  APP_PATHS,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../../utils/constants";
import { useApiQuery } from "../../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../../utils/api";
import { useNavigate, useParams } from "react-router-dom";

const ViewVendorDetails: React.FC = () => {
  const { user_id } = useParams();
  const navigate = useNavigate();

  let { data } = useApiQuery({
    queryKey: RQ_KEYS.ORG_USER_BY_ID,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_ORG_VENDOR_BY_ID + `/${user_id}`,
    staleTime: 1000,
  });
  console.log("daata in view user detials ", data);

  return (
    <React.Fragment>
      <div className={styles.editOrganisationContainer}>
        <div className="contentHeader">
          <div className={styles.backBtn}>
            <Button
              label=""
              id="backBtn"
              color="var(--blackColor)"
              background="transparent"
              fontSize="var(--secondaryFont)"
              onClick={() => {
                navigate(`${APP_PATHS.MANAGE_VENDORS}`);
              }}
              border="none"
              Icon={<BackIcon className="svg_image_icon" />}
            />
          </div>
          <Breadcrumb
            pageName="Manage User"
            // path={[
            //   "Dashboard",
            //   "Organizations",
            //   "View Vendor",
            //   "User ID:" + user_id,
            // ]}
            // org_name={data?.vendor_org_name}
          />
        </div>
        <div className={styles.editOrganisationBody}>
          <div className={styles.login}>
            <div className={styles.loginSection1}>
              <div className={styles.loginForm}>
                <div className={styles.loginFormInner}>
                  <h2>View Vendor Details</h2>
                  <h3>Organization Vendor Details</h3>
                  <div className={styles.flexEditOrg}>
                    <div className={styles.editOrgInput}>
                      <label>User ID: </label>
                      <p>{data?.user_id ? data?.user_id : "N/A"}</p>
                    </div>
                    <div className={styles.editOrgInput}>
                      <label>First Name: </label>
                      <p>{data?.first_name ? data?.first_name : "N/A"}</p>
                    </div>
                    <div className={styles.editOrgInput}>
                      <label>Last Name: </label>
                      <p>{data?.last_name ? data?.last_name : "N/A"}</p>
                    </div>
                    <div className={styles.editOrgInput}>
                      <label>Email: </label>
                      <p>{data?.email ? data?.email : "N/A"}</p>
                    </div>
                    <div className={styles.editOrgInput}>
                      <label>Organization Name: </label>
                      <p>
                        {data?.vendor_org_name ? data?.vendor_org_name : "N/A"}
                      </p>
                    </div>
                  </div>

                  <div className={styles.flexEditOrg}>
                    <div className={styles.editOrgInput}>
                      <label>User Status: </label>
                      <p>{data?.is_active ? data?.is_active : "N/A"}</p>
                    </div>
                  </div>
                  <div className={styles.flexEditOrg}>
                    <div className={styles.editOrgInput}>
                      <label>Created At: </label>
                      {/* <p>{values?.assessments}</p>assessments */}
                      <p>{data?.created_at ? data?.created_at : "N/A"}</p>
                    </div>
                  </div>
                  {/* <div className={styles.flexEditLocation}>
                    <h4>Office Location</h4>

                    <div
                      className={`${styles.flexEditOrg} ${styles.editLoaction}`}
                    >
                      <div className={styles.regionBranch}>Office :</div>
                      <div
                        className={`${styles.editOrgInput} ${styles.editOrgRadio}`}
                      >
                        <label>Region: </label>
                        <p>{data?.region ? data?.region : "N/A"}</p>
                      </div>
                      <div className={styles.editOrgInput}>
                        <label>Country: </label>
                        <p>{data?.country ? data?.country : "N/A"}</p>
                      </div>
                      <div
                        className={`${styles.editOrgInput} ${styles.editOrgRadio}`}
                      >
                        <label>State: </label>
                        <p>
                          {data?.state && data?.state !== "null"
                            ? data?.state
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/* // In this table we will show all the assessments assigned
                    //to user */}
                  {/* <hr />
                  <div className={styles.assessmentBtnHead}>
                    <h4>Assessments Assigned</h4>
                  </div>
                  <div className={styles.tableEditOrg}>
                    {data?.org_assessments &&
                    data?.org_assessments?.length > 0 ? (
                      <Table
                        tableHeaders={[
                          "Region",
                          "Assessment Name",
                          "Jurisdiction",
                        ]}
                        tableData={data.org_assessments}
                        hideColumns={["assessment_id", "org_assessment_id"]}
                        action={{
                          name: "Action",

                          buttonOne: (data: any) => {
                            return (
                              data && (
                                <Tooltip text="Remove">
                                  <DeleteIcon
                                    fill="#000000"
                                    className="svg_image_icon"
                                    onClick={async () => {
                                      setModal({
                                        state: true,
                                        modalName:
                                          ASSESSMENT_MODAL_NAMES.DELETE_USER_ASSESSMENT,
                                        type: "DELETE",
                                      });
                                      //
                                      setOrgAssessmentId(
                                        data?.org_assessment_id
                                      );
                                    }}
                                  />
                                </Tooltip>
                              )
                            );
                          },
                          buttonTwo: (data: any) => {
                            return (
                              data && (
                                <Tooltip text="View">
                                  <ViewIcon
                                    fill="#000000"
                                    className="svg_image_icon"
                                    onClick={() => {
                                      navigate(
                                        `${APP_PATHS.CHAPTER.replace(
                                          ":assessment_id",
                                          data?.assessment_id
                                        )}`
                                      );
                                    }}
                                  />
                                </Tooltip>
                              )
                            );
                          },
                        }}
                      />
                    ) : (
                      <p style={{ alignSelf: "baseline" }}>
                        No Assessments added yet
                      </p>
                    )}
                  </div>
                  <hr />
                  <h3>Organization Admin Details</h3>
                  <div className={styles.flexEditOrg}>
                    <div className={styles.editOrgInput}>
                      <label>First Name: </label>
                      <p>
                        {data?.admin_first_name ? data?.admin_first_name : "N/A"}
                      </p>
                    </div>
                    <div className={styles.editOrgInput}>
                      <label>Last Name: </label>
                      <p>
                        {data?.admin_last_name ? data?.admin_last_name : "N/A"}
                      </p>
                    </div>
                  </div>
                  <div className={styles.flexEditOrg}>
                    <div className={styles.editOrgInput}>
                      <label>Admin Email: </label>
                      <p>{data?.admin_email ? data?.admin_email : "N/A"}</p>
                    </div>
                    <div className={styles.editOrgInput}>
                      <label>Admin Status: </label>
                      <p>
                        {data?.admin_is_active ? data?.admin_is_active : "N/A"}
                      </p>
                    </div>
                  </div> */}

                  <div className={styles.formInnerBtnSection}></div>
                </div>
              </div>
            </div>
          </div>
          {/* ); */}
        </div>
      </div>
    </React.Fragment>
  );
};
export default React.memo(ViewVendorDetails);
