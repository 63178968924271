import React, { useEffect } from "react";
import styles from "./style.module.scss";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import { useQueryClient } from "react-query";
import {
  LOCALSTORAGE,
  RQ_KEYS,
  APP_PATHS,
  APP_ROLES,
} from "../../../utils/constants";
import UserProfile from "../Userprofile";
import LogoutModal from "../ModalPopups/LogoutModal/index";

const SidebarClose: React.FC<any> = ({
  sidebarData,
  onClick,
  userAuthData,
  onClickCancel,
  // onClickOpen,
}) => {
  const [showOption, setShowOption] = React.useState<boolean[]>(
    Array(sidebarData?.length).fill(false)
    // .map((_, idx) => (idx === 0 ? true : false))
  );
  const [showPopup, setShowPopup] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // const handleMainIconClick = (index: number) => {
  //   const newShowOption = Array(sidebarData?.length).fill(false);
  //   newShowOption[index] = !showOption[index];
  //   setShowOption(newShowOption);

  //   // If opening the section, show the first option by default
  //   if (!showOption[index] && sidebarData[index].options.length > 0) {
  //     const firstOptionPath = sidebarData[index].options[0].path;
  //     navigate(firstOptionPath);
  //   }
  // };

  const handleMainIconClick = (index: number) => {
    if (sidebarData[index].options.length > 0) {
      const firstOptionPath = sidebarData[index].options[0].path;
      navigate(firstOptionPath);
    }
  };

  console.log("userAuthData in sidebar", userAuthData);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    sidebarData?.forEach((data: any, idx: any): any => {
      const foundIdx = data.options.findIndex(
        (d: any) => d.path === location.pathname
      );
      if (foundIdx >= 0) {
        setShowOption(showOption.map((_, i) => i === idx));
      }
    });
  }, [location.pathname, sidebarData]);

  console.log("location 1", location);
  const handleLogout = () => {
    if (localStorage.getItem("supportusertoken")) {
      const token: any = localStorage.getItem("supportusertoken");
      localStorage.setItem("token", token);
      localStorage.removeItem("supportusertoken");
      navigate(APP_PATHS.ORGADMIN_LIST);
      window.location.reload();
    } else {
      localStorage.removeItem(LOCALSTORAGE.TOKEN);
      queryClient.removeQueries(RQ_KEYS.AUTH);
      queryClient.removeQueries(RQ_KEYS.USER_DATA);
      navigate(0);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div className={styles.sideBar}>
        {isModalOpen === true ? (
          <LogoutModal
            onClickCancel={closeModal}
            onClickLogouut={handleLogout}
            openState={isModalOpen}
          />
        ) : (
          <p></p>
        )}
        <div className={styles.sideBarInner}>
          <div className={styles.dashBoardList}>
            <ul>
              {sidebarData &&
                sidebarData?.length > 0 &&
                sidebarData?.map(
                  ({ section, options, Icon }: any, index: number) => {
                    return (
                      <>
                        <li key={index}>
                          <div
                            className={styles.arrow}
                            // onClick={() => handleMainIconClick(index)}
                            onClick={() => {
                              console.log(">>>>> Section", section);
                              if (section === "Account") {
                                openModal();
                              } else {
                                handleMainIconClick(index);
                              }
                            }}
                          >
                            <p className={styles.section}>
                              {Icon && Icon(true)}
                            </p>
                          </div>
                          {/* Remove or comment out this section to hide nested options */}
                          {/* {showOption[index] &&
                    options.map(
                      ({ path, Icon, title, isClickable }: any) => {
                        // ... option rendering code
                    })} */}
                        </li>
                      </>
                    );
                  }
                )}
            </ul>

          </div>
        </div>
      </div>
      {showPopup && (
        <UserProfile
          heading="User Account"
          onClickCancel={() => setShowPopup(false)}
        />
      )}
    </>
  );
};

export default SidebarClose;
