import React, { useEffect } from "react";
import styles from "./style.module.scss";
import { Button, Checkbox } from "../../../components";
import { ISteps } from "../../../../interfaces/ISteps";
import { ReactComponent as LeftArrowIcon } from "../../../../assests/svg/backArrow.svg";
import { ReactComponent as CrossIcon } from "../../../../assests/svg/close.svg";
import { ReactComponent as RightArrowIcon } from "../../../../assests/svg/next_arrow_fill.svg";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import Accordion from "react-bootstrap/Accordion";
import { REQUEST_TYPE, RQ_KEYS } from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";

const StepFour_OrgAssessments: React.FC<ISteps> = ({
  prevPageHandler,
  nextPageHandler,
  values,
  setFieldValue,
}) => {
  const categoryList = useApiQuery({
    queryKey: RQ_KEYS.ASSESSMENT_CATEGORIES,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_ASSESSMENT_CATEGORY,
  });
  console.log("values in step 4", values);
  const getAssessments = _useMutation({
    queryKey: RQ_KEYS.FILTERED_ASSESSMENT_LIST,
  });
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });

  const [checked, setChecked] = React.useState<boolean>(true);
  const [state, setState] = React.useState<any>({
    filterRegion: [],
    filterCountry: [],
    filterState: [],
    filterIndustries: [],
    filterCategory: [],
  });

  const filteredAssessments: any = _getQueryState({
    key: RQ_KEYS.FILTERED_ASSESSMENT_LIST,
  });
  console.log("filteredAssessments", filteredAssessments);
  useEffect(() => {
    setState((prev: any) => ({
      ...prev,
      filterRegion:
        values && values.location && values.location.length > 0
          ? values.location.map(({ region }: any) => region)
          : [],
      filterIndustries:values && values.org_industries && values.org_industries.length > 0
          ?  values?.org_industries?.map(
            (ind: any) => ind.value
          ) : [],
    }));
    getAssessments.mutate({
      requestType: REQUEST_TYPE._POST,
      url: API_ROUTE.GET_FILTERED_ASSESSMENT_LIST,
      requestData: {
        filterRegion:
          values && values.location && values.location.length > 0
            ? values.location.map(({ region }: any) => region)
            : [],
        filterIndustries:values && values.org_industries && values.org_industries.length > 0
            ?  values?.org_industries?.map(
              (ind: any) => ind.value
            ) : [],    
      },
    });
  }, []);
  const onSelectFilter = ({ type, value, checked }: any) => {
    if (checked) {
      setState((prev: any) => ({ ...prev, [type]: [...prev[type], value] }));
      getAssessments.mutate({
        requestType: REQUEST_TYPE._POST,
        url: API_ROUTE.GET_FILTERED_ASSESSMENT_LIST,
        requestData: {
          // filterIndustries: values?.org_industries?.map(
          //   (ind: any) => ind.value
          // ),
          ...state,
          [type]: [...state[type], value],
        },
      });
    } else {
      onUnSelectFilter({ type, value });
    }
  };

  const onUnSelectFilter = async ({ type, value }: any) => {
    const unSelectedFilter = state[type].filter(
      (item: string) => item !== value
    );
    setState((prev: any) => ({
      ...prev,
      [type]: unSelectedFilter,
    }));
    getAssessments.mutate({
      requestType: REQUEST_TYPE._POST,
      url: API_ROUTE.GET_FILTERED_ASSESSMENT_LIST,
      requestData: {
        // filterIndustries: values?.org_industries?.map((ind: any) => ind.value),
        ...state,
        [type]: unSelectedFilter,
      },
    });
  };
  console.log("KL", values);
  return (
    <div className={styles.stepCommonInner}>
      <div className={styles.selectAssessContainer}>
        <div className={styles.regionCountAssess}>
          <div className={styles.regionCountDiv}>
            <Accordion
              className={styles.accordionContainer}
              defaultActiveKey={["0", "1", "2", "3"]}
              alwaysOpen
            >
              {/* <div className={styles.filterDiv}> */}
              <h2>Filter By:</h2>
              <Button
                type="button"
                label="Reset Filter"
                id="resetbtn"
                background="var(--primaryColor)"
                border="1px solid var(--primaryColor)"
                color="var(--whiteColor)"
                fontSize="var(--secondaryFont)"
                fontWeight="600"
                padding="3px 8px"
                borderRadius="5px"
                Icon={
                  <CrossIcon
                    fill="var(--whiteColor)"
                    style={{ maxWidth: "10px" }}
                  />
                }
                onClick={() => {
                  setState({
                    filterRegion: [],
                    filterCountry: [],
                    filterState: [],
                    filterIndustries: [],
                    filterCategory: [],
                  });
                  getAssessments.mutate({
                    requestType: REQUEST_TYPE._POST,
                    url: API_ROUTE.GET_FILTERED_ASSESSMENT_LIST,
                    // requestData: {
                    //   filterIndustries: values?.org_industries?.map(
                    //     (ind: any) => ind.value
                    //   ),
                    // },
                  });
                }}
              />
              {/* </div> */}
              <Accordion.Item className={styles.accItem} eventKey="0">
                <Accordion.Header>Region</Accordion.Header>
                <Accordion.Body className={styles.accBody}>
                  {values && values.location && values.location.length > 0
                    ? values.location.map(({ region }: any, index: number) => (
                        <Checkbox
                          key={index}
                          name={region}
                          type="checkbox"
                          label={region}
                          isRequired={false}
                          flexDirection="row-reverse"
                          gap="5px"
                          checked={
                            state["filterRegion"] &&
                            state["filterRegion"].length > 0 &&
                            state["filterRegion"].includes(region)
                          }
                          onChange={(e: any) =>
                            onSelectFilter({
                              type: "filterRegion",
                              value: region,
                              checked: e.target.checked,
                            })
                          }
                        />
                      ))
                    : null}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className={styles.accItem} eventKey="1">
                <Accordion.Header>Country</Accordion.Header>
                <Accordion.Body className={styles.accBody}>
                  {values && values.location && values.location.length > 0
                    ? values.location.map(({ country }: any, index: number) =>
                      country && (
                        <Checkbox
                          key={index}
                          name={country}
                          type="checkbox"
                          label={country}
                          isRequired={false}
                          flexDirection="row-reverse"
                          gap="5px"
                          onChange={(e: any) => {
                            onSelectFilter({
                              type: "filterCountry",
                              value: country,
                              checked: e.target.checked,
                            });
                          }}
                          checked={
                            state["filterCountry"] &&
                            state["filterCountry"].length > 0 &&
                            state["filterCountry"].includes(country)
                          }
                        />
                      ))
                    : null}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className={styles.accItem} eventKey="2">
                <Accordion.Header>State</Accordion.Header>
                <Accordion.Body className={styles.accBody}>
                  {values && values.location && values.location.length > 0
                    ? values.location.map(
                        ({ state }: any, index: number) =>
                          state && (
                            <Checkbox
                              key={index}
                              name={state}
                              type="checkbox"
                              label={state}
                              isRequired={false}
                              flexDirection="row-reverse"
                              gap="5px"
                              onChange={(e: any) =>
                                onSelectFilter({
                                  type: "filterState",
                                  value: state,
                                  checked: e.target.checked,
                                })
                              }
                              checked={
                                state["filterState"] &&
                                state["filterState"].length > 0 &&
                                state["filterState"].includes(state)
                              }
                            />
                          )
                      )
                    : null}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className={styles.accItem} eventKey="3">
                <Accordion.Header>Industry</Accordion.Header>
                <Accordion.Body className={styles.accBody}>
                  {values && values.org_industries
                    && values.org_industries.length > 0
                    ? values.org_industries.map(
                        ({ value }: any, index: number) =>
                          value && (
                            <Checkbox
                              key={index}
                              name={value}
                              type="checkbox"
                              label={value}
                              isRequired={false}
                              flexDirection="row-reverse"
                              gap="5px"
                              onChange={(e: any) =>
                                onSelectFilter({
                                  type: "filterIndustries",
                                  value: value,
                                  checked: e.target.checked,
                                })
                              }
                              checked={
                                state["filterIndustries"] &&
                                state["filterIndustries"].length > 0 &&
                                state["filterIndustries"].includes(value)
                              }
                            />
                          )
                      )
                    : null}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className={styles.accItem} eventKey="4">
                <Accordion.Header>Category</Accordion.Header>
                <Accordion.Body className={styles.accBody}>
                  {categoryList &&
                  categoryList.data &&
                  categoryList.data.length > 0
                    ? categoryList.data.map(
                        ({ value, label }: any, index: number) => (
                          <Checkbox
                            key={index}
                            name={value}
                            type="checkbox"
                            label={label}
                            isRequired={false}
                            flexDirection="row-reverse"
                            gap="5px"
                            onChange={(e: any) =>
                              onSelectFilter({
                                type: "filterCategory",
                                value: value,
                                checked: e.target.checked,
                              })
                            }
                            checked={
                              state["filterCategory"] &&
                              state["filterCategory"].length > 0 &&
                              state["filterCategory"].includes(value)
                            }
                          />
                        )
                      )
                    : null}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className={styles.assessmentDiv}>
            <div className={styles.assessmentHeadings}>
              <h2>Select Assessment</h2>
              <h4>
                Showing available assessments based on the selected filters
              </h4>
            </div>

            <div className={styles.selectCount}>
              <div className={styles.selectAllCheck}>
                {/* <Checkbox
                  key="all"
                  name="all"
                  type="checkbox"
                  label="Select All"
                  isRequired={false}
                  flexDirection="row-reverse"
                  gap="5px"
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      setFieldValue &&
                        setFieldValue(
                          "assessments",
                          filteredAssessments && filteredAssessments.length > 0
                            ? filteredAssessments.map(
                                ({ assessment_id }: any) => {
                                  return { assessment_id };
                                }
                              )
                            : []
                        );
                    } else {
                      setFieldValue && setFieldValue("assessments", []);
                    }
                  }}
                  disabled={filteredAssessments?.length <= 0 ? true : false}
                  checked={
                    values &&
                    values?.assessments.length > 0 &&
                    filteredAssessments?.length > 0 &&
                    values?.assessments?.every((obj: any) => obj)
                  }
                /> */}
                <Checkbox
                  key="all"
                  name="all"
                  type="checkbox"
                  label="Select All"
                  isRequired={false}
                  flexDirection="row-reverse"
                  gap="5px"
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      setFieldValue &&
                        setFieldValue(
                          "assessments",
                          filteredAssessments && filteredAssessments.length > 0
                            ? filteredAssessments.map(({ assessment_id }: any) => ({
                              assessment_id,
                            }))
                            : []
                        );
                    } else {
                      setFieldValue && setFieldValue("assessments", []);
                    }
                  }}
                  disabled={filteredAssessments?.length <= 0}
                  checked={
                    values &&
                    filteredAssessments?.length > 0 &&
                    values?.assessments?.length === filteredAssessments?.length
                  }
                />

              </div>

              <span>
                Selected Assessment: {values?.assessments?.length}/
                {filteredAssessments && filteredAssessments.length}
              </span>
            </div>

            <div className={styles.assessmentCheck}>
              {filteredAssessments && filteredAssessments.length > 0 ? (
                filteredAssessments.map(
                  ({ assessment_id, assessment_name }: any, index: number) => (
                    <div
                      className={`${checked && styles.checkClass} ${
                        styles.singleCheck
                      }`}
                      key={index}
                    >
                      <Checkbox
                        name={assessment_id}
                        type="checkbox"
                        label={assessment_name}
                        isRequired={false}
                        flexDirection="row-reverse"
                        gap="5px"
                        checked={
                          values &&
                          values?.assessments.length > 0 &&
                          values?.assessments?.some(
                            (obj: any) => obj.assessment_id === assessment_id
                          )
                        }
                        onChange={(e: any) => {
                          const foundAssessment = values.assessments.find(
                            (assessment: any) =>
                              assessment.assessment_id === assessment_id
                          );
                          if (e.target.checked) {
                            if (!foundAssessment) {
                              setFieldValue &&
                                setFieldValue("assessments", [
                                  ...values.assessments,
                                  {
                                    assessment_id: assessment_id,
                                  },
                                ]);
                            }
                          } else {
                            if (foundAssessment) {
                              setFieldValue &&
                                setFieldValue(
                                  "assessments",
                                  values.assessments.filter(
                                    (assessment: any) =>
                                      assessment.assessment_id !==
                                      foundAssessment.assessment_id
                                  )
                                );
                            }
                          }
                        }}
                      />
                    </div>
                  )
                )
              ) : (
                <div className={styles.noCheckDiv}>
                  <p>No Assessment Found</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.pageBtn}>
        <Button
          label="Previous Step"
          id="backbtn"
          background="transparent"
          border="1px solid var(--secondaryColor)"
          color="var(--secondaryColor)"
          fontSize="var(--secondaryFont)"
          fontWeight="600"
          // maxWidth="100px"
          borderRadius="5px"
          onClick={prevPageHandler}
          disabled={loadingState}
          Icon={<LeftArrowIcon fill="var(--secondaryColor)" />}
        />

        <Button
          label="Submit"
          id="submitbtn"
          type="submit"
          color="white"
          borderRadius="5px"
          fontWeight="bold"
          background="var(--secondaryColor)"
          maxWidth="110px"
          fontSize="var(--secondaryFont)"
          display="flex"
          flexDirection="row-reverse"
          Icon={<RightArrowIcon fill="#fff" />}
          disabled={loadingState}
          loading={loadingState}
        />
      </div>
    </div>
  );
};

export default StepFour_OrgAssessments;
