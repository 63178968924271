import * as Yup from "yup";
const EditOrganizationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("This field is required.")
    .matches(/^[A-Za-z ]*$/, "Name cannot contain number"),
  last_name: Yup.string()
    .required("This field is required.")
    .matches(/^[A-Za-z ]*$/, "Name cannot contain number"),
  is_active: Yup.string().required("This field is required."),
  org_name: Yup.string().required("This field is required."),
  // .matches(
  //   /^[A-Za-z ]*$/,
  //   "Org Name cannot contain number or special characters"
  // )
  // org_industries: Yup.array()
  //   .required("This field is required.")
  //   .min(1, "This field is required"),
  // org_region: Yup.array()
  //   .required("This field is required.")
  //   .min(1, "This field is required"),
  // org_type: Yup.string().required("This field is required."),

  // org_id: Yup.string().required("This field is required."),
  org_status: Yup.string().required("This field is required."),
  // org_type: Yup.string().required("This field is required."),
  location: Yup.array().of(
    Yup.object()
      .shape({
        region: Yup.string().required("This field is required."),
        // country: Yup.string(),
      })
      .required("This field is required.")
  ),
});

export { EditOrganizationSchema };
