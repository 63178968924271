import * as Yup from "yup";
const AddAssessmentSchema = Yup.object().shape({
  region: Yup.string().required("This field is required"),
  // country: Yup.string(),
  assessments: Yup.array().min(1, "Please select atleast one assessment"),
  // assessment_name: Yup.string().required("This field is required"),
  // assessment_jurisdiction_state: Yup.string().required(
  //   "This field is required"
  // ),
  // // assessment_business_area: Yup.string().required("This field is required"),
  // assessment_industry: Yup.array()
  //   .min(1, "Please select atleast one industry")
  //   .required("This field is required"),
  // assessment_region: Yup.array()
  //   .min(1, "Please select atleast one region")
  //   .required("This field is required"),
});

const AssessmentInitialValue = {
  region: "",
  country: "",
  state: "",
  assessments: [],
  assessment_catgory: "",
  assessment_industry: "",
};
export { AddAssessmentSchema, AssessmentInitialValue };
