import React from "react";
import styles from "./style.module.scss";
import Select, { ActionMeta, OnChangeValue } from "react-select";
// import { colors } from "react-select/dist/declarations/src/theme";
import FormikErrorMessage from "../FormikErrorMessage";

const ReactSelect: React.FC<any> = ({
  label,
  isRequired,
  options,
  isDisabled,
  defaultValue,
  placeholder,
  values,
  setFieldValue,
  name,
  menuPlacement,
  long,
  className,
  isOptionDisabled,
  controlHeight,
  isMulti,
  onChange,
  includeNone,
}: any) => {
  return (
    <div className={`${styles.selectUi} ${className}`}>
      <label>
        {label}
        {isRequired && <sup style={{ color: "var(--redColor)" }}>*</sup>}
      </label>
      <Select
        value={values}
        isOptionDisabled={(option) => isDisabled}
        isDisabled={isDisabled}
        isMulti={isMulti}
        // options={options}
        menuPlacement={menuPlacement ? menuPlacement : "bottom"}
        options={
          isRequired || includeNone
            ? options
            : [...options, { value: null, label: "None" }]
        }
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        styles={{
          control: (baseStyles, state) => {
            if (long) {
              return {
                height: "auto",
                ...baseStyles,
                borderColor: state.isFocused ? "yellow" : "var(--blackColor)",

                border: "1px solid var(--blackColor)",
              };
            } else {
              return {
                height: controlHeight ? controlHeight : "42px",
                ...baseStyles,
                borderColor: state.isFocused ? "yellow" : "var(--blackColor)",
                border: "1px solid var(--blackColor)",
              };
            }
          },
          menuList: (provided) => ({
            ...provided,
            maxHeight: "135px", // Set the maximum height as per your requirement
            margin: 0,
          }),
          singleValue: (provided: any) => {
            if (isMulti) {
              return {
                ...provided,
                color: "var(--blackColor)",
              };
            } else {
              return {
                ...provided,
                height: "90%",
              };
            }
          },
          option: (provided: any) => ({
            ...provided,
            cursor: "pointer",
          }),
          valueContainer: (provided: any) => {
            if (isMulti) {
              if (long) {
                return {
                  ...provided,
                  maxHeight: "auto",
                  margin: 0,
                };
              } else {
                return {
                  ...provided,
                  maxHeight: "40px",
                  overflowY: "auto",
                  margin: 0,
                };
              }
            } else {
              return {
                ...provided,
                height: "30px",
                paddingTop: 0,
                margin: 0,
              };
            }
          },
          dropdownIndicator: (provided: any) => ({
            ...provided,
            height: "fit-content",
            svg: {
              fill: "#000000",
              fontWeight: "500",
            },
            padding: 0,
          }),
          placeholder: (base) => ({
            ...base,
            // backgroundColor: "black",
            fontSize: "16px",
            // color: "black",
            fontWeight: 400,
          }),
        }}
        defaultValue={defaultValue}
        isSearchable
        onChange={(
          newValue: OnChangeValue<any, true | false>,
          actionMeta: ActionMeta<any>
        ) => {
          switch (actionMeta.action) {
            case "remove-value":
              newValue = values?.filter(
                ({ label }: any) => label !== actionMeta.removedValue.label
              );
              break;
            case "clear":
              newValue = [];
              break;
          }
          if (isMulti) {
            setFieldValue && setFieldValue(name, newValue);
            onChange && onChange(newValue);
          } else {
            setFieldValue && setFieldValue(name, newValue.value);
            onChange && onChange(newValue.value);
          }
        }}
      />
      <p style={{ padding: "0" }}>
        {isRequired && <FormikErrorMessage name={name} />}
      </p>
    </div>
  );
};
export default ReactSelect;
