// import useSWR, { mutate } from 'swr';
import { useState } from "react";
import { Student } from "./types";
// import { data } from "./db";

// const url = "http://localhost:5000/students";
let data = {
  students: [
    {
      id: 1,
      "process name": "HR Process",
      description: "This process is about HRS",
      "number of employees": "22",
    },
  ],
};

export default function useStudents() {
  // const { data, isValidating } = useSWR(url, getRequest);

  const [dataState, setDataState] = useState(data?.students);
  // const data: any[] = getRequest();

  console.log("data in func useStudent", data);

  async function updateRequest(id: number, data: Student) {
    // const response = await fetch(`${url}/${id}`, {
    //   method: "PUT",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // });
    // return response.json();
    return data;
  }

  async function addRequest(addData: any) {
    // const response = await fetch(url, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // });
    // return response.json();
    data.students.push(addData);
    setDataState([...dataState, addData]);
    console.log("data in add request", data);
    return data.students;
  }

  async function deleteRequest(id: number) {
    // const response = await fetch(`${url}/${id}`, {
    //   method: "DELETE",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
    // return response.json();
    return data;
  }

  function getRequest() {
    // const response = await fetch(url);
    // return response.json();
    return data?.students;
  }

  const updateRow = async (id: number, postData: Student) => {
    await updateRequest(id, postData);
    // mutate(url);
  };

  const deleteRow = async (id: number) => {
    await deleteRequest(id);
    // mutate(url);
  };

  const addRow = async (postData: any) => {
    console.log("add row postdata", postData);
    await addRequest(postData);
    // mutate(url);
  };

  return {
    data,
    isValidating: false,
    addRow,
    updateRow,
    deleteRow,
  };
}
