import * as Yup from "yup";

const validationRequirements = {
  length: 8,
  specialChar: /.*[!@#$%^&*].*/,
  number: /.*[0-9].*/,
};

const validationMessages: any = {
  length: "Password must be at least 8 characters long",
  specialChar: "Password must contain at least one special character",
  number: "Password must contain at least one number",
};
// let value = "PIYUSH";
const LoginSchema: any = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required."),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long"),

  rememberme: Yup.string(),
  // .matches(
  //   /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
  //   "Password must contain at least one special character and one number"
  // )
  // .test(
  //   "case-one",
  //   "Password must contain at least one uppercase letter",
  //   (value) => /[A-Z]/.test(value)
  // )
  // .test(
  //   "case-two",
  //   "Password must contain at least one lowercase letter",
  //   (value) => /[a-z]/.test(value)
  // ),
});

export { LoginSchema, validationRequirements, validationMessages };
