// export function disableConsoleOutput() {
//   // Store original console methods
//   const originalConsole = {
//     log: console.log,
//     warn: console.warn,
//     error: console.error,
//     info: console.info,
//     debug: console.debug
//   };

//   // Override console methods
//   console.log = function() {};
//   console.warn = function() {};
//   console.error = function() {};
//   console.info = function() {};
//   console.debug = function() {};

//   // Optional: Keep error logging in production
//   if (process.env.NODE_ENV !== 'development') {
//     console.error = originalConsole.error;
//   }

//   // Return function to restore console if needed
//   return function enableConsoleOutput() {
//     console.log = originalConsole.log;
//     console.warn = originalConsole.warn;
//     console.error = originalConsole.error;
//     console.info = originalConsole.info;
//     console.debug = originalConsole.debug;
//   };
// }

// This approach prevents console execution and maintains a reference to original methods
export const disableConsoleOutput = () => {
  const noop = () => undefined;
  const methods = ['log', 'warn', 'error', 'info', 'debug'];
  
  methods.forEach(method => {
      Object.defineProperty(console, method, {
          get: () => noop,
          set: () => {},
          configurable: true
      });
  });
}
