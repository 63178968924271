import * as Yup from "yup";
const AddTasksSchema = Yup.object().shape({
  task: Yup.string().required("This field is required"),
  risk_level: Yup.string(),
  question_ids: Yup.array()
    .min(1, "At least one question is required")
    .required("This field is required"),
  // question_ids:Yup.string().required("This field is required"),
  // question_number: Yup.string().required("This field is required"),
  // no_task: Yup.string().required("This field is required"),
});

const TasksInitialValue = {
  task: "",
  question_ids: [],
  risk_level: "",
  // question_number: "",
  // no_task: "",
  // check_similar_questions: false,
};
export { AddTasksSchema, TasksInitialValue };
