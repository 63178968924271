import { useQuery, useQueryClient } from "react-query";
import { REQUEST_TYPE, RQ_KEYS } from "../utils/constants";
import { _GET, _PATCH, _POST } from "./axios.methods";
import { useMutation } from "react-query";
import { da } from "date-fns/locale";
export async function generateApiCall({ url, requestType, requestData }: any) {
  if (requestType === REQUEST_TYPE._GET) {
    const data = await _GET(`${url}`);
    return data;
  } else if (requestType === REQUEST_TYPE._POST) {
    const data = await _POST(`${url}`, requestData);
    return data;
  } else if (requestType === REQUEST_TYPE._PATCH) {
    const data = await _PATCH(`${url}`, requestData);
    return data;
  }
}
export function useApiQuery({
  queryKey,
  requestType,
  url,
  requestData,
  staleTime,
  cacheTime,
  enabled = true,
  onSuccess
}: any) {
  const queryClient = useQueryClient();

  return useQuery(
    [queryKey],
    async () => {
      await queryClient.setQueryData(RQ_KEYS.LOADING, true);
      await queryClient.setQueryData(`${queryKey}_loading`, true);

      const response = await generateApiCall({ url, requestType, requestData });
      await queryClient.setQueryData(RQ_KEYS.LOADING, false);
      await queryClient.setQueryData(`${queryKey}_loading`, false);
      if (response && response.status && response.code === 200) {
        onSuccess && onSuccess(response.data)
        return response.data;
      }
    },
    {
      staleTime: staleTime >= 0 ? staleTime : Infinity,
      // cacheTime: cacheTime >= 0 ? cacheTime : Infinity,
      cacheTime: 5,
      enabled,
      refetchOnMount: false,
    }
  );
}

export function _useMutation({
  onSuccess,
  queryKey,
  showErrorToast = true,
}: any | null) {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading } = useMutation(generateApiCall, {
    onMutate: () => {
      queryClient.setQueryData(RQ_KEYS.LOADING, true);
      queryClient.setQueryData(`${queryKey}_loading`, true);
    },
    onSuccess: ({ data, status, response, message }: any) => {
      console.log("API CALL STATE>> data, status, message", data, response);
      queryClient.setQueryData(RQ_KEYS.LOADING, false);
      queryClient.setQueryData(`${queryKey}_loading`, false);
      console.log("status???????",status);
      if (status === true) {
        // console.log("status ++**", queryKey, data);
        queryKey && queryClient.setQueryData(queryKey, data); 
        return onSuccess && onSuccess({ data, message });
      } else if (response && response.data && showErrorToast) {
        console.log("++**", response);
        queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
          message: response && response.data && response.data.message,
          toastType: "ERROR",
        });
      }
    },
    onError: (error: any) => {
      queryClient.setQueryData(RQ_KEYS.LOADING, false);
      queryClient.setQueryData(`${queryKey}_loading`, false);
      queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
        message: "Something Went Wrong! Please try again",
        toastType: "ERROR",
      });
      console.log("ERROR IN API CALL", error);
    },
    onSettled: ({ data }: any) => {
      console.log("NEW ONSETTLE", queryKey);
      queryClient.setQueryData(RQ_KEYS.LOADING, false);
      queryClient.setQueryData(`${queryKey}_loading`, false);
    },
  });
  return {
    mutate,
    mutateAsync,
    isLoading,
  };
}

export function _getQueryState({ key }: any) {
  const queryClient = useQueryClient();
  const storeState = queryClient.getQueryData(key);
  // console.log("storeState", storeState);
  return storeState;
}
