import React from "react";
import styles from "./style.module.scss";
import logoImage from "../../../assests/png/siteLogo1.png";
import { ReactComponent as LogoutIcon } from "../../../assests/svg/logout.svg";

import { LOCALSTORAGE, RQ_KEYS } from "../../../utils/constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import Button from "../Button";

const StepSideBar: React.FC<any> = ({ content, name }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleLogout = () => {
    localStorage.removeItem(LOCALSTORAGE.TOKEN);
    queryClient.removeQueries(RQ_KEYS.AUTH);
    queryClient.removeQueries(RQ_KEYS.USER_DATA);
    navigate(0);
  };

  return (
    <div className={styles.imageSection}>
      <div className={styles.stepperHeader}>
        <h1>Welcome to </h1>
        <img src={logoImage} alt="logo_image"></img>
      </div>
      <h3>Welcome {name}!</h3>
      <p>{content}</p>
      <p>Get started on your compliance journey in just 4 simple steps.</p>
      <div className={styles.logOutDiv}>
        <Button
          label="Logout"
          onClick={handleLogout}
          id="logoutBtn"
          border="1px solid var(--whiteColor)"
          fontWeight="bold"
          lineHeight="inherit"
          fontSize="var(--primaryFont)"
          gap="10px"
          marginRight="auto"
          // width="100%"
          // maxWidth="100%"
          Icon={
            <LogoutIcon fill="var(--whiteColor)" className="svg_image_icon" />
          }
        />
      </div>
    </div>
  );
};

export default StepSideBar;
