import React from "react";
import { ReactComponent as AnswerIcon } from "../../../assests/svg/answer.svg";
import { ReactComponent as Date } from "../../../assests/svg/date.svg";
import { ReactComponent as Flag } from "../../../assests/svg/flag.svg";
import { ReactComponent as UserIcon } from "../../../assests/svg/user_logo_fill.svg";
import { IQuestion } from "../../interfaces/IQuestion";
import Button from "../Button";
import styles from "./style.module.scss";

const QuestionCard: React.FC<IQuestion> = ({
  questions,
  onSelectQuestion,
  heading,
  isAnswerAllowed,
  showSidePopupHandler,
}) => {
  console.log("questions", questions);

  return (
    <div className={`${styles.tasksCardParent} ${styles.tasksCardGridParent}`}>
      <h3>{heading}</h3>
      {questions && questions.length > 0 ? (
        questions.map((ques: any) => (
          <div
            className={`${styles.tasksCard} ${
              heading !== "Completed" && styles.taskOnHover
            }`}
          >
            <div className={styles.cardTop}>
              <span>{ques.assessment_name}</span>
              {isAnswerAllowed && (
                <Button
                  id="answerquestion"
                  label="Answer"
                  onClick={() => {
                    onSelectQuestion && onSelectQuestion(ques);
                  }}
                  Icon={<AnswerIcon fill="var(--whiteColor)" />}
                />
              )}
            </div>
            <div className={styles.cardChapter}>
              <span>Chapter: {ques.chapter_name}</span>
            </div>
            <div
              className={styles.cardMid}
              onClick={() => showSidePopupHandler && showSidePopupHandler(ques)}
            >
              Question: {ques.question}
            </div>

            <div className={styles.cardBottom}>
              {/* <span className={styles.cardDate}>
                <p>Assigned On:</p>
                <Date fill="var(--textColor)" />
                {ques.assigned_at}
              </span> */}

              <span className={styles.cardOwner}>
                <p>Assigned by:</p>
                <UserIcon fill="var(--textColor)" />
                {ques.assigned_by}
              </span>
              {ques.due_date && (
                <span className={styles.dueWeek}>
                  <p> Due Date:</p>
                  <Flag fill="var(--redColor)" />
                  {ques.due_date}
                </span>
              )}
            </div>
          </div>
        ))
      ) : (
        <p>No Questions Found</p>
      )}
    </div>
  );
};

export default QuestionCard;
