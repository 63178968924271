import React, { useEffect } from "react";
import styles from "./style.module.scss";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import { useQueryClient } from "react-query";
import {
  LOCALSTORAGE,
  RQ_KEYS,
  APP_PATHS,
  APP_ROLES,
} from "../../../utils/constants";
import logoImage from "../../../assests/svg/blueLogo.svg";
import { ReactComponent as CancelIcon } from "../../../assests/svg/close.svg";
import { ReactComponent as DownIcon } from "../../../assests/svg/down_arrow.svg";
import { ReactComponent as UpIcon } from "../../../assests/svg/up_arrow.svg";
import { ReactComponent as LeftArrowIcon } from "../../../assests/svg/leftArrow.svg";
import {
  capitalizeFirstLetter,
  getAdminRoleFromKey,
  localeDate,
} from "../../../utils/helpers";
import UserProfile from "../Userprofile";
import LogoutModal from "../../../common/Parts/ModalPopups/LogoutModal/index";

const Sidebar: React.FC<any> = ({
  sidebarData,
  onClick,
  userAuthData,
  onClickCancel,
  onClickOpen,
}) => {
  const [showOption, setShowOption] = React.useState<boolean[]>(
    Array(sidebarData.length).fill(false)
    // .map((_, idx) => (idx === 0 ? true : false))
  );
  const [showPopup, setShowPopup] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  console.log("userAuthData in sidebar", userAuthData);

  useEffect(() => {
    sidebarData.forEach((data: any, idx: any): any => {
      const foundIdx = data.options.findIndex(
        (d: any) => d.path === location.pathname
      );
      if (foundIdx >= 0) {
        setShowOption(showOption.map((_, i) => i === idx));
      }
    });
  }, [location.pathname, sidebarData]);

  console.log("location 1", location);
  const handleLogout = () => {
    if (localStorage.getItem("supportusertoken")) {
      const token: any = localStorage.getItem("supportusertoken");
      localStorage.setItem("token", token);
      localStorage.removeItem("supportusertoken");
      navigate(APP_PATHS.ORGADMIN_LIST);
      window.location.reload();
    } else {
      localStorage.removeItem(LOCALSTORAGE.TOKEN);
      queryClient.removeQueries(RQ_KEYS.AUTH);
      queryClient.removeQueries(RQ_KEYS.USER_DATA);
      navigate(0);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  function ShowUserProfile() {
    // console.log(userAuthData)
    if (
      userAuthData.role !== "OBOEDIO_ADMIN" &&
      userAuthData.role !== "SUPPORT_USER"
    ) {
      navigate(APP_PATHS.USER_LOGIN_DETAILS);
    }
  }

  return (
    <>
      <div className={styles.sideBar}>
        {isModalOpen === true ? (
          <LogoutModal
            onClickCancel={closeModal}
            onClickLogouut={handleLogout}
            openState={isModalOpen}
          />
        ) : (
          <p></p>
        )}
        <div className={styles.sideBarInner}>
          <div className={styles.dashBoardList}>
            {/* <h4>Menu</h4> */}
            {/* <Link to={"/"}> */}
            <div>
              <div className={styles.headerLogo}>
                <img src={logoImage} alt="logo_image"></img>
                {/* <CancelIcon
                  className="svg_image_icon"
                  onClick={onClickCancel}
                  fill="#0097ce"
                /> */}
                <LeftArrowIcon
                  className="svg_image_icon"
                  onClick={onClickCancel}
                  // fill="var(--primaryColor)"
                />
              </div>
              {/* </Link> */}
              {userAuthData && (
                <div className={styles.loggedInUser}>
                  {/* <div
                    className={styles.loggedInUserContainer}
                    onClick={() => {
                      ShowUserProfile();
                    }}
                  > */}
                  {/* <div className={styles.userInitial}>
                      {userAuthData?.profile_image ? (
                        <img
                          src={userAuthData.profile_image}
                          alt="user_profile_image"
                          style={{ width: 10 }}
                        ></img>
                      ) : (
                        <p>{`${userAuthData.first_name.charAt(
                          0
                        )}${userAuthData.last_name.charAt(0)}`}</p>
                      )}
                    </div> */}
                  {/* <div className={styles.userInfo}>
                      <p>{`${capitalizeFirstLetter(
                        userAuthData.first_name
                      )} ${capitalizeFirstLetter(userAuthData.last_name)}`}</p>
                      <p>{`${
                        getAdminRoleFromKey(userAuthData.role) !== null &&
                        getAdminRoleFromKey(userAuthData.role)["name"]
                      }`}</p> */}

                  {/* <p
                        style={{
                          fontSize: 12,
                          fontWeight: "normal",
                        }}
                      >
                        Last Logged In:
                      </p> */}
                  {/* <p style={{ fontSize: 10 }}>
                        {localeDate(userAuthData?.last_logged_in)}
                      </p> */}
                </div>
                // </div>
                // </div>
              )}
            </div>

            <ul>
              {sidebarData &&
                sidebarData.length > 0 &&
                sidebarData.map(
                  ({ section, options, Icon }: any, index: number) => {
                    console.log({ Icon });
                    return (
                      <>
                        <li key={index}>
                          <div
                            className={styles.arrow}
                            onClick={() => {
                              const newShowOption = [...showOption];
                              newShowOption[index] = !showOption[index];
                              setShowOption(newShowOption);
                            }}
                          >
                            <p className={styles.section}>
                              {Icon && Icon(true)}
                              {section}
                            </p>
                            {showOption[index] ? (
                              <span
                                style={{ width: "fit-content", padding: 0 }}
                              >
                                <UpIcon
                                  fill="black"
                                  width={15}
                                  height={15}
                                  style={{ maxWidth: "15px" }}
                                />
                              </span>
                            ) : (
                              <span
                                style={{ width: "fit-content", padding: 0 }}
                              >
                                <DownIcon
                                  fill="black"
                                  width={15}
                                  height={15}
                                  style={{ maxWidth: "15px" }}
                                />
                              </span>
                            )}
                          </div>
                          {/* </span> */}
                          {showOption[index] &&
                            options.map(
                              ({ path, Icon, title, isClickable }: any) => {
                                const className =
                                  path === location.pathname
                                    ? styles.active
                                    : "";
                                if (isClickable) {
                                  return (
                                    <a
                                      onClick={() => {
                                        switch (title) {
                                          case "Profile":
                                            setShowPopup(true);
                                            break;
                                          case "Logout":
                                            // handleLogout();
                                            openModal();
                                            break;
                                        }
                                      }}
                                    >
                                      {Icon(path === location.pathname)}
                                      {title}
                                    </a>
                                  );
                                } else {
                                  return (
                                    <Link
                                      to={path}
                                      className={className}
                                      onClick={onClickCancel}
                                    >
                                      {Icon(path === location.pathname)}
                                      {title}
                                    </Link>
                                  );
                                }
                              }
                            )}
                        </li>
                      </>
                    );
                  }
                )}
            </ul>
          </div>
          {/* <div className={styles.logoutButton}>
          <Button
          label="Logout"
          onClick={handleLogout}
          id="logoutBtn"
          borderRadius="5px"
          padding="6px 5px"
          border="none"
          fontWeight="bold"
          lineHeight="inherit"
          fontSize="15px"
          alignItems="center"
          gap="10px"
          maxWidth="100%"
          Icon={<EditIcon fill="#ffffff" className="svg_image_icon" />}
          />
        </div> */}
        </div>
      </div>
      {showPopup && (
        <UserProfile
          heading="User Account"
          onClickCancel={() => setShowPopup(false)}
        />
      )}
    </>
  );
};

export default Sidebar;
