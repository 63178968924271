import React, { useState } from "react";
import styles from "./style.module.scss";
import update_details from "../../../assests/png/update_details.png";
import { Button, InputField } from "../../../common/components";
import { Formik, Form } from "formik";
import { PersonalDetailsValue, ResetAndUpdateDetailsSchema } from "./validator";
import { _useMutation, _getQueryState } from "../../../services/useAxiosQuery";
import {
  APP_PATHS,
  APP_ROLES,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../utils/constants";
import { ReactComponent as SaveBtn } from "../../../assests/svg/save_close.svg";
import { useQueryClient } from "react-query";
import logoImage from "../../../assests/svg/blueLogo.svg";
import { API_ROUTE } from "../../../utils/api";
import { ReactComponent as UpdateIcon } from "../../../assests/svg/update_icon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { ITOAST_STATE } from "../../../interfaces/IToastState";
import Toast from "../../../common/components/Toast";
import { decodeToken } from "react-jwt";
import login_icon from "../../../assests/svg/login_icon.svg";
import eyeHideIcon from "../../../assests/svg/eye_hIde.svg";
import eyeIcon from "../../../assests/svg/eye_icon.svg";

const ResetAndUpdatePassword = () => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    React.useState<boolean>(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { token } = useParams();
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });
  const tokenDecodedData: any = token && decodeToken(token);
  const onSuccess = ({ message }: any) => {
    // onSuccess
    queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
      message: message,
      toastType: "SUCCESS",
    });
    navigate(APP_PATHS.LOGIN);
  };
  const updateUserDetails = _useMutation({
    onSuccess,
  });
  console.log(
    "tokenDecodedData",
    tokenDecodedData,
    tokenDecodedData && tokenDecodedData?.last_name
      ? tokenDecodedData?.last_name
      : ""
  );
  return (
    <div className={styles.resetPasswordContainer}>
      <div className={styles.loginSiteLogo}>
        <img
          style={{
            width: 150,
            alignSelf: "center",
          }}
          src={logoImage}
          alt="logo_image"
        ></img>
      </div>
      <div className={styles.loginFlexMain}>
        <div className={styles.resetPassForm}>
          <Formik
            initialValues={{
              ...PersonalDetailsValue,
              token: token,
              // first_name: "FN",
              first_name:
                tokenDecodedData && tokenDecodedData?.first_name
                  ? tokenDecodedData?.first_name
                  : "",
              last_name:
                tokenDecodedData && tokenDecodedData?.last_name
                  ? tokenDecodedData?.last_name
                  : "",
              vendor_org_name:
                tokenDecodedData &&
                tokenDecodedData?.role === APP_ROLES.VENDOR_USER
                  ? tokenDecodedData?.org_name
                  : "",
            }}
            validationSchema={ResetAndUpdateDetailsSchema}
            onSubmit={async (values) => {
              queryClient.setQueryData(RQ_KEYS.LOADING, true);
              updateUserDetails.mutate({
                url: API_ROUTE.UPDATE_USER_DETAILS,
                requestType: REQUEST_TYPE._PATCH,
                requestData: values,
              });
              queryClient.removeQueries(RQ_KEYS.TOAST_STATE);
            }}
          >
            {({ handleChange, isValid, values, handleBlur }) => {
              return (
                <Form className="signup-form" style={{ height: "100%" }}>
                  <div className={styles.resetPass}>
                    <div className={styles.resetPassSectionOne}>
                      <div className={styles.resetPassForm}>
                        <div className={styles.resetPassFormInner}>
                          <img
                            style={{
                              width: 30,
                              alignSelf: "center",
                            }}
                            src={login_icon}
                            alt="forgot_icon"
                          ></img>
                          <h1>Personal Details</h1>
                          <Toast toastState={toastState} />
                          <p>Register here for your Oboedio account</p>
                          <div className={styles.loginInputs}>
                            <div className={styles.nameField}>
                              <InputField
                                name="first_name"
                                type="Text"
                                label="First Name"
                                placeholder="Enter First Name"
                                isRequired={true}
                                display="flex"
                                flexDirection="column"
                                gap="5px"
                                maxWidth="95%"
                                fontWeight="500"
                                alignItems="flex-start"
                                onChange={handleChange}
                                value={values.first_name}
                                onBlur={handleBlur}
                              />
                              <InputField
                                name="last_name"
                                type="Text"
                                label="Last Name"
                                placeholder="Enter Last Name"
                                isRequired={false}
                                display="flex"
                                flexDirection="column"
                                gap="5px"
                                maxWidth="95%"
                                fontWeight="500"
                                alignItems="flex-start"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.last_name}
                              />
                            </div>
                            <InputField
                              name="password"
                              type="password"
                              label="Password"
                              isRequired={true}
                              display="flex"
                              flexDirection="column"
                              placeholder="Enter Password"
                              gap="5px"
                              fontWeight="500"
                              alignItems="flex-start"
                              onChange={handleChange}
                              icon={showPassword ? eyeHideIcon : eyeIcon}
                              iconClass={styles.eyeIconPassword}
                              showPassword={showPassword}
                              setShowPassword={setShowPassword}
                              onBlur={handleBlur}
                            />
                            <InputField
                              name="confirm_password"
                              type="password"
                              label="Confirm Password"
                              placeholder="Confirm your Password"
                              isRequired={true}
                              display="flex"
                              flexDirection="column"
                              gap="5px"
                              fontWeight="500"
                              alignItems="flex-start"
                              onChange={handleChange}
                              icon={showConfirmPassword ? eyeHideIcon : eyeIcon}
                              iconClass={styles.eyeIconPassword}
                              showPassword={showConfirmPassword}
                              setShowPassword={setShowConfirmPassword}
                              onBlur={handleBlur}
                            />
                            {tokenDecodedData &&
                              tokenDecodedData?.role ===
                                APP_ROLES.VENDOR_USER && (
                                <InputField
                                  name="vendor_org_name"
                                  type="Text"
                                  label="Organization Name"
                                  placeholder="Enter Organization Name"
                                  isRequired={true}
                                  display="flex"
                                  flexDirection="column"
                                  gap="10px"
                                  fontWeight="500"
                                  alignItems="flex-start"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.vendor_org_name}
                                />
                              )}
                          </div>
                          {/* <hr /> */}
                          <div className={styles.formInnerBtnSection}>
                            <Button
                              id="resetPassBtn"
                              label="Submit"
                              borderRadius="5px"
                              fontWeight="bold"
                              color="var(--whiteColor)"
                              padding="8px 15px"
                              fontSize="15px"
                              border="none"
                              type="submit"
                              maxWidth="100%"
                              width="40%"
                              justifyContent="center"
                              lineHeight="1.4rem"
                              Icon={<UpdateIcon style={{ fill: "#ffffff" }} />}
                              disabled={!isValid || loadingState ||  !values.first_name || 
                                !values.password || 
                                !values.confirm_password}
                            />
                          </div>

                          <div className={styles.requirements}>
                            <h4>Password Requirements:</h4>
                            <div
                              className={`requirement ${
                                /[A-Z]/.test(values.password)
                                  ? styles.fulfilled
                                  : ""
                              }`}
                            >
                              <span className={styles.tick}>✓</span>

                              <span className="requirement-text">
                                Password must contain an Uppercase letter.
                              </span>
                            </div>
                            <div
                              className={`requirement ${
                                /[a-z]/.test(values.password)
                                  ? styles.fulfilled
                                  : ""
                              }`}
                            >
                              <span className={styles.tick}>✓</span>

                              <span className="requirement-text">
                                Password must contain a Lowercase letter.
                              </span>
                            </div>
                            <div
                              className={`requirement ${
                                /[!@#$%^&*]/.test(values.password)
                                  ? styles.fulfilled
                                  : ""
                              }`}
                            >
                              <span className={styles.tick}>✓</span>

                              <span className="requirement-text">
                                Password must contain a special character.
                              </span>
                            </div>
                            <div
                              className={`requirement  ${
                                /[0-9]/.test(values.password)
                                  ? styles.fulfilled
                                  : ""
                              }`}
                            >
                              <span className={styles.tick}>✓</span>
                              <span className="requirement-text">
                                Password must contain a number.
                              </span>
                            </div>
                            <div
                              className={`requirement ${
                                values.password.length >= 8
                                  ? styles.fulfilled
                                  : ""
                              }`}
                            >
                              <span className={styles.tick}>✓</span>
                              <span className="requirement-text">
                                Password must contain 8 or more characters.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <div className={styles.imageSection}>
          <img src={update_details} alt="sample" />
        </div>
      </div>
    </div>
  );
};
export default ResetAndUpdatePassword;
