import styles from "./style.module.scss";
import Comingsoon from "../../../assests/svg/coming_soon1.svg";

const Unauthorized = () => {
  return (
    <div
      className={`manageAssessmentContainer ${styles.manageAssessmentContainer}`}
    >
      <div className={styles.manageAssessmentBody}>
        <img src={Comingsoon} alt="Comingsoon" />
        <h1>Coming Soon...</h1>
      </div>

      {/* <div className="container">
        <h1>404</h1>
        <p>Oops! The page you're looking for does not exist.</p>
        <a className="button" href="/">
          Go back to Homepage
        </a>
      </div> */}
    </div>
  );
};
export default Unauthorized;
