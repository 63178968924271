import React, { useState } from "react";
import styles from "./style.module.scss";
import {ReactComponent as Login_img} from "../../../assests/svg/login.svg";
import login_icon from "../../../assests/svg/login_icon.svg";
import ILogin from "../../../interfaces/ILogin";
import { Button, Checkbox, InputField } from "../../../common/components";
import Link from "../../../common/components/Link";
import { Formik, Form, ErrorMessage } from "formik";
import {
  LoginSchema,
  validationMessages,
  validationRequirements,
} from "./validator";
import eyeIcon from "../../../assests/svg/eye_icon.svg";
import eyeHideIcon from "../../../assests/svg/eye_hIde.svg";
import { _useMutation, _getQueryState } from "../../../services/useAxiosQuery";
import {
  APP_PATHS,
  APP_ROLES,
  LOCALSTORAGE,
  LOCALSTORAGE_ITEMS,
  PROFILE_STATUS,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { API_ROUTE } from "../../../utils/api";
import { decodeToken } from "react-jwt";
import logoImage from "../../../assests/svg/blueLogo.svg";
import { ReactComponent as LoginEnterIcon } from "../../../assests/svg/login_enter.svg";

import { ITOAST_STATE } from "../../../interfaces/IToastState";
import Toast from "../../../common/components/Toast";
import {
  getEncryptedObj,
  setEncryptedObj,
} from "../../../utils/crypto-js/crypto-js.utils";
const Login: React.FC<ILogin> = () => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutateAsync: getOrganizationDetails } = _useMutation({
    queryKey: RQ_KEYS.USER_ORGANIZATION,
  });
  // const navigate = useNavigate();
  const onSuccess = async ({ data }: any) => {
    const token = data.token;
    const auth: any = decodeToken(token);
    await localStorage.setItem(LOCALSTORAGE.TOKEN, token);
    queryClient.setQueryData(RQ_KEYS.AUTH, token);

    console.log("userAuthData", auth);
    queryClient.setQueryData(RQ_KEYS.USER_DATA, auth);
    await getOrganizationDetails({
      requestType: REQUEST_TYPE._GET,
      url: `${API_ROUTE.GET_USER_ORGANIZATION}`,
    });
    if (auth.role === APP_ROLES.OBOEDIO_ADMIN) {
      return navigate(APP_PATHS.ORG_ADMIN_MANAGEMENT);
    } else if (auth.role === APP_ROLES.EXECUTIVE_USER) {
      return navigate(APP_PATHS.ORG_DASHBOARD);
    } else if (auth.role === APP_ROLES.SUPPORT_USER) {
      return navigate(APP_PATHS.ORG_DASHBOARD);
    } else if (auth.role === APP_ROLES.ORG_ADMIN) {
      if (auth.profile_status === PROFILE_STATUS.PENDING) {
        return navigate(APP_PATHS.ORG_ONBOARDING);
      } else {
        // return navigate(APP_PATHS.MANAGE_ORGANIZATION);
        return navigate(APP_PATHS.ORG_DASHBOARD);
      }
    } else if (auth.role === APP_ROLES.ORG_USER) {
      return navigate(APP_PATHS.ASSIGNED_TASKS);
    } else if (auth.role === APP_ROLES.VENDOR_USER) {
      return navigate(APP_PATHS.ASSESSMENT_DASHBOARD);
    } else if (auth.role === APP_ROLES.BRANCH_ADMIN) {
      return navigate(APP_PATHS.ASSIGNED_TASKS);
    }
    else if (auth.role === APP_ROLES.AUDITOR) {
      return navigate(APP_PATHS.ASSIGNED_TASKS);
    }
  };

  const { mutate } = _useMutation({ onSuccess ,showErrorToast: true, queryKey: RQ_KEYS.LOGIN});
  // const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  const loadingState = _getQueryState({ key:  `${RQ_KEYS.LOGIN}_loading`});
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });

  const creds = getEncryptedObj(LOCALSTORAGE_ITEMS.CREDS);
  console.log({ creds });
  const initialLoginValues = {
    email: creds?.email || "",
    password: creds?.password || "",
    rememberme: creds?.rememberme,
  };

  // const [initialLoginValues, setInitialLoginValues] = useState({
  //   email: creds?.email || "",
  //   password: creds?.password || "",
  //   rememberme: creds?.rememberme || false,
  // });
  

  // const initialErrors = {
  //   password: LoginSchema.fields.password.tests.reduce(
  //     (acc: any, test: any) => {
  //       acc[test.name] = test.message;
  //       return acc;
  //     },
  //     {}
  //   ),
  // };
  // console.log("loadingState", loadingState);
  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginSiteLogo}>
        <img
          style={{
            width: 150,
            alignSelf: "center",
            margin: "0.5rem 0.5rem",
          }}
          src={logoImage}
          alt="logo_image"
        ></img>
      </div>
      <div className={styles.loginFlexMain}>
        <div className={styles.loginForm}>
          <Formik
            initialValues={initialLoginValues}
            // initialErrors={initialErrors}
            validationSchema={LoginSchema}
            enableReinitialize={true}
            onSubmit={async (values) => {
              console.log(
                "on submit login ",
                values,
                typeof values?.rememberme
              );
              if (values?.rememberme) {
                const encryptObj = {
                  email: values.email,
                  password: values.password,
                  rememberme: values.rememberme,
                };
                setEncryptedObj(LOCALSTORAGE_ITEMS.CREDS, encryptObj);
              } else {
                setEncryptedObj(LOCALSTORAGE_ITEMS.CREDS, {});
              }
              // queryClient.setQueryData(RQ_KEYS.LOADING, true);
              mutate({
                url: API_ROUTE.LOGIN,
                requestType: REQUEST_TYPE._POST,
                requestData: values,
              });
              queryClient.removeQueries(RQ_KEYS.TOAST_STATE);
            }}
          >
            {({
              handleChange,
              isValid,
              handleBlur,
              setFieldValue,
              values,
            }: any) => {
              return (
                <Form className="signup-form" style={{ height: "100%" }}>
                  <div className={styles.login}>
                    <div className={styles.loginSection1}>
                      <div className={styles.loginForm}>
                        <div className={styles.loginFormInner}>
                          <img
                            style={{
                              width: 30,
                              alignSelf: "center",
                            }}
                            src={login_icon}
                            alt="forgot_icon"
                          ></img>
                          <h1>Login</h1> <Toast toastState={toastState} />
                          <p>Welcome to Oboedio.</p>
                          <div className={styles.loginInputs}>
                            <InputField
                              name="email"
                              type="text"
                              label="Email"
                              isRequired={true}
                              display="flex"
                              flexDirection="column"
                              gap="5px"
                              fontWeight="500"
                              alignItems="flex-start"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <InputField
                              name="password"
                              type="password"
                              label="Password"
                              id="passwordLogin"
                              isRequired={true}
                              display="flex"
                              onChange={handleChange}
                              flexDirection="column"
                              gap="5px"
                              fontWeight="500"
                              alignItems="flex-start"
                              icon={showPassword ?   eyeIcon : eyeHideIcon}
                              iconClass={styles.eyeIconPassword}
                              setShowPassword={setShowPassword}
                              showPassword={showPassword}
                              onBlur={handleBlur}
                              value={values.password}
                              // eyeHideIcon
                            />
                            <div style={{display:"flex",justifyContent:"flex-end"}}>
                            <Link
                              label="Forgot Password?"
                              color="var(--secondaryColor)"
                              fontWeight="600"
                              to={APP_PATHS.FORGET_PASSWORD}
                            />
                            </div>
                          </div>
                          {/* <LoginEnterIcon style={{ fill: "#000000" }} /> */}
                          {/* <Link
                          label="Forgot Password?"
                          color="var(--secondaryColor)"
                          fontWeight="600"
                          to={APP_PATHS.FORGET_PASSWORD}
                        /> */}
                          {/* <hr /> */}
                         {/* <div style={{  }}>
                         
                         </div> */}
                          <div className={styles.formInnerBtnSection}>
                         <div style={{width:"100%" }}>
                         <Checkbox
                              name="rememberme"
                              type="checkbox"
                              label={`Remember Me`}
                              isRequired={false}
                              flexDirection="row-reverse"
                              alignItems="center"
                              gap="5px"
                              onChange={handleChange}
                              checked={values.rememberme}
                              width="max-content"
                            />
                         </div>
                            <div className={styles.loginButton}>
                              <Button
                                id="loginBtn"
                                label="Log In"
                                borderRadius="5px"
                                fontWeight="bold"
                                width="60%"
                                color="var(--whiteColor)"
                                fontSize="15px"
                                border="none"
                                type="submit"
                                Icon={
                                  <LoginEnterIcon style={{ fill: "#ffffff" }} />
                                }
                                disabled={!isValid || loadingState}
                                loading={loadingState}
                                maxWidth="100%"
                                justifyContent="center"
                                lineHeight="1.4rem"
                                // filter="invert(100%) sepia(1%) saturate(4057%) hue-rotate(163deg) brightness(110%) contrast(100%)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <div className={styles.imageSection}>
          {/* <img src={login_img} alt="sample" /> */}
          <Login_img />
        </div>
      </div>
    </div>
  );
};
export default Login;
