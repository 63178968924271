import { USER_ROLES } from "./constants";

export function capitalizeFirstLetter(str: string) {
  if (str) {
    return str
      .split(" ")
      .map(
        (string) => string && string.charAt(0).toUpperCase() + string.slice(1)
      )
      .join(" ");
  }

  return null;
}

export function getAdminRoleFromKey(key: string) {
  const userRole: any =
    key && USER_ROLES.filter((userRoleData: any) => userRoleData.key === key);
  return userRole && userRole.length > 0 ? userRole[0] : null;
}

export function formatReactSelectDefaultValueWithParse(data: any) {
  // console.log("formatReactSelectDefaultValueWithParse", data);
  return data && JSON.parse(data) && JSON.parse(data).length > 0
    ? JSON.parse(data).map((region_name: string) => {
        console.log("formatReactSelectDefaultValueWithParse", region_name);
        return {
          value: region_name,
          label: region_name,
        };
      })
    : [];
}
export function formatReactSelectOptions(data: any, valueKey: string) {
  return data && data.length > 0
    ? data.map((item: any) => {
        return {
          value: item[valueKey],
          label: item[valueKey],
        };
      })
    : [];
}

export function addMinutes(date: Date, minutes: number): Date {
  date.setMinutes(date.getMinutes() + minutes);

  return date;
}
export const removeDuplicates = (array: any) => {
  // Create a temporary object to store unique objects
  const tempObj: any = {};

  // Iterate over the array and store each object in the tempObj using a unique identifier
  for (let obj of array) {
    const key = JSON.stringify(obj);
    tempObj[key] = obj;
  }

  // Convert the values of tempObj back to an array
  const uniqueArray = Object.values(tempObj);

  return uniqueArray;
};

export function removeDuplicationsUsingSet(array: any) {
  // Create a Set from the array
  var set = new Set(array);

  // Delete the empty string from the Set
  set.delete("");
  set.delete(" ");
  set.delete(null);
  // Convert the Set back to an array
  var result = Array.from(set);

  return result;
}
export function flatten(arr: any) {
  let flattenArr: any = [];

  for (let i = 0; i < arr.length; i++) {
    if (Array.isArray(arr[i])) {
      flattenArr = flattenArr.concat(flatten(arr[i]));
    } else {
      flattenArr.push(arr[i]);
    }
  }

  return flattenArr;
}

export const assessmentToTree = (data: any) => {
  let assessments: any = {};
  data &&
    data.length > 0 &&
    data.forEach(function (d: any) {
      if (!assessments[d.assessment_region])
        assessments[d.assessment_region] = {};
      if (!assessments[d.assessment_region][d.assessment_jurisdiction_country])
        assessments[d.assessment_region][d.assessment_jurisdiction_country] =
          {};
      if (
        !assessments[d.assessment_region][d.assessment_jurisdiction_country][
          d.assessment_jurisdiction_state
        ]
      )
        if (d.assessment_jurisdiction_state) {
          assessments[d.assessment_region][d.assessment_jurisdiction_country][
            d.assessment_jurisdiction_state
          ] = d;
        } else {
          assessments[d.assessment_region][d.assessment_jurisdiction_country] =
            d;
        }
    });
  console.log({ assessments });
  return assessments;
};

export const localeDate = (dateStr: string): string =>
  new Date(dateStr).toLocaleDateString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

export const calcBIAProgress = (item: any, answerData: any) => {
  console.log("item in calc BIA proces", item, answerData);

  let completedCount = 0;
  if (answerData?.length > 0) {
    for (let answer of answerData) {
      if (
        answer.assigned_status === "Saved" ||
        answer.assigned_status === "Submitted"
      ) {
        completedCount++;
      }
    }
  }

  console.log("getCompletedCount", completedCount);

  // earlier answer.length
  // const compPercentage = Math.floor(
  //   (completedCount / item.total_articles) * 100
  // );
  const TOTAL_BIA_ARTICLES = 10;
  const compPercentage = Math.floor(
    (completedCount / TOTAL_BIA_ARTICLES) * 100
  );
  console.log("🚀 ~ calcBIAProgress ~ compPercentage:", compPercentage)

  const itemsCompleted = Math.floor(
    (item?.total_question_count * compPercentage) / 100
  );
  console.log("🚀 ~ calcBIAProgress ~ itemsCompleted:", itemsCompleted)

  return { ...item, completed_question_count: itemsCompleted };
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
