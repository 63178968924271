import * as Yup from "yup";
const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
      "Password must contain at least one special character and one number"
    )
    .test(
      "case-one",
      "Password must contain at least one uppercase letter",
      (value) => /[A-Z]/.test(value)
    )
    .test(
      "case-two",
      "Password must contain at least one lowercase letter",
      (value) => /[a-z]/.test(value)
    ),
  repeat_password: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

const ResetPasswordValue = {
  password: "",
  repeat_password: "",
};

export { ResetPasswordSchema, ResetPasswordValue };
