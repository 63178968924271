import React, { useState } from "react";
import { Button } from "../../../../../common/components";
import { Breadcrumb } from "../../../../../common/Parts";
import styles from "./style.module.scss";
import ManageChapters from "../../../../../common/Parts/ModalPopups/AssessmentModals/ManageChapters";
import ManageArticles from "../../../../../common/Parts/ModalPopups/AssessmentModals/ManageArticles";
import ManageQuestions from "../../../../../common/Parts/ModalPopups/AssessmentModals/ManageQuestions";
import { ReactComponent as EditIcon } from "../../../../../assests/svg/edit_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assests/svg/delete_icon.svg";
import { ReactComponent as PlusIcon } from "../../../../../assests/svg/plus.icon.svg";
import Spinner from "react-bootstrap/Spinner";
import { ReactComponent as DownIcon } from "../../../../../assests/svg/down_arrow.svg";
import { ReactComponent as UpIcon } from "../../../../../assests/svg/up_arrow.svg";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../../services/useAxiosQuery";
import { ReactComponent as ViewIcon } from "../../../../../assests/svg/eye_icon.svg";
import { ReactComponent as InfoIcon } from "../../../../../assests/svg/i_icon.svg";
import {
  APP_PATHS,
  ASSESSMENT_MODAL_NAMES,
  REQUEST_TYPE,
  RQ_KEYS,
  LIMIT,
} from "../../../../../utils/constants";
import { API_ROUTE } from "../../../../../utils/api";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ManageTasks from "../../../../../common/Parts/ModalPopups/AssessmentModals/ManageTasks";
import ViewArticle from "../../../../../common/Parts/ModalPopups/AssessmentModals/ManageArticles/ViewArticle";
import DeleteChapter from "../../../../../common/Parts/ModalPopups/AssessmentModals/DeleteChapter";
import { flatten } from "../../../../../utils/helpers";
import DeleteArticle from "../../../../../common/Parts/ModalPopups/AssessmentModals/DeleteArticle";
import DeleteQuestion from "../../../../../common/Parts/ModalPopups/AssessmentModals/DeleteQuestion";
import DeleteTask from "../../../../../common/Parts/ModalPopups/AssessmentModals/DeleteTask";
import Tooltip from "../../../../../common/Parts/ToolTip";
import { IModal } from "../../../../../interfaces/IModal";
import LoadingHoc from "../../../../../common/components/LoadingHoc";
import { ReactComponent as BackArrow } from "../../../../../assests/svg/backArrow.svg";
import PriorityBadge from "../../../../../common/components/PriorityBadge";
import CurrentFollowUp from "./CurrentFollowUp";
import ShowQuestions from "./ShowQuestions";

interface IState {
  selectedChapters: any;
  selectedArticles: any;
  selectedQuestions: any;
  selectedTask: any;
}
const Chapter = () => {
  const [dataLimit, setDataLimit] = useState<any>({ limit: LIMIT, offset: 0 });
  const [searchKey, setSearchKey] = React.useState<string>("null");
  // const { assessment_id } = useParams();
  const [searchParams] = useSearchParams();
  const assessment_id = searchParams.get("assessment_id");
  console.log("assessment_id", assessment_id);
  const navigate = useNavigate();
  const [showGuidance, setShowGuidance] = useState<number[]>([]);
  const [showOption, setShowOption] = useState(false);
  const [showChapter, setShowChapter] = useState(true);
  const [selectedChapterId, setSelectedChapterId] = useState(null);
  const [showModal, setModal] = React.useState<IModal>({
    state: false,
    modalName: "",
    data: "",
  });
  const assessmentLoadingState = _getQueryState({
    key: `${RQ_KEYS.ASSESSMENT_BY_IDS}_loading`,
  });
  const [state, setState] = React.useState<IState | null>(null);
  const onSuccessArchiveUpdate = async () => {
    navigate(APP_PATHS.ASSESSMENTS);
    await getAllArchiveAssessments.mutate({
      url: `${API_ROUTE.GET_ARCHIVE_ASSESSMENT_LIST}?limit=10&offset=0`,
      requestType: REQUEST_TYPE._GET,
    });
  };

  let assessment: any = useApiQuery({
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_ASSESSMENT_BY_ID}/${assessment_id}`,
    staleTime: 0,
    queryKey: RQ_KEYS.ASSESSMENT_BY_IDS,
  });
  let getAllArchiveAssessments: any = _useMutation({
    queryKey: RQ_KEYS.ARCHIVE_ASSESSMENT_LIST,
  });

  console.log("assessments A", assessment, assessmentLoadingState);
  assessment = assessment?.data;
  let chapters: any = useApiQuery({
    queryKey: RQ_KEYS.ALL_CHAPTERS,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_CHAPTERS_LIST_OBOEDIO}/${assessment_id}`,
    staleTime: 10,
  });
  const isLoading = chapters?.isLoading;
  chapters = chapters?.data;
  console.log("chapters data", chapters);
  // const isLoading = _getQueryState({ key: RQ_KEYS.LOADING });
  // let { isLoading } = useApiQuery({
  //   queryKey: RQ_KEYS.ORG_ADMINS_LIST,
  //   requestType: REQUEST_TYPE._GET,
  //   url:
  //     API_ROUTE.GET_ORG_ADMINS_LIST +
  //     `/${dataLimit.limit}/${dataLimit.offset}/${searchKey}`,
  // });

  return (
    <React.Fragment>
      <div
        className={`manageAssessmentContainer ${styles.manageAssessmentContainer}`}
      >
        <LoadingHoc isLoading={assessmentLoadingState}>
          <div className="contentHeader">
            <div className={styles.backBtn}>
              <Button
                label=""
                id="backBtn"
                color="var(--blackColor)"
                background="transparent"
                fontSize="var(--secondaryFont)"
                onClick={() => {
                  navigate(-1);
                }}
                border="none"
                Icon={<BackArrow className="svg_image_icon" />}
              />
            </div>
            <Breadcrumb
              pageName={`Assessment / ${
                assessment?.assessment_name ? assessment?.assessment_name : ""
              }`}
              path={
                [
                  // "Dashboard",
                  // "Assessments",
                  // assessment?.assessment_name ? assessment?.assessment_name : "",
                  // "Chapter",
                ]
              }
              action={{
                buttonOne: assessment?.publish_status === "Draft" && (
                  <Button
                    label="+ Add Chapter"
                    id="addChapterBtn"
                    borderRadius="5px"
                    fontWeight="bold"
                    color="var(--secondaryColor)"
                    // maxWidth="140px"
                    lineHeight="initial"
                    fontSize="var(--secondaryFont)"
                    border="1px solid var(--secondaryColor)"
                    background="var(--whiteColor)"
                    onClick={() => {
                      setModal({
                        state: true,
                        modalName: ASSESSMENT_MODAL_NAMES.CHAPTER,
                        type: "CREATE",
                      });
                    }}
                  />
                ),
              }}
            />
          </div>

          <div className={styles.manageAssessmentBody}>
            {chapters && chapters.length > 0 ? (
              chapters.map(
                (
                  { chapter_name, chapter_id, articles, chapter_number }: any,
                  index: number
                ) => {
                  return (
                    <div className={styles.assessmentMain} key={index}>
                      <div className={styles.assessHeaderMaindiv}>
                        <div className={styles.accordionContainerChapter}>
                          {selectedChapterId === chapter_id ? (
                            <Tooltip text="Hide">
                              <UpIcon
                                width={10}
                                height={10}
                                onClick={() => {
                                  setSelectedChapterId(null);
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip text="Show">
                              <DownIcon
                                width={10}
                                height={10}
                                onClick={() => {
                                  // const newGuidance = [
                                  //   ...showGuidance,
                                  //   question_id,
                                  // ];
                                  setSelectedChapterId(chapter_id);
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                                }}
                              />
                            </Tooltip>
                          )}
                          <h2>{`Chapter ${index + 1}: ${chapter_name} `}</h2>
                        </div>
                        {assessment?.publish_status === "Draft" &&
                          articles &&
                          articles.length > 0 && (
                            <div className={styles.sectionAddBtn}>
                              <div className={styles.iconParent}>
                                <Tooltip text="Edit">
                                  <EditIcon
                                    className={styles.iconBlue}
                                    onClick={() => {
                                      setState((prev: any) => ({
                                        ...prev,
                                        selectedChapters: {
                                          chapter_name,
                                          chapter_id,
                                          chapter_number,
                                        },
                                      }));
                                      setModal({
                                        state: true,
                                        modalName:
                                          ASSESSMENT_MODAL_NAMES.CHAPTER,
                                        type: "UPDATE",
                                      });
                                      // setShowEditPopup(true);
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip text="Delete">
                                  <DeleteIcon
                                    //    fill="var(--textColor)"
                                    className={styles.iconRed}
                                    onClick={() => {
                                      setState((prev: any) => {
                                        return {
                                          ...prev,
                                          selectedChapters: {
                                            chapter_name,
                                            // chapter_number,
                                            chapter_id,
                                            article_ids:
                                              articles &&
                                              articles.map(
                                                (article: any) =>
                                                  article.article_id
                                              ),
                                            question_ids:
                                              articles &&
                                              articles.map((article: any) => {
                                                const artQues =
                                                  article.questions.map(
                                                    (question: any) =>
                                                      question?.question_id
                                                  );

                                                return flatten(artQues);
                                              }),
                                            task_ids:
                                              articles &&
                                              articles.map((article: any) => {
                                                const artTasks =
                                                  article.tasks.map(
                                                    (task: any) => task.task_id
                                                  );
                                                return flatten(artTasks);
                                              }),
                                          },
                                        };
                                      });
                                      setModal({
                                        state: true,
                                        modalName:
                                          ASSESSMENT_MODAL_NAMES.DELETE_CHAPTER,
                                        type: "DELETE",
                                      });
                                      // setShowEditPopup(true);
                                    }}
                                  />
                                </Tooltip>
                              </div>
                              <Button
                                label="+ Add Article"
                                id="btn"
                                borderRadius="5px"
                                // padding="4px 10px"
                                fontWeight="bold"
                                border="none"
                                fontSize="15px"
                                onClick={() => {
                                  setState((prev: any) => ({
                                    ...prev,
                                    selectedChapters: {
                                      chapter_name,
                                      chapter_id,
                                      chapter_number,
                                    },
                                  }));
                                  setModal({
                                    state: true,
                                    modalName: ASSESSMENT_MODAL_NAMES.ARTICLE,
                                    type: "CREATE",
                                  });
                                }}
                              />
                            </div>
                          )}
                      </div>
                      {assessment?.publish_status === "Draft" &&
                        articles &&
                        articles.length < 1 && (
                          <Button
                            label="+ Add Article"
                            id="btn"
                            borderRadius="5px"
                            padding="0px"
                            fontWeight="bold"
                            color="var(--secondaryColor)"
                            border="none"
                            fontSize="15px"
                            background="var(--transparentColor)"
                            onClick={() => {
                              setState((prev: any) => ({
                                ...prev,
                                selectedChapters: {
                                  chapter_name,
                                  chapter_id,
                                  chapter_number,
                                },
                              }));
                              setModal({
                                state: true,
                                modalName: ASSESSMENT_MODAL_NAMES.ARTICLE,
                                type: "CREATE",
                              });
                            }}
                          />
                        )}
                      {selectedChapterId === chapter_id &&
                        articles &&
                        articles.length > 0 &&
                        articles.map(
                          (
                            {
                              article_name,
                              article_id,
                              risk_level,
                              risk_description,
                              law,
                              questions,
                              tasks,
                              article_number,
                            }: any,
                            index: number
                          ) => {
                            const followupQuestions = questions.filter(
                              (ques: any) => ques.parent_option_id !== null
                            );

                            const dynamicArticleNumber = index + 1;

                            return (
                              <div
                                className={styles.addedArticleSection}
                                key={article_id}
                              >
                                <label>
                                  <div
                                    className={styles.articleWithIconContainer}
                                  >
                                    <h4>{`Article ${article_number}: ${article_name}`}</h4>

                                    {assessment?.publish_status === "Draft" && (
                                      <div className={styles.iconParent}>
                                        <Tooltip text="View">
                                          <InfoIcon
                                            // fill="var(--textColor)"
                                            // stroke="black"
                                            className={styles.iconPrimary}
                                            onClick={() => {
                                              setState((prev: any) => ({
                                                ...prev,
                                                selectedChapters: {
                                                  chapter_name,
                                                  chapter_number,
                                                  chapter_id,
                                                },
                                                selectedArticles: {
                                                  article_name,
                                                  article_id,
                                                  article_number:
                                                    article_number,
                                                  risk_level,
                                                  risk_description,
                                                  law,
                                                },
                                              }));
                                              setModal({
                                                state: true,
                                                modalName:
                                                  ASSESSMENT_MODAL_NAMES.VIEW_ARTICLE,
                                                // type: "UPDATE",
                                              });
                                            }}
                                          />
                                        </Tooltip>

                                        <Tooltip text="Edit">
                                          <EditIcon
                                            // fill="var(--textColor)"
                                            className={styles.iconBlue}
                                            onClick={() => {
                                              setState((prev: any) => ({
                                                ...prev,
                                                selectedChapters: {
                                                  chapter_name,
                                                  chapter_number,
                                                  chapter_id,
                                                },
                                                selectedArticles: {
                                                  article_name,
                                                  article_id,
                                                  article_number:
                                                    article_number,
                                                  risk_level,
                                                  risk_description,
                                                  law,
                                                },
                                              }));
                                              setModal({
                                                state: true,
                                                modalName:
                                                  ASSESSMENT_MODAL_NAMES.ARTICLE,
                                                type: "UPDATE",
                                              });
                                            }}
                                          />
                                        </Tooltip>
                                        <Tooltip text="Delete">
                                          <DeleteIcon
                                            //  fill="var(--textColor)"
                                            className={styles.iconRed}
                                            onClick={() => {
                                              setState((prev: any) => {
                                                return {
                                                  ...prev,
                                                  selectedArticles: {
                                                    article_id,
                                                    question_ids: questions
                                                      .filter(
                                                        (ques: any) =>
                                                          ques.parent_option_id ===
                                                          null
                                                      )
                                                      .map((question: any) => {
                                                        return question?.question_id;
                                                      }),
                                                    task_ids: tasks.map(
                                                      (task: any) => {
                                                        return task.task_id;
                                                      }
                                                    ),
                                                  },
                                                };
                                              });
                                              setModal({
                                                state: true,
                                                modalName:
                                                  ASSESSMENT_MODAL_NAMES.DELETE_ARTICLE,
                                                type: "DELETE",
                                              });
                                              // setShowEditPopup(true);
                                            }}
                                          />
                                        </Tooltip>
                                      </div>
                                    )}
                                  </div>
                                </label>

                                <ShowQuestions
                                  topLevelQuestions={questions.filter(
                                    (ques: any) =>
                                      ques.parent_option_id === null
                                  )}
                                  referenceQuestions={questions}
                                  setState={setState}
                                  setModal={setModal}
                                  showGuidance={showGuidance}
                                  setShowGuidance={setShowGuidance}
                                  showOption={showOption}
                                  setShowOption={setShowOption}
                                  assessment={assessment}
                                  status={assessment?.publish_status}
                                  chapterData={{
                                    chapter_id,
                                    chapter_name,
                                    chapter_number,
                                  }}
                                  articleData={{
                                    article_name,
                                    article_id,
                                    article_number: article_number,
                                    risk_level,
                                    risk_description,
                                    law,
                                  }}
                                />

                                {assessment?.publish_status === "Draft" && (
                                  <div className={styles.sectionAddBtn}>
                                    <Button
                                      label="+ Add Question"
                                      id="btn"
                                      borderRadius="5px"
                                      padding="0px"
                                      fontWeight="bold"
                                      color="var(--secondaryColor)"
                                      border="none"
                                      fontSize="15px"
                                      background="var(--transparentColor)"
                                      onClick={() => {
                                        setState((prev: any) => ({
                                          ...prev,
                                          selectedChapters: {
                                            chapter_name,
                                            chapter_id,
                                            chapter_number,
                                          },
                                          selectedArticles: {
                                            article_name,
                                            article_id,
                                            article_number: article_number,
                                            risk_level,
                                            risk_description,
                                            law,
                                          },
                                        }));
                                        setModal({
                                          state: true,
                                          modalName:
                                            ASSESSMENT_MODAL_NAMES.QUESTION,
                                          type: "CREATE",
                                        });
                                      }}
                                    />
                                  </div>
                                )}
                                {/* <div className={styles.questionsList}> */}
                                {tasks && tasks.length > 0 ? (
                                  <div
                                    className={`${styles.questionsList} ${styles.addedArticleSection}`}
                                  >
                                    {tasks &&
                                      tasks.length > 0 &&
                                      tasks.map(
                                        (
                                          {
                                            task,
                                            task_id,
                                            question_ids,
                                            risk_level,
                                            article_id,
                                          }: any,
                                          index: number
                                        ) => {
                                          return (
                                            <p key={task_id}>
                                              <b>Task {index + 1}: </b>{" "}
                                              <span>
                                                {task}
                                                <PriorityBadge
                                                  priority={risk_level}
                                                />
                                              </span>
                                              {assessment?.publish_status ===
                                                "Draft" && (
                                                <div
                                                  className={styles.iconParent}
                                                >
                                                  <Tooltip text="Edit">
                                                    <EditIcon
                                                      //  fill="var(--textColor)"
                                                      className={
                                                        styles.iconBlue
                                                      }
                                                      onClick={() => {
                                                        setModal({
                                                          state: true,
                                                          modalName:
                                                            ASSESSMENT_MODAL_NAMES.TASK,
                                                          type: "UPDATE",
                                                        });
                                                        setState(
                                                          (prev: any) => ({
                                                            ...prev,
                                                            selectedTask: {
                                                              task,
                                                              task_id,
                                                              question_ids,
                                                            },
                                                            selectedArticles: {
                                                              ...prev?.selectedArticles,
                                                              article_id,
                                                            },
                                                            selectedChapters: {
                                                              chapter_name,
                                                              chapter_id,
                                                              articles,
                                                              chapter_number,
                                                            },
                                                          })
                                                        );
                                                      }}
                                                    />
                                                  </Tooltip>
                                                  <Tooltip text="Delete">
                                                    <DeleteIcon
                                                      // fill="var(--textColor)"
                                                      className={styles.iconRed}
                                                      onClick={() => {
                                                        setState(
                                                          (prev: any) => {
                                                            return {
                                                              ...prev,
                                                              selectedTask:
                                                                task_id,
                                                            };
                                                          }
                                                        );
                                                        setModal({
                                                          state: true,
                                                          modalName:
                                                            ASSESSMENT_MODAL_NAMES.DELETE_TASK,
                                                          type: "DELETE",
                                                        });
                                                        // setShowEditPopup(true);
                                                      }}
                                                    />
                                                  </Tooltip>
                                                </div>
                                              )}
                                            </p>
                                          );
                                        }
                                      )}
                                  </div>
                                ) : (
                                  ""
                                )}

                                {assessment?.publish_status === "Draft" && (
                                  <div className={styles.sectionAddBtn}>
                                    <Button
                                      label="+ Add Tasks"
                                      id="btn"
                                      borderRadius="5px"
                                      padding="0px"
                                      fontWeight="bold"
                                      color="var(--secondaryColor)"
                                      border="none"
                                      fontSize="15px"
                                      background="var(--transparentColor)"
                                      onClick={() => {
                                        setState((prev: any) => ({
                                          ...prev,
                                          selectedArticles: {
                                            article_name,
                                            article_id,
                                            article_number: article_number,
                                            risk_level,
                                            risk_description,
                                            law,
                                          },
                                          selectedChapters: {
                                            chapter_name,
                                            chapter_id,
                                            articles,
                                            chapter_number,
                                          },
                                        }));
                                        setModal({
                                          state: true,
                                          modalName:
                                            ASSESSMENT_MODAL_NAMES.TASK,
                                          type: "CREATE",
                                        });
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          }
                        )}
                    </div>
                  );
                }
              )
            ) : (
              <div>No Chapter</div>
            )}
          </div>
          {assessment?.publish_status === "Draft" && (
            <div className={styles.headerButtons}>
              <Button
                label="+ Add Chapter"
                id="addChapterBtn"
                fontWeight="bold"
                color="var(--secondaryColor)"
                // maxWidth="150px"
                lineHeight="initial"
                fontSize="var(--secondaryFont)"
                // border="1px solid var(--secondaryColor)"
                background="transparent"
                onClick={() => {
                  setModal({
                    state: true,
                    modalName: ASSESSMENT_MODAL_NAMES.CHAPTER,
                    type: "CREATE",
                  });
                }}
              />
            </div>
          )}
        </LoadingHoc>
      </div>
      {showModal &&
        showModal.state &&
        showModal.modalName === ASSESSMENT_MODAL_NAMES.CHAPTER && (
          <ManageChapters
            openState={showModal}
            heading={
              showModal?.type === "CREATE" ? "Add Chapter" : "Edit Chapter"
            }
            type={showModal?.type}
            data={state && state?.selectedChapters}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
          />
        )}
      {showModal &&
        showModal.state &&
        showModal.modalName === ASSESSMENT_MODAL_NAMES.ARTICLE && (
          <ManageArticles
            openState={showModal}
            heading={
              showModal?.type === "CREATE" ? "Add Article" : "Edit Article"
            }
            type={showModal?.type}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
            chapter_id={
              state &&
              state?.selectedChapters &&
              state?.selectedChapters?.chapter_id
            }
            chapter_number={
              state &&
              state?.selectedChapters &&
              state?.selectedChapters?.chapter_number
            }
            data={state && state?.selectedArticles}
          />
        )}
      {showModal &&
        showModal.state &&
        showModal.modalName === ASSESSMENT_MODAL_NAMES.VIEW_ARTICLE && (
          <ViewArticle
            openState={showModal}
            heading="View Article"
            type={showModal?.type}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
            data={state && state?.selectedArticles}
          />
        )}
      {showModal &&
        showModal.state &&
        showModal.modalName === ASSESSMENT_MODAL_NAMES.QUESTION && (
          <ManageQuestions
            openState={showModal}
            heading={
              showModal?.type === "CREATE" ? "Add Question" : "Edit Question"
            }
            type={showModal?.type}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
            article_id={
              state &&
              state?.selectedArticles &&
              state?.selectedArticles?.article_id
            }
            chapter_id={
              state &&
              state?.selectedChapters &&
              state?.selectedChapters?.chapter_id
            }
            data={state && state?.selectedQuestions}
            followupData={showModal?.data || undefined}
          />
        )}
      {showModal &&
        showModal.state &&
        showModal.modalName === ASSESSMENT_MODAL_NAMES.TASK && (
          <ManageTasks
            openState={showModal}
            heading={showModal?.type === "CREATE" ? "Add Task" : "Edit Task"}
            type={showModal?.type}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
            article_id={
              state &&
              state?.selectedArticles &&
              state?.selectedArticles?.article_id
            }
            chapter_id={
              state &&
              state?.selectedChapters &&
              state?.selectedChapters?.chapter_id
            }
            data={state && state?.selectedTask}
          />
        )}
      {/* DELETE modals */}
      {showModal &&
        showModal.state &&
        showModal.modalName === ASSESSMENT_MODAL_NAMES.DELETE_CHAPTER && (
          <DeleteChapter
            openState={showModal}
            heading="Delete Chapter"
            type={showModal?.type}
            data={state && state?.selectedChapters}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
          />
        )}

      {showModal &&
        showModal.state &&
        showModal.modalName === ASSESSMENT_MODAL_NAMES.DELETE_ARTICLE && (
          <DeleteArticle
            openState={showModal}
            heading="Delete Article"
            type={showModal?.type}
            data={state && state?.selectedArticles}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
          />
        )}

      {showModal &&
        showModal.state &&
        showModal.modalName === ASSESSMENT_MODAL_NAMES.DELETE_QUESTION && (
          <DeleteQuestion
            openState={showModal}
            heading="Delete Question"
            type={showModal?.type}
            data={state && state?.selectedQuestions}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
          />
        )}

      {showModal &&
        showModal.state &&
        showModal.modalName === ASSESSMENT_MODAL_NAMES.DELETE_TASK && (
          <DeleteTask
            openState={showModal}
            heading="Delete Task"
            type={showModal?.type}
            data={state && state?.selectedTask}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
          />
        )}
    </React.Fragment>
  );
};

export default Chapter;
