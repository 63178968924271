import React from "react";
import Badge from "react-bootstrap/Badge";

type Priority = "Low" | "Medium" | "Severe";

type PriorityBadgeProps = {
  priority: Priority;
};

const PriorityBadge = ({ priority }: PriorityBadgeProps) => {
  const getBg = () => {
    if (priority === "Severe") {
      return "danger";
    } else if (priority === "Medium") {
      return "warning";
    } else {
      return "primary";
    }
  };
  return <Badge bg={getBg()} style={{marginLeft:'5px'}}>{priority}</Badge>;
};

export default PriorityBadge;
