import * as Yup from "yup";

const BranchLocationSchema = Yup.object().shape({
  branch_name: Yup.string().required("This field is required"),
  street_address_1: Yup.string().required("This field is required"),
  zipcode: Yup.string()
    .min(6, "Must be at least 6 characters")
    .max(8, "Must be at most 8 characters"),
  city: Yup.string(),
  region: Yup.string().required("Region is required"),
  country: Yup.string(),
  is_headquarter: Yup.string(),
});

const BranchLocationInitialValue = {
  branch_name: "",
  street_address_1: "",
  region: "",
  country: "",
  state: "",
  is_headquarter: "",
  org_type: "",
  city: "",
  zipcode: "",
};

export { BranchLocationSchema, BranchLocationInitialValue };
