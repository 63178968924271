import React from "react";
import styles from "./style.module.scss";
// import { InputField, MultiSelect } from "../../../../components";
import { IInviteUser } from "../../../../../interfaces/IAssessmentModals";
import { Button, InputField, MultiSelect } from "../../../../components";
import { Formik, Form } from "formik";
import cancel_icon from "../../../../../assests/svg/cancel_icon.svg";
import { UpdateOrganizationSchema } from "./validator";
import { ReactComponent as CancelBtn } from "../../../../../assests/svg/close.svg";
import { ReactComponent as UpdateIcon } from "../../../../../assests/svg/update_icon.svg";
import { ReactComponent as EditIcon } from "../../../../../assests/svg/edit_icon.svg";
import {
  _getQueryState,
  _useMutation,
  // _useMutation,
  useApiQuery,
} from "../../../../../services/useAxiosQuery";
import { REQUEST_TYPE, RQ_KEYS } from "../../../../../utils/constants";
import { API_ROUTE } from "../../../../../utils/api";
import Toast from "../../../../components/Toast";
import { ITOAST_STATE } from "../../../../../interfaces/IToastState";
import { useQueryClient } from "react-query";
import { Modal } from "@mui/material";
const ManageOrganizationPopup: React.FC<IInviteUser> = ({
  onClickCancel,
  heading,
  data,
  org_id,
  type,
  openState,
}) => {
  const queryClient = useQueryClient();
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  console.log("POPUP DATA", data);

  const getUserOrgMutate = _useMutation({
    queryKey: RQ_KEYS.USER_ORGANIZATION,
  });
  const onSuccess = async () => {
    await getUserOrgMutate.mutate({
      url: `${API_ROUTE.GET_USER_ORGANIZATION}`,
      requestType: REQUEST_TYPE._GET,
    });
    onClickCancel();
  };
  const editOrgDetails = _useMutation({ onSuccess });

  const industryList = useApiQuery({
    queryKey: RQ_KEYS.INDUSTRIES,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_INDUSTRIES,
  });
  console.log(industryList.data, " industryList");

  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.invitePopupRow1}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>
          {/* <Toast toastState={toastState} /> */}
          <Formik
            initialValues={{
              org_name: data?.org_name,
              org_type: data?.org_type,
              org_industries: data?.org_industries.map((ind: string) => ({
                value: ind,
                label: ind,
              })),
            }}
            validationSchema={UpdateOrganizationSchema}
            enableReinitialize={true}
            onSubmit={async (values) => {
              console.log("ONSUBMIT VALUES", values);
              queryClient.setQueryData(RQ_KEYS.LOADING, true);

              await editOrgDetails.mutate({
                url: API_ROUTE.EDIT_USER_ORGANIZATION,
                requestType: REQUEST_TYPE._PATCH,
                requestData: {
                  ...values,
                  org_industries: values.org_industries.map(
                    (val: any) => val.value
                  ),
                  org_id,
                },
              });
            }}
          >
            {({ handleChange, isValid, values, setFieldValue, handleBlur }) => (
              <div className={styles.invitePopupRow2}>
                <Form>
                  <div className={styles.userLogin}>
                    <div className={`${styles.userLoginInner} ${styles.full}`}>
                      <InputField
                        name="org_name"
                        label="Organization Name"
                        type="text"
                        alignItems="left"
                        placeholder="Organization Name"
                        isRequired={true}
                        borderRadius="14px"
                        width="100%"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.org_name}
                        inputBoxStyle={styles.inputBox}
                        gap="5px"
                        display="flex"
                      />
                    </div>
                    <div className={`${styles.userLoginInner} ${styles.full}`}>
                      <MultiSelect
                        name="org_type"
                        label="Organization Type"
                        isRequired={true}
                        placeHolder="Select Organization Type"
                        options={[
                          { value: "Private", label: "Private" },
                          { value: "Public", label: "Public" },
                        ]}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        values={{
                          value: values?.org_type,
                          label: values?.org_type,
                        }}
                        isMulti={false}
                        onBlur={handleBlur}
                      />
                    </div>

                    <MultiSelect
                      name="org_industries"
                      label="Organization Industry"
                      isMulti={true}
                      type={type}
                      long={true}
                      className={styles.editOrgReactSelect}
                      isRequired={true}
                      defaultValue={values?.org_industries}
                      options={industryList?.data}
                      values={values?.org_industries}
                      setFieldValue={setFieldValue}
                      onBlur={handleBlur}
                    // onChange={handleChange}
                    />
                  </div>
                  <div className={styles.inviteUserBtn}>
                    <Button
                      label="Cancel"
                      id="cancelbtn"
                      type="button"
                      borderRadius="5px"
                      fontWeight="bold"
                      // maxWidth="100px"
                      fontSize="var(--secondaryFont)"
                      background="var(--whiteColor)"
                      border="1px solid var(--secondaryColor)"
                      color="var(--secondaryColor)"
                      disabled={loadingState}
                      Icon={
                        <CancelBtn fill="#0097ce" style={{ maxWidth: "12px" }} />
                      }
                      onClick={onClickCancel}
                    />
                    <Button
                      label="Update"
                      id="saveAssessmentbtn"
                      borderRadius="5px"
                      fontWeight="bold"
                      color="var(--whiteColor)"
                      // maxWidth="180px"
                      fontSize="var(--secondaryFont)"
                      border="none"
                      disabled={loadingState}
                      loading={loadingState}
                      type="submit"
                      Icon={
                        <UpdateIcon fill="#ffffff" className="svg_image_icon" />
                      }
                    />
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default ManageOrganizationPopup;
