import React from "react";
import styles from "./style.module.scss";
import {
  IAssignUnassignUserAssessment,
  IInviteUser,
} from "../../../../../interfaces/IAssessmentModals";
import { Button } from "../../../../components";
import { Formik, Form } from "formik";
import cancel_icon from "../../../../../assests/svg/cancel_icon.svg";
import {
  _getQueryState,
  _useMutation,
} from "../../../../../services/useAxiosQuery";
import { REQUEST_TYPE, RQ_KEYS } from "../../../../../utils/constants";
import Toast from "../../../../components/Toast";
import { ITOAST_STATE } from "../../../../../interfaces/IToastState";
import { ReactComponent as CancelBtn } from "../../../../../assests/svg/close.svg";
import { ReactComponent as UnAssignBtn } from "../../../../../assests/svg/un_assign.svg";
import { ReactComponent as AssignBtn } from "../../../../../assests/svg/assign.svg";
import { useQueryClient } from "react-query";
import { API_ROUTE } from "../../../../../utils/api";
import { Modal } from "@mui/material";

const AssignUnassignUserAssessment: React.FC<IAssignUnassignUserAssessment> = ({
  onClickCancel,
  openState,
  // onClick,
  heading,
  data,
  type,
}) => {
  const queryClient = useQueryClient();
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  const onDeleteSuccess = () => {
    queryClient.refetchQueries({ queryKey: RQ_KEYS.ORG_ADMIN_BY_ID });
    onClickCancel();
  };

  const deleteOrgAssessment = _useMutation({ onSuccess: onDeleteSuccess });
  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.addChapterHeader}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>
          <Toast toastState={toastState} />
          <Formik
            initialValues={{ delete: "" }}
            // validationSchema={ChatperSchema}
            onSubmit={async (values) => {
              queryClient.setQueryData(RQ_KEYS.LOADING, true);
              deleteOrgAssessment.mutate({
                url: API_ROUTE.ASSIGN_UNASSIGN_ORG_ASSESSMENT,
                requestType: REQUEST_TYPE._PATCH,
                requestData: {
                  ...data,
                  action: type,
                },
              });
              queryClient.removeQueries(RQ_KEYS.TOAST_STATE);
            }}
          >
            {({ handleChange, isValid, values, setFieldValue, handleBlur }) => (
              <div className={styles.addChapterRow}>
                <Form>
                  <div className={styles.userLogin}>
                    {type === "ASSIGNED" ? (
                      <h5>Are you sure you want to reassign this Assessment?</h5>
                    ) : (
                      <>
                        {" "}
                        <h5>
                          Are you sure you want to unassign this Assessment?
                        </h5>
                        <p className={styles.unassignModalNote}>
                          Note: The Org. Admin will not be able to view this
                          assessment once unassigned.
                        </p>
                      </>
                    )}
                  </div>
                  <div className={styles.inviteUserBtn}>
                    <Button
                      label="Cancel"
                      id="cancelbtn"
                      borderRadius="5px"
                      fontWeight="bold"
                      // maxWidth="100px"
                      fontSize="var(--secondaryFont)"
                      background="var(--whiteColor)"
                      border="1px solid var(--secondaryColor)"
                      color="var(--secondaryColor)"
                      Icon={
                        <CancelBtn fill="#0097ce" style={{ maxWidth: "12px" }} />
                      }
                      onClick={onClickCancel}
                    />
                    {type === "ASSIGNED" && (
                      <Button
                        label="ReAssign"
                        id="deleteChapterbtn"
                        borderRadius="5px"
                        fontWeight="bold"
                        color="var(--whiteColor)"
                        background="var(--secondaryColor)"
                        fontSize="var(--secondaryFont)"
                        border="none"
                        type="submit"
                        Icon={
                          <AssignBtn
                            fill="#ffffff"
                            style={{ maxWidth: "14px" }}
                          />
                        }
                        disabled={!isValid || loadingState}
                      />
                    )}
                    {type === "UNASSIGNED" && (
                      <Button
                        label="UnAssign"
                        id="deleteChapterbtn"
                        borderRadius="5px"
                        fontWeight="bold"
                        color="var(--whiteColor)"
                        background="red"
                        // maxWidth="120px"
                        fontSize="var(--secondaryFont)"
                        border="none"
                        type="submit"
                        Icon={
                          <UnAssignBtn
                            fill="#ffffff"
                            style={{ maxWidth: "14px" }}
                          />
                        }
                        disabled={!isValid || loadingState}
                      />
                    )}
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
      </Modal>
      
      );
};

      export default AssignUnassignUserAssessment;
