import * as Yup from "yup";
const CreateAssessmentSchema = Yup.object().shape({
  assessment_name: Yup.string().required("This field is required"),
  // assessment_jurisdiction_state: Yup.string().required(
  //   "This field is required"
  // ),
  assessment_industry: Yup.string().required("This field is required"),
  // assessment_region: Yup.string().required("This field is required"),
  assessment_category: Yup.string().required("This field is required"),
  assessment_location: Yup.array().of(
    Yup.object().shape({
      assessment_region: Yup.string().required("Region is required"),
      assessment_jurisdiction_country: Yup.string(),
      // assessment_jurisdiction_state: Yup.string().required("Name is required"),
    })
  ),

  // section_name: Yup.string().required("This field is required"),
  // subsection_name: Yup.string().required("This field is required"),
  // question_name: Yup.string().required("This field is required"),
  // answer_name: Yup.string().required("This field is required"),
});

const AssessmentInitialValue = {
  assessment_name: "",
  // assessment_jurisdiction_country: "",
  // assessment_jurisdiction_state: "",
  assessment_industry: "",
  // assessment_region: "",
  assessment_location: [
    {
      assessment_region: "",
      assessment_jurisdiction_country: "Global",
      assessment_jurisdiction_state: "",
    },
  ],
  assessment_category: "",

  section_name: "",
  subsection_name: "",
  question_name: "",
  answer_name: "",
};

export { CreateAssessmentSchema, AssessmentInitialValue };
