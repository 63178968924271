import { DateRangePicker } from "react-date-range";
import { Button } from "../../../../common/components";
import { ReactComponent as CancelBtn } from "../../../../assests/svg/close.svg";

const DateRangePickerComp = ({
  dateRangeState,
  setDateRangeState,
  closeHandler,
}: any) => {
  const saveHandler = (item: any) => setDateRangeState([item.selection]);
  return (
    <>
      <DateRangePicker
        onChange={(item: any) => setDateRangeState([item.selection])}
        //   showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={dateRangeState}
        direction="horizontal"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          padding: "20px",
          gap: "10px",
        }}
      >
        <Button
          label="Cancel"
          id="selectDateRange"
          secondary={true}
          padding="5px 40px"
          icon
          onClick={closeHandler}
          Icon={
            <CancelBtn
              fill="#0097ce"
              style={{ maxWidth: "12px" }}
            />
          }
        />
      </div>
    </>
  );
};

export default DateRangePickerComp;
