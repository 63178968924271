import React, { useState } from "react";
import { IProgressBar } from "../../interfaces/IProgressBar";
import styles from "./style.module.scss";

const ProgressBar: React.FC<IProgressBar> = ({ progress, fullStatus }) => {
  return (
    <div
      className={`
      ${fullStatus && styles.fullStatus}
      ${styles.statusBar}`}
    >
      <div
        className={styles.statusBarProgress}
        style={{ width: `${progress}` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
