import styles from "./style.module.scss";
import { ReactComponent as UserIcon } from "../../../assests/svg/user_logo_fill.svg";

export interface ICommentObj {
  username: string;
  text: string;
  created_at: string;
  id: number;
}

interface IComment {
  comment: ICommentObj;
}

const Comment: React.FC<IComment> = ({ comment }) => {
  const { username, text, created_at } = comment;
  return (
    <div className={styles.viewComments}>
      <UserIcon
        className={styles.inputUserIcon}
        fill="var(--textColor)"
        style={{ maxWidth: "18px" }}
      />
      <div className={styles.viewCommentsUser}>
        <h5>{username}</h5>
        <p>{text}</p>
      </div>
      <span>{created_at}</span>
    </div>
  );
};

export default Comment;
