import React, { useState, useRef, useEffect } from "react";
import styles from "./style.module.scss";
import { IDropdown } from "../../../common/interfaces/IDropdown";
import { ReactComponent as ArrowIcon } from "../../../assests/svg/selectIcon.svg";

const Dropdown: React.FC<IDropdown> = ({
  dropdownData,
  label,
  onClick,
  className,
  classParent,
  placeholder,
  readOnly,
}) => {
  const [show, setShow] = React.useState<boolean>(false);
  const [currentValue, setCurrentValue] = React.useState(label);
  const dropdownRef: any = React.useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setShow(!show);
  };

  //const placeHolderTxt = !show && label ? styles.placeHolderTxt : "";
  console.log("total length : >>> " + dropdownData?.length);
  return (
    <div className={`${styles.dropdown} ${classParent}`} ref={dropdownRef}>
      <ul
        className={`${styles.dropdownPlaceholder} ${className}`}
        // onClick={() => setShow(!show)}
        onClick={toggleDropdown}
      >
        {label ? (
          <li className={styles.dropdowndataOverflowBug}>{currentValue}</li>
        ) : (
          <li className={styles.placeHolderTxt}>Select</li>
        )}
        <li>
          <ArrowIcon />

          {show && (
            <ul className={styles.listContainer}>
              {dropdownData?.map((data: any) => (
                <li
                  onClick={() => {
                    setShow(false);
                    onClick && onClick(data);
                    setCurrentValue(data.name);
                  }}
                >
                  {data.name}
                </li>
              ))}
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
};
export default Dropdown;
