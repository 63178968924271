import React from "react";
import { Breadcrumb } from "../../../../../common/Parts";
import styles from "./style.module.scss";
import { Button, Table } from "../../../../../common/components";
import { ReactComponent as EditIcon } from "../../../../../assests/svg/edit_icon.svg";
import { ReactComponent as BackIcon } from "../../../../../assests/svg/backArrow.svg";
import { ReactComponent as AssignIcon } from "../../../../../assests/svg/assign.svg";
import { ReactComponent as ViewIcon } from "../../../../../assests/svg/view_icon.svg";
import { ReactComponent as UnAssignBtn } from "../../../../../assests/svg/un_assign.svg";
import {
  APP_PATHS,
  APP_ROLES,
  ASSESSMENT_MODAL_NAMES,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../../utils/constants";
import {
  _useMutation,
  useApiQuery,
  _getQueryState,
} from "../../../../../services/useAxiosQuery";

import { API_ROUTE } from "../../../../../utils/api";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { Formik, Form } from "formik";
import AddAssessmentByAdmin from "../../../../../common/Parts/ModalPopups/AddAssessmentByAdmin";
import AssignUnassignUserAssessment from "../../../../../common/Parts/ModalPopups/AssessmentModals/Assign-UnassignUserAssessment";
import { Spinner } from "react-bootstrap";
import { Value } from "sass";
import CustomizedMenus from "../../../../../common/components/MenuList";

const ViewOrganizationDetails: React.FC = () => {
  const [showAdminAssessment, setShowAdminAssessment] =
    React.useState<boolean>(false);
  const [showModal, setModal] = React.useState<any>({
    state: false,
    modalName: "",
  });
  const [orgAssessmentId, setOrgAssessmentId] = React.useState(0);

  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });
  const { org_id } = useParams();
  const navigate = useNavigate();
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  const queryClient = useQueryClient();
  const onSuccess = ({ message }: any) => {
    queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
      message: message,
      toastType: "SUCCESS",
    });
  };

  const handleAssessmentClick = (assessment_id: string , assign_assessment_id : string): void => {
   
    if (
      userAuthData?.role === APP_ROLES.ORG_ADMIN ||
      userAuthData?.role === APP_ROLES.BRANCH_ADMIN ||
      userAuthData?.role === APP_ROLES.AUDITOR ||
      userAuthData?.role === APP_ROLES.VENDOR_USER ||
      userAuthData?.role === APP_ROLES.ORG_USER
      // userAuthData?.role === APP_ROLES.OBOEDIO_ADMIN
    ) {
      navigate(
        `${APP_PATHS.ASSESSMENT_CHAPTER_LIST}?assessment_id=${btoa(
          assessment_id
        )}`
      );
    }else if( userAuthData?.role === APP_ROLES.OBOEDIO_ADMIN){
      navigate(
        `${APP_PATHS.ASSESSMENT_CHAPTER_LIST}?assessment_id=${btoa(
          assign_assessment_id
        )}&isAssignAssessment=true`
      );
    }
  };

  let { data } = useApiQuery({
    queryKey: RQ_KEYS.ORG_ADMIN_BY_ID,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_ORG_ADMIN_BY_ID + `/${org_id}`,
    // staleTime: 1000,
  });
console.log("data>?>?>>>",data);
  const updateUserOrganizationDetails = _useMutation({ onSuccess });

  return (
    <React.Fragment>
      <div className={styles.editOrganisationContainer}>
        <div className="contentHeader">
          <div className={styles.backBtn}>
            <Button
              label=""
              id="backBtn"
              color="var(--blackColor)"
              background="transparent"
              fontSize="var(--secondaryFont)"
              onClick={() => {
                navigate(`${APP_PATHS.ORG_ADMIN_MANAGEMENT}`);
              }}
              border="none"
              Icon={<BackIcon className="svg_image_icon" />}
            />
          </div>
          <Breadcrumb
            pageName="View Organization"
            // path={[
            //   "Dashboard",
            //   "Organizations",
            //   "View Organization",
            //   "Org ID:" + org_id,
            // ]}
          />
        </div>
        <div className={styles.editOrganisationBody}>
          <Formik
            initialValues={data}
            enableReinitialize={true}
            onSubmit={async (values) => {
              const org_industries =
                values?.org_industries?.length > 0
                  ? values.org_industries.map(({ value }: any) => {
                      console.log("requestData", value);
                      return value;
                    })
                  : [];
              const org_region =
                values?.org_region?.length > 0
                  ? values.org_region.map(({ value }: any) => {
                      return value;
                    })
                  : [];

              const requestData = {
                ...values,
                org_industries: org_industries,
                org_region: org_region,
              };

              queryClient.setQueryData(RQ_KEYS.LOADING, true);
              await updateUserOrganizationDetails.mutate({
                url: API_ROUTE.UPDATE_ORG_USER_DETAILS,
                requestType: REQUEST_TYPE._PATCH,
                requestData,
              });
              queryClient.removeQueries(RQ_KEYS.TOAST_STATE);
            }}
          >
            {({ handleChange, isValid, values, setFieldValue, handleBlur }) => {
              console.log("values in view org", values);
              return (
                <Form className="signup-form" style={{ height: "100%" }}>
                  <div className={styles.login}>
                    <div className={styles.loginSection1}>
                      <div className={styles.loginForm}>
                        {values?.is_active !== "PENDING" && data && (
                          <Button
                            label="Edit"
                            // disabled={true}
                            id="editBtn"
                            borderRadius="5px"
                            fontWeight="bold"
                            color="var(--whiteColor)"
                            // maxWidth="140px"
                            fontSize="var(--secondaryFont)"
                            marginRight="25px"
                            onClick={() => {
                              navigate(
                                `${APP_PATHS.EDIT_ORGANIZATION}/${data.org_id}`
                              );
                            }}
                            border="none"
                            Icon={<EditIcon fill="#ffffff" />}
                          />
                        )}
                        <div className={styles.loginFormInner}>
                          <h2>View Organization Details</h2>
                          <h3>Organization Details</h3>
                          <div className={styles.flexEditOrg}>
                            <div className={styles.editOrgInput}>
                              <label>Organization ID: </label>
                              <p>{org_id ? org_id : "N/A"}</p>
                            </div>
                            <div className={styles.editOrgInput}>
                              <label>Organization Name: </label>
                              <p>
                                {values?.org_name ? values?.org_name : "N/A"}
                              </p>
                            </div>
                          </div>

                          <div className={styles.flexEditOrg}>
                            <div className={styles.editOrgInput}>
                              <label>Users: </label>
                              <p>{values?.users ? values?.users : "N/A"}</p>
                            </div>
                            <div className={styles.editOrgInput}>
                              <label>Assessments: </label>
                              <p>
                                {values?.assigned_assessment_count &&
                                values?.assigned_assessment_count > 0
                                  ? values?.assigned_assessment_count
                                  : "0"}
                              </p>
                            </div>
                          </div>

                          <div className={styles.flexEditOrg}>
                            <div className={styles.editOrgInput}>
                              <label>Orgnization Status: </label>
                              <p>
                                {values?.org_status
                                  ? values?.org_status
                                  : "N/A"}
                              </p>
                            </div>
                            <div className={styles.editOrgInput}>
                              <label>Organization Type: </label>
                              <p>
                                {values?.org_type ? values?.org_type : "N/A"}
                              </p>
                            </div>
                          </div>
                          <div className={styles.flexEditOrg}>
                            <div className={styles.editOrgInput}>
                              <label>Created At: </label>
                              {/* <p>{values?.assessments}</p>assessments */}
                              <p>
                                {values?.created_at
                                  ? values?.created_at
                                  : "N/A"}
                              </p>
                            </div>
                            <div className={styles.editOrgInput}>
                              <label>Organization Industries:</label>
                              {values && values.org_industries.length > 0 ? (
                                <p>
                                  {values.org_industries
                                    .map((item: { label: any }) => item.label)
                                    .join(", ")}
                                </p>
                              ) : (
                                <p>N/A</p>
                              )}
                            </div>
                          </div>
                          <div className={styles.flexEditLocation}>
                            <h4>Office Locations</h4>
                            {values?.location &&
                              values?.location.length > 0 &&
                              values?.location.map(
                                (
                                  { region, state, country }: any,
                                  index: number
                                ) => (
                                  <div
                                    className={`${styles.flexEditOrg} ${styles.editLoaction}`}
                                  >
                                    <div className={styles.regionBranch}>
                                      Office {index + 1}:
                                    </div>
                                    <div
                                      className={`${styles.editOrgInput} ${styles.editOrgRadio}`}
                                    >
                                      <label>Region: </label>
                                      <p>{region ? region : "N/A"}</p>
                                    </div>
                                    <div className={styles.editOrgInput}>
                                      <label>Country: </label>
                                      <p>{country ? country : "N/A"}</p>
                                    </div>
                                    <div
                                      className={`${styles.editOrgInput} ${styles.editOrgRadio}`}
                                    >
                                      <label>State: </label>
                                      <p>
                                        {state && state != "null"
                                          ? state
                                          : "N/A"}
                                      </p>
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                          <hr />
                          <div className={styles.assessmentBtnHead}>
                            <h4>Assigned Assessments</h4>
                            <div className={styles.assessmentBtnDiv}>
                              <Button
                                label="Assign Assessment"
                                borderRadius="5px"
                                id="assessmentBtn"
                                border="none"
                                fontWeight="bold"
                                fontSize="var(--secondaryFont)"
                                Icon={
                                  <AssignIcon
                                    fill="var(--whiteColor)"
                                    style={{width:'30px' }}
                                  />
                                }
                                onClick={() => {
                                  // queryClient.resetQueries({
                                  //   queryKey: RQ_KEYS.FILTERED_ASSESSMENT_LIST,
                                  // });
                                  setShowAdminAssessment(!showAdminAssessment);
                                }}
                                type="button"
                              />
                            </div>
                          </div>
                          <div className={styles.tableEditOrg}>
                            {!loadingState ? (
                              <Table
                                tableHeaders={[
                                  "Assessment Name",
                                  "Region",
                                  "Jurisdiction",
                                  // "Status",
                                ]}
                                tableData={
                                  data?.org_assessments.length > 0 &&
                                  data?.org_assessments.filter(
                                    ({ assigned_status }: any) =>
                                      assigned_status === "ASSIGNED"
                                  )
                                }
                                hideColumns={[
                                  "assessment_id",
                                  "org_assessment_id",
                                  "assigned_status",
                                  "org_id",
                                  "assign_assessment_id"
                                ]}
                                action={{
                                  name: "Action",
                                  buttonOne: (data: any) => {
                                    return (
                                      data && (
                                        <Button
                                          label="UnAssign"
                                          // borderRadius="0px"
                                          id="assessmentBtn"
                                          // border="none"
                                          // padding="0px"
                                          // background="transparent"
                                          // fontWeight="bold"
                                          // color="var(--redColor)"
                                          // justifyContent="left"
                                          // textAlign="left"
                                          // fontSize="var(--secondaryFont)"
                                          textDecoration="none"
                                          type="button"
                                          onClick={async () => {
                                            setModal({
                                              state: true,
                                              modalName:
                                                ASSESSMENT_MODAL_NAMES.UNASSIGN_USER_ASSESSMENT,
                                              type: "UNASSIGNED",
                                              data: data,
                                            });
                                          }}
                                          Icon={
                                            <UnAssignBtn
                                              fill="#ffffff"
                                              style={{ maxWidth: "14px", width:'30px' }}
                                            />
                                          }
                                        />
                                      )
                                    );
                                  },
                                  buttonTwo: (data: any) => {
                                    return (
                                      data && (
                                        <CustomizedMenus
                                          actions={[
                                            {
                                              label: "View",
                                              icon: <ViewIcon fill="var(--whiteColor)"/>,
                                              condition: true,
                                              onClick: () => {
                                                console.log("data>>>>>>",data);

                                                handleAssessmentClick(
                                                  data?.assessment_id , data?.assign_assessment_id

                                                );
                                              },
                                            },
                                          ]}
                                        />
                                      )
                                    );
                                  },
                                }}
                              />
                            ) : (
                              <div className={styles.assesmentLoader}>
                                <Spinner animation="border" variant="primary" />
                              </div>
                            )}

                            {data?.org_assessments &&
                              data?.org_assessments.filter(
                                ({ assigned_status }: any) =>
                                  assigned_status === "ASSIGNED"
                              )?.length === 0 && (
                                <h6
                                  style={{
                                    paddingTop: "1rem",
                                    textAlign: "center",
                                  }}
                                >
                                  No Assessments added yet
                                </h6>
                              )}
                          </div>
                          <div className={styles.assessmentBtnHead}>
                            <h4>Unassigned Assessments</h4>
                          </div>
                          <div className={styles.tableEditOrg}>
                            {!loadingState ? (
                              <Table
                                tableHeaders={[
                                  "Assessment Name",
                                  "Region",
                                  "Jurisdiction",
                                ]}
                                tableData={
                                  data?.org_assessments.length > 0 &&
                                  data?.org_assessments.filter(
                                    ({ assigned_status }: any) =>
                                      assigned_status === "UNASSIGNED"
                                  )
                                }
                                hideColumns={[
                                  "assessment_id",
                                  "org_assessment_id",
                                  "assigned_status",
                                  "org_id",
                                  "assign_assessment_id",
                                ]}
                                action={{
                                  name: "Action",
                                  buttonOne: (data: any) => {
                                    return (
                                      data && (
                                        <Button
                                          label="ReAssign"
                                          borderRadius="0px"
                                          id="assessmentBtn"
                                          border="none"
                                          padding="0px"
                                          background="transparent"
                                          fontWeight="bold"
                                          color="var(--secondaryColor)"
                                          justifyContent="left"
                                          textAlign="left"
                                          fontSize="var(--secondaryFont)"
                                          type="button"
                                          onClick={async () => {
                                            setModal({
                                              state: true,
                                              modalName:
                                                ASSESSMENT_MODAL_NAMES.UNASSIGN_USER_ASSESSMENT,
                                              type: "ASSIGNED",
                                              data,
                                            });
                                          }}
                                        />
                                      )
                                    );
                                  },
                                }}
                              />
                            ) : (
                              <div className={styles.assesmentLoader}>
                                <Spinner animation="border" variant="primary" />
                              </div>
                            )}

                            {data?.org_assessments &&
                              data?.org_assessments.filter(
                                ({ assigned_status }: any) =>
                                  assigned_status === "UNASSIGNED"
                              )?.length === 0 && (
                                <h6
                                  style={{
                                    paddingTop: "1rem",
                                    textAlign: "center",
                                  }}
                                >
                                  No Assessments added yet
                                </h6>
                              )}
                          </div>
                          <hr />
                          <h3>Organization Admin Details</h3>
                          <div className={styles.flexEditOrg}>
                            <div className={styles.editOrgInput}>
                              <label>First Name: </label>
                              <p>
                                {values?.admin_first_name
                                  ? values?.admin_first_name
                                  : "N/A"}
                              </p>
                            </div>
                            <div className={styles.editOrgInput}>
                              <label>Last Name: </label>
                              <p>
                                {values?.admin_last_name
                                  ? values?.admin_last_name
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                          <div className={styles.flexEditOrg}>
                            <div className={styles.editOrgInput}>
                              <label>Admin Email: </label>
                              <p>{values?.admin_email}</p>
                            </div>
                            <div className={styles.editOrgInput}>
                              <label>Admin Status: </label>
                              <p>{values?.admin_is_active}</p>
                            </div>
                          </div>

                          <div className={styles.formInnerBtnSection}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <AddAssessmentByAdmin
        heading="Assign Assessment"
        openState={showAdminAssessment}
        onClickCancel={() => {
          setShowAdminAssessment(false);
          // queryClient.resetQueries({ queryKey: RQ_KEYS.TOAST_STATE });
        }}
        data={data}
        user_org_id={data?.org_id}
        assignedAssessmentIds={
          data?.org_assessments.length > 0
            ? data?.org_assessments?.map(
                (assessment: any) => assessment.assessment_id
              )
            : []
        }
      />

      {showModal &&
        showModal.state &&
        // showModal.modalName ===
        ASSESSMENT_MODAL_NAMES.UNASSIGN_USER_ASSESSMENT && (
          <AssignUnassignUserAssessment
            openState={showModal}
            heading={
              showModal?.type === "ASSIGNED"
                ? "ReAssign Assessment"
                : "UnAssign Assessment"
            }
            type={showModal?.type}
            data={showModal?.data}
            // assessment_id={orgAssessmentId}
            onClickCancel={() => {
              setModal((prev: any) => ({ ...prev, state: false }));
            }}
          />
        )}
    </React.Fragment>
  );
};
export default React.memo(ViewOrganizationDetails);
