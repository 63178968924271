import React from "react";
import { format } from 'date-fns';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

const Pdf = ({ organisationName, assessmentData }: any) => {
  // Group the assessment data by chapter_id
  const groupedData = assessmentData.reduce((acc: any, curr: any) => {
    // Use chapter_id as the key
    if (!acc[curr.chapter_id]) {
      acc[curr.chapter_id] = {
        chapter_name: curr.chapter_name,
        questions: [],
      };
    }
    // Push the current question into the relevant chapter group
    acc[curr.chapter_id].questions.push(curr);
    return acc;
  }, {});

  const formatDate = (dateString: any) => {
    if (!dateString || dateString === "0000-00-00") return "N/A";
    const date = new Date(dateString);

    if (isNaN(date.getTime())) return "N/A";

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}-${day}-${year}`; // Format as month-date-year
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.mainContainer}>
          <View style={styles.header}>
            <Image style={styles.logoImg} src="/logo.png" />
            <Text style={styles.headerText}>{organisationName}</Text>
          </View>
          <Text style={styles.mainHeading}>
            {assessmentData[0]?.assessment_name}
          </Text>
          {Object.keys(groupedData).map((chapterId) => {
            const chapter = groupedData[chapterId];

            return (
              <View key={chapterId}>
                {/* Render chapter name only once */}
                <Text style={styles.chapterName}>
                  Chapter Name: {chapter.chapter_name}
                </Text>

                {chapter.questions.map((data: any, index: any) => (
                  <View style={styles.section} key={index}>
                    <View style={styles.detailView}>
                      <View style={styles.innerStyle}>
                        <Text>Assigned To: {data.assigned_to_username}</Text>
                        <Text>
                          Assigned Date: {formatDate(data.assigned_at)}
                        </Text>
                        <Text>Completion Status: {data.assigned_status}</Text>
                      </View>
                      <View style={styles.innerStyle}>
                        <Text>Assigned By: {data.assigned_by_username}</Text>
                        <Text style={styles.margin}>
                          Due Date: {formatDate(data.due_date)}
                        </Text>
                      </View>
                    </View>
                    <Text style={styles.questionData}>
                      Q{index + 1}: {data.question}
                    </Text>

                    {data.question_type === "Subjective" && (
                      <Text style={styles.questionData}>
                        A: {data.answer_subjective || "N/A"}
                      </Text>
                    )}

                    {data.question_type === "Objective" && (
                      <Text style={styles.questionData}>
                        A:{" "}
                        {data.multi_option_answer &&
                        data.multi_option_answer.length > 0
                          ? data.multi_option_answer.map(
                              (answer: any, answerIndex: any) => (
                                <Text key={answerIndex}>{` ${answer}`}</Text>
                              )
                            )
                          : "N/A"}
                      </Text>
                    )}

                      {data.question_type === "List" && (
                      <Text style={styles.questionData}>
                        A:{" "}
                        {data.answer_list &&
                        data.answer_list.length > 0
                          ? data.answer_list.map(
                              (answer: any, answerIndex: any) => (
                                <Text key={answerIndex}>{` ${answer}`}</Text>
                              )
                            )
                          : "N/A"}
                      </Text>
                    )}
                  </View>
                ))}
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#f1f2f4",
    color: "#270089",
    padding: 10,
  },
  mainContainer: {
    padding: 10,
    border: 1,
    borderColor: "#dfe2eb",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerText: {
    fontSize: 14,
  },
  logoImg: {
    width: 160,
    height: 19,
  },
  mainHeading: {
    marginVertical: 10,
    textAlign: "center",
    fontWeight: "bold",
  },
  logo: {
    height: 50,
    width: 100,
  },
  section: {
    margin: 5,
    padding: 5,

    //   flexGrow: 1,
  },
  text: {
    fontSize: 12,
  },
  margin: {
    marginBottom: 5,
  },
  chapterName: {
    fontSize: 14,
  },
  detailView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: "gray",
    fontSize: 12,
    marginBottom: 8,
  },
  innerStyle: {
    display: "flex",
    gap: 3,
  },
  questionData: {
    fontSize: 12,
    color: "black",
  },
});

export default Pdf;
