import styles from "./style.module.scss";
import update_details from "../../../assests/png/forget password.png";
import { Button, InputField } from "../../../common/components";
import { Formik, Form } from "formik";
import { ForgetPasswordValue, ForgetPasswordSchema } from "./validator";
import { _useMutation, _getQueryState } from "../../../services/useAxiosQuery";
import { APP_PATHS, REQUEST_TYPE, RQ_KEYS } from "../../../utils/constants";
import { useQueryClient } from "react-query";
import { API_ROUTE } from "../../../utils/api";
import logoImage from "../../../assests/svg/blueLogo.svg";
import { ReactComponent as UpdateIcon } from "../../../assests/svg/update_icon.svg";
import { ReactComponent as BackIcon } from "../../../assests/svg/back_icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { ITOAST_STATE } from "../../../interfaces/IToastState";
import Toast from "../../../common/components/Toast";
import forgotIcon from "../../../assests/svg/forgot_icon.svg";

const ForgotPassword = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });

  const onSuccess = ({ message, data }: any) => {
    // onSuccess
    console.log("message in forgot password ", message, data.envelope.to[0]);
    queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
      message: message,
      toastType: "SUCCESS",
    });
    navigate(APP_PATHS.FORGET_PASSWORD_STATUS, {
      state: { email: data.envelope.to[0] },
    });
  };
  const { mutate } = _useMutation({
    onSuccess,
  });

  return (
    <div className={styles.resetPasswordContainer}>
      <div className={styles.loginSiteLogo}>
        <img
          style={{
            width: 150,
            alignSelf: "center",
          }}
          src={logoImage}
          alt="logo_image"
        ></img>
      </div>
      <div className={styles.loginFlexMain}>
        <div className={styles.resetPassForm}>
          <Formik
            initialValues={ForgetPasswordValue}
            validationSchema={ForgetPasswordSchema}
            onSubmit={async (values) => {
              queryClient.setQueryData(RQ_KEYS.LOADING, true);
              mutate({
                url: API_ROUTE.FORGET_PASSWORD,
                requestType: REQUEST_TYPE._POST,
                requestData: values,
              });
              queryClient.removeQueries(RQ_KEYS.TOAST_STATE);
            }}
          >
            {({ handleChange, isValid, values }) => {
              return (
                <Form className="signup-form" style={{ height: "100%" }}>
                  <div className={styles.resetPass}>
                    <div className={styles.resetPassSectionOne}>
                      <div className={styles.resetPassForm}>
                        <div className={styles.resetPassFormInner}>
                          <img
                            style={{
                              width: 30,
                              alignSelf: "center",
                            }}
                            src={forgotIcon}
                            alt="forgot_icon"
                          ></img>
                          <h1>Forgot Password?</h1>
                          <p>
                            We'll email you instructions on how to reset it.
                          </p>
                          <Toast toastState={toastState} />
                          <InputField
                            name="email"
                            type="email"
                            label="Email"
                            isRequired={true}
                            display="flex"
                            flexDirection="column"
                            placeholder="Enter your email"
                            alignItems="flex-start"
                            // fontWeight="500"
                            onChange={handleChange}
                            value={values.email}
                            gap="5px"
                          />

                          {/* <hr /> */}
                          <div className={styles.formInnerBtnSection}>
                            <Button
                              id="resetPassBtn"
                              label="Submit"
                              borderRadius="5px"
                              fontWeight="bold"
                              color="var(--whiteColor)"
                              fontSize="15px"
                              border="none"
                              type="submit"
                              width="60%"
                              maxWidth="100%"
                              justifyContent="center"
                              lineHeight="1.4rem"
                              Icon={<UpdateIcon style={{ fill: "#ffffff" }} />}
                              disabled={!isValid || loadingState}
                              filter="invert(100%) sepia(1%) saturate(4057%) hue-rotate(163deg) brightness(110%) contrast(100%)"
                            />
                          </div>
                          <div className={`backToLogin ${styles.backToLogin}`}>
                            <BackIcon
                              className="svg_image_icon"
                              style={{ maxWidth: "14px" }}
                            />
                            <Link to={"/login"}>Back to login</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <div className={styles.imageSection}>
          <img src={update_details} alt="sample" />
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
