import React, { useMemo, useState } from "react";
import styles from "./style.module.scss";
import { Dropdown, InputField, MultiSelect } from "../../../components";
import { IInviteUser } from "../../../../interfaces/IAssessmentModals";
import { Button } from "../../../components";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import { Country, State } from "country-state-city";
import { ReactComponent as SelectIconBtn } from "../../../../assests/svg/selectIcon.svg";
import cancel_icon from "../../../../assests/svg/cancel_icon.svg";
import { AssessmentInitialValue, ImportAssessmentSchema } from "./validator";
import { ReactComponent as CancelBtn } from "../../../../assests/svg/close.svg";
import { ReactComponent as EditIcon } from "../../../../assests/svg/edit_icon.svg";
import { ReactComponent as UpdateIcon } from "../../../../assests/svg/update_user2.svg";
import { ReactComponent as UploadIcon } from "../../../../assests/svg/upload_icons.svg";

import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import {
  ASSESSMENT_CATEGORIES,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../utils/constants";
import assessments_icon from "../../../../assests/svg/assessments.svg";
import Toast from "../../../components/Toast";
import { ITOAST_STATE } from "../../../../interfaces/IToastState";
import { useQueryClient } from "react-query";
// import {
//   getAllCountries,
//   getUSstate,
// } from "../../../../utils/countriesContenent";
import { Modal } from "@mui/material";
import { API_ROUTE } from "../../../../utils/api";

const ImportAssessment: React.FC<any> = ({
  onClickCancel,
  heading,
  type,
  openState,
  data,
  handleImportFile,
}) => {
  //   const [foundAssessment, setFoundAssessment] = useState("");
  const queryClient = useQueryClient();
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  const draftAssessments: any = _getQueryState({
    key: RQ_KEYS.DRAFT_ASSESSMENT,
  });
  const publishedAssessments: any = _getQueryState({
    key: RQ_KEYS.PUBLISHED_ASSESSMENT,
  });
  const archiveAssessments: any = _getQueryState({
    key: RQ_KEYS.ARCHIVE_ASSESSMENT_LIST,
  });

  const allAssessments = useMemo(
    () =>
      draftAssessments &&
      publishedAssessments &&
      archiveAssessments && [
        ...draftAssessments.assessments,
        ...publishedAssessments.assessments,
        ...archiveAssessments.assessments,
      ],
    [draftAssessments, publishedAssessments, archiveAssessments]
  );
  console.log("assessments in modal", allAssessments);

  const { data: assessmentTypes } = useApiQuery({
    queryKey: RQ_KEYS.ASSESSMENT_TYPES,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_ASSESSMENT_TYPES,
  });
  console.log({ assessmentTypes });

  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.invitePopupRow1}>
            <p>Import Assessment</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>
          <Toast toastState={toastState} />
          <Formik
            initialValues={type === "CREATE" ? AssessmentInitialValue : data}
            validationSchema={ImportAssessmentSchema}
            enableReinitialize={true}
            onSubmit={async (values) => {
              console.log("ONSUBMIT VALUES", values);
              handleImportFile && handleImportFile(values);
              onClickCancel && onClickCancel();
            }}
          >
            {({ handleChange, isValid, values, setFieldValue }) => (
              <div className={styles.invitePopupRow2}>
                <Form>
                  <div className={styles.userLogin}>
                    <div className={` ${styles.assessDropdown}`}>
                      <MultiSelect
                        name="assessment_name"
                        label="Assessment"
                        placeHolder="Select"
                        isMulti={false}
                        className={styles.editOrgReactSelect}
                        isRequired={true}
                        options={
                          assessmentTypes && assessmentTypes.length > 0
                            ? assessmentTypes.map(
                                ({ assessment_type }: any) => {
                                  return {
                                    value: assessment_type,
                                    label: assessment_type,
                                  };
                                }
                              )
                            : []
                        }
                        defaultValue={{
                          value: values?.assessment_name,
                          label: values?.assessment_name,
                        }}
                        values={
                          values?.assessment_name
                            ? {
                                value: values?.assessment_name,
                                label: values?.assessment_name,
                              }
                            : null
                        }
                        onChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    </div>

                    <div className={styles.inputContainer}>
                      <InputField
                        name="image"
                        type="file"
                        fileType=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        label="Import File"
                        isRequired={true}
                        onChange={(event: any) => {
                          setFieldValue("image", event.currentTarget.files[0]);
                        }}
                        // onChange={handleChange}
                      />
                    </div>
                    <div className={styles.inviteUserBtn}>
                      <Button
                        label="Cancel"
                        id="cancelbtn"
                        type="button"
                        borderRadius="5px"
                        fontWeight="bold"
                        fontSize="var(--secondaryFont)"
                        background="var(--whiteColor)"
                        border="1px solid var(--secondaryColor)"
                        color="var(--secondaryColor)"
                        disabled={loadingState}
                        Icon={
                          <CancelBtn
                            fill="#0097ce"
                            style={{ maxWidth: "12px" }}
                          />
                        }
                        onClick={onClickCancel}
                      />
                      <Button
                        label="Import Assessment"
                        id="saveAssessmentbtn"
                        borderRadius="5px"
                        fontWeight="bold"
                        color="var(--whiteColor)"
                        maxWidth="190px"
                        fontSize="var(--secondaryFont)"
                        border="none"
                        disabled={loadingState}
                        loading={loadingState}
                        type="submit"
                        Icon={
                          <UploadIcon
                            fill="#ffffff"
                            className="svg_image_icon"
                          />
                        }
                      />
                    </div>
                    {/* <div
                                            style={{
                                                color: "red",
                                                fontSize: 12,
                                                textAlign: "right",
                                                marginTop: "10px",
                                            }}
                                        >
                                            {foundAssessment}
                                        </div> */}
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default ImportAssessment;
