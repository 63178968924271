import { EditableProcessesTable } from "../../../../../common/Parts/EditableProcessesTable";
import styles from './style.module.scss'

const ProcessesTable = ({ questions, setArticleIndex }: any) => {
  console.log("INSIDE PROCESSESS TABLE", questions, setArticleIndex);
  return (
    <div className={styles.questionTableContainer}>
    <EditableProcessesTable
      questions={questions}
      setArticleIndex={setArticleIndex}
    />
    </div>
  );
};
export default ProcessesTable;
