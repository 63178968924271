import React from "react";
import styles from "./style.module.scss";
import { InputField } from "../../../../components";
import { IInviteUser } from "../../../../../interfaces/IAssessmentModals";
import { Button } from "../../../../components";
import { Formik, Form } from "formik";
import cancel_icon from "../../../../../assests/svg/cancel_icon.svg";
import { InitialChapterValue, ChatperSchema } from "./validator";
import { useParams, useSearchParams } from "react-router-dom";
import {
  _getQueryState,
  _useMutation,
} from "../../../../../services/useAxiosQuery";
import { REQUEST_TYPE, RQ_KEYS } from "../../../../../utils/constants";
import { API_ROUTE } from "../../../../../utils/api";
import { useQueryClient } from "react-query";
import Toast from "../../../../components/Toast";
import { ITOAST_STATE } from "../../../../../interfaces/IToastState";
import { ReactComponent as CancelBtn } from "../../../../../assests/svg/close.svg";
import { ReactComponent as UpdateBtn } from "../../../../../assests/svg/update_icon.svg";
import { Modal } from "@mui/material";

const ManageChapters: React.FC<IInviteUser> = ({
  onClickCancel,
  heading,
  data,
  type,
  openState,
}) => {
  console.log(">>> Data in Add Chapter Modal",data);
  const queryClient = useQueryClient();
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  // const { assessment_id } = useParams();
  
  const [searchParams] = useSearchParams();
  const assessment_id = searchParams.get("assessment_id");


  const getChatpers = _useMutation({
    queryKey: RQ_KEYS.ALL_CHAPTERS,
  });
  const onSuccess = () => {
    getChatpers.mutate({
      requestType: REQUEST_TYPE._GET,
      url: `${API_ROUTE.GET_CHAPTERS_LIST_OBOEDIO}/${assessment_id}`,
    });
    onClickCancel();
  };
  const createChatper = _useMutation({
    onSuccess,
    queryKey: RQ_KEYS.PUBLISHED_ASSESSMENT,
  });
  const updateChatper = _useMutation({ onSuccess });
  console.log("assessment_id....", assessment_id, data);
  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.addChapterHeader}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>
          <Toast toastState={toastState} />
          <Formik
            initialValues={type === "CREATE" ? InitialChapterValue : data}
            validationSchema={ChatperSchema}
            onSubmit={async (values) => {
              console.log("VALUES", values);
              queryClient.setQueryData(RQ_KEYS.LOADING, true);
              if (type === "CREATE") {
                createChatper.mutate({
                  url: API_ROUTE.CREATE_CHAPTERS,
                  requestType: REQUEST_TYPE._POST,
                  requestData: { ...values, assessment_id },
                });
              } else {
                updateChatper.mutate({
                  url: API_ROUTE.UPDATE_CHAPTERS,
                  requestType: REQUEST_TYPE._PATCH,
                  requestData: { ...values, assessment_id },
                });
              }
            }}
          >
            {({ handleChange, isValid, values, setFieldValue, handleBlur }) => (
              <div className={styles.addChapterRow}>
                <Form>
                  <div className={styles.userLogin}>
                    <div className={styles.userLoginInner}>
                      <InputField
                        name="chapter_name"
                        alignItems="left"
                        type="text"
                        label="Name"
                        placeholder="Chapter Name"
                        isRequired={true}
                        display="flex"
                        fontWeight="600"
                        fontSize="12px"
                        gap="5px"
                        onChange={handleChange}
                        value={values.chapter_name}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className={styles.userLoginInner}>
                    <InputField
                      name="chapter_number"
                      type="text"
                      alignItems="left"
                      label="Number"
                      placeholder="Chapter Number"
                      isRequired={true}
                      display="flex"
                      fontWeight="600"
                      fontSize="12px"
                      gap="5px"
                      onChange={handleChange}
                      value={values.chapter_number}
                      onBlur={handleBlur}
                    />
                  </div>
                  </div>
                  <div className={styles.inviteUserBtn}>
                    <Button
                      label="Cancel"
                      id="cancelbtn"
                      borderRadius="5px"
                      fontWeight="bold"
                      // maxWidth="100px"
                      fontSize="var(--secondaryFont)"
                      background="var(--whiteColor)"
                      border="1px solid var(--secondaryColor)"
                      color="var(--secondaryColor)"
                      Icon={
                        <CancelBtn
                          fill="#0097ce"
                          style={{ maxWidth: "12px" }}
                        />
                      }
                      onClick={onClickCancel}
                    />
                    {type === "CREATE" ? (
                      <Button
                        label="+ Add Chapter"
                        id="addChapterbtn"
                        borderRadius="5px"
                        fontWeight="bold"
                        color="var(--whiteColor)"
                        maxWidth="130px"
                        fontSize="var(--secondaryFont)"
                        border="none"
                        type="submit"
                        disabled={!isValid || loadingState}
                        loading={loadingState}
                      />
                    ) : (
                      <Button
                        label="Update Chapter"
                        id="addChapterbtn"
                        borderRadius="5px"
                        fontWeight="bold"
                        color="var(--whiteColor)"
                        // maxWidth="150px"
                        fontSize="var(--secondaryFont)"
                        border="none"
                        type="submit"
                        Icon={<UpdateBtn fill="#ffffff" />}
                        disabled={!isValid || loadingState}
                      />
                    )}
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default ManageChapters;
