import React, { useEffect } from "react";
import { Button, SearchBar, Table } from "../../../../../common/components";
import { Breadcrumb } from "../../../../../common/Parts";
import styles from "./style.module.scss";
import { ReactComponent as ViewIcon } from "../../../../../assests/svg/view_icon.svg";
import { ReactComponent as EditIcon } from "../../../../../assests/svg/edit_icon.svg";
import { ReactComponent as PlusIcon } from "../../../../../assests/svg/plus.icon.svg";
import AddAssessmentByAdmin from "../../../../../common/Parts/ModalPopups/AddAssessmentByAdmin";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../../services/useAxiosQuery";
import {
  APP_PATHS,
  LIMIT,
  QUESTION_SCREEN_MODAL_NAMES,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../../utils/constants";
import { API_ROUTE } from "../../../../../utils/api";
import { useNavigate } from "react-router-dom";
import useDebounce from "../../../../../hooks/useDebounce";
import { Pagination } from "../../../../../common/components/Pagination";
import { IAssignModal } from "../../../../../interfaces/IModal";
import AssignAssessmentAndOptions from "../../../../../common/Parts/ModalPopups/QuestionScreenModals/AssignAssessmentAndOptions";
import Toast from "../../../../../common/components/Toast";
import { ITOAST_STATE } from "../../../../../interfaces/IToastState";
import LoadingHoc from "../../../../../common/components/LoadingHoc";
const AssessmentList = () => {
  const navigate = useNavigate();

  const [showModal, setModal] = React.useState<IAssignModal>({
    state: false,
    modalName: "",
    data: null,
    type: "Task",
  });
  const [dataLimit, setDataLimit] = React.useState<any>({
    limit: LIMIT,
    offset: 0,
  });
  const [addAssessmentPopup, setAddAssessmentPopup] =
    React.useState<boolean>(false);
  const organizationData: any = _getQueryState({
    key: RQ_KEYS.USER_ORGANIZATION,
  });
  const { mutate: getOrgAssessments } = _useMutation({
    queryKey: RQ_KEYS.ASSESSMENTS_BY_ORG_ID,
  });

  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });
  console.log({ userAuthData });

  let { data } = useApiQuery({
    queryKey: RQ_KEYS.USER_ORGANIZATION,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_USER_ORGANIZATION}?user_org_id=${
      userAuthData && userAuthData.user_org_id > 0
        ? userAuthData.user_org_id
        : null
    }`,
  });
  data = data && { ...data, location: data.branch_locations };
  console.log({ data });

  useApiQuery({
    queryKey: RQ_KEYS.ASSESSMENTS_BY_ORG_ID,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_ASSESSMENT_BY_ORG_ID}?limit=${LIMIT}&offset=0`,
  });

  // const isLoading: any = _getQueryState({ key: RQ_KEYS.LOADING });
  const assessments: any = _getQueryState({
    key: RQ_KEYS.ASSESSMENTS_BY_ORG_ID,
  });

  const assessmentLoadingState: any = _getQueryState({
    key: `${RQ_KEYS.ASSESSMENTS_BY_ORG_ID}_loading`,
  });
  const [searchKey, setSearchKey] = React.useState<string>("null");
  const searchQuery = useDebounce(searchKey, 2000);
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });

  React.useMemo(() => {
    if (searchQuery || searchKey.length < 0) {
      getOrgAssessments({
        requestType: REQUEST_TYPE._GET,
        url: `${API_ROUTE.GET_ASSESSMENT_BY_ORG_ID}?limit=${LIMIT}&offset=0&search=${searchQuery}`,
      });
    } else if (searchKey.length === 0) {
      setSearchKey("null");
      getOrgAssessments({
        requestType: REQUEST_TYPE._GET,
        url: `${API_ROUTE.GET_ASSESSMENT_BY_ORG_ID}?limit=${LIMIT}&offset=0`,
      });
    }
  }, [searchQuery]);
  const onPaginzationChange = ({ offset }: any) => {
    setDataLimit((prev: any) => ({
      ...prev,
      offset,
    }));
    getOrgAssessments({
      requestType: REQUEST_TYPE._GET,
      url: `${API_ROUTE.GET_ASSESSMENT_BY_ORG_ID}?limit=${LIMIT}&offset=${offset}&search=${searchQuery}`,
    });
    // getOrgUsersLists.mutate({
    //   url: API_ROUTE.GET_ORG_USERS_LIST + `/${LIMIT}/${offset}/null`,
    //   requestType: REQUEST_TYPE._GET,
    // });
  };

  const renderTable = React.useCallback(() => {
    return (
      <Table
        tableHeaders={[
          "Assessment Name",
          "Region",
          "Jurisdiction",
          "Assessment Category",
          "Last Modified",
        ]}
        tableData={assessments?.data}
        hideColumns={["assessment_id"]}
        action={{
          name: "Action",
          buttonOne: (data: any) => {
            return (
              <Button
                label="View"
                id="assignToBtn"
                Icon={<ViewIcon fill="#ffffff" />}
                onClick={() => {
                  console.log("data?.assessment_id",data?.assessment_id)
                  navigate(
                    `${APP_PATHS.ASSESSMENT_CHAPTER_LIST}?assessment_id=${btoa(
                      data?.assessment_id
                    )}`
                  );
                }}
              />
            );
          },
          buttonTwo: (data: any) => {
            return (
              <Button
                label="Assign Assessment"
                id="assignToBtn"
                onClick={() => {
                  setModal({
                    state: true,
                    modalName: QUESTION_SCREEN_MODAL_NAMES.ASSIGN,
                    type: "Assign_Assessment",
                    data: {
                      assessment_name: data && data.assessment_name,
                      assessment_id: data && data?.assessment_id,
                    },
                  });
                }}
                Icon={<PlusIcon fill="#ffffff" />}
              />
            
            );
          },
        }}
      />
    );
  }, [assessments, dataLimit.offset]);

  return (
    <React.Fragment>
      <div
        className={`manageAssessmentContainer ${styles.manageAssessmentAdminContainer}`}
      >
        <Breadcrumb
          pageName="Assessments"
          // path={["Assessments"]}
          // org_name={organizationData?.org_name}
          action={{ buttonOne: <Toast toastState={toastState} /> }}
        />
        <div className={styles.manageAssessmentBody}>
          <div className={styles.assessmentheader}>
            <Button
              id="add-assessment"
              label="Add Assessment"
              padding="8px 10px"
              onClick={() => {
                setAddAssessmentPopup(true);
              }}
              Icon={<EditIcon fill="#ffffff" className="svg_image_icon" />}
            />
            <SearchBar
              type="text"
              placeholder="Search by Assessment Name"
              isRequired={false}
              name="searchBar"
              display="flex"
              onChange={(event: any) => setSearchKey(event.target.value)}
            />
          </div>
          <LoadingHoc isLoading={assessmentLoadingState}>
            {renderTable()}
          </LoadingHoc>
          <div className={styles.manageAssessmentBody}>
            {assessments?.data && assessments?.data?.length === 0 && (
              <h6
                style={{
                  textAlign: "center",
                }}
              >
                No Assessment found
              </h6>
            )}
            <Pagination
              data={assessments && assessments.data}
              totalCount={assessments && assessments.totalCount}
              onPaginzationChange={onPaginzationChange}
              setDataLimit={setDataLimit}
              dataLimit={dataLimit}
            />
          </div>
        </div>
      </div>
      <AddAssessmentByAdmin
        heading="Add Assessment"
        openState={addAssessmentPopup}
        onClickCancel={() => {
          setAddAssessmentPopup(false);
        }}
        data={data}
        assignedAssessmentIds={assessments?.data?.map(
          (assessment: any) => assessment.assessment_id
        )}
      />
      {showModal &&
        showModal.state &&
        showModal.modalName === QUESTION_SCREEN_MODAL_NAMES.ASSIGN && (
          <AssignAssessmentAndOptions
            openState={showModal}
            type={showModal.type}
            heading="Assign Assessment"
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
            data={showModal?.data}
            modalName={showModal.modalName}
          />
        )}
    </React.Fragment>
  );
};

export default React.memo(AssessmentList);
