import React, { useCallback, useMemo, useState } from "react";
import styles from "./style.module.scss";
import { InputField, MultiSelect, Textarea } from "../../../../components";
import { Button } from "../../../../components";
// import { Formik, Form } from "formik";
import { ReactComponent as AssignTask } from "../../../../../assests/svg/assign.svg";

import { ReactComponent as CancelBtn } from "../../../../../assests/svg/close.svg";
import cancel_icon from "../../../../../assests/svg/cancel_icon.svg";
import {
  IAssignQuestionModal,
  IQuestion,
} from "../../../../../interfaces/IQuestion";
import {
  APP_PATHS,
  APP_ROLES,
  ASSIGNED_STATUS,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../../utils/constants";
import { Form, Formik } from "formik";
import {
  AssignAssessmentInitialValue,
  AssignAssessmentSchema,
} from "./validator";
import {
  _getQueryState,
  _useMutation,
} from "../../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../../utils/api";
import { useQueryClient } from "react-query";
import { Modal } from "@mui/material";
const AssignAssessmentAndOptions: React.FC<IAssignQuestionModal> = ({
  onClickCancel,
  heading,
  type,
  data,
  question_oaa_id,
  assigned_to_org_assign_assessment_id,
  onSuccess,
  openState,
}) => {
  console.log("data in assign assessment modal", data);
  console.log("question_oaa_id in assign assessment modal", question_oaa_id);
  console.log("assigned_to_org_assign_assessment_id",assigned_to_org_assign_assessment_id)

  const queryClient = useQueryClient();
  const userList: any = _getQueryState({ key: RQ_KEYS.USER_LIST });

  const { mutate: getUserList } = _useMutation({ queryKey: RQ_KEYS.USER_LIST });
  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });
  const { mutate: assignAssessmentToUser , isLoading:assignedAssessmentLoading} = _useMutation({
    onSuccess: ({ message }: any) => {
      queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
        message: message,
        toastType: "SUCCESS",
      });

      queryClient.refetchQueries({ queryKey: RQ_KEYS.REMEDIATION_TASKS });
      queryClient.refetchQueries({ queryKey: RQ_KEYS.QUESTIONS });
      queryClient.refetchQueries({ queryKey: RQ_KEYS.ASSESSMENT_BY_IDS });
      queryClient.refetchQueries({ queryKey: RQ_KEYS.ALL_CHAPTERS });
      onClickCancel();
    },
  });

  const { mutate: assignQuestionTasksToUser , isLoading:assignedQuestionLoading } = _useMutation({
    queryKey: RQ_KEYS.REMEDIATION_TASKS,
    onSuccess: () => {
      onSuccess && onSuccess();
      onClickCancel();
      
    },
  
  });
  const { mutate: assignTaskToUser , isLoading :assignTaskToUserLoading } = _useMutation({
    queryKey: RQ_KEYS.REMEDIATION_TASKS,
    onSuccess: () => {
      onSuccess && onSuccess();
      onClickCancel();
    },
  });
  const { mutate: updateAssignedStatus } = _useMutation({
    onSuccess: () => {
      console.log(">>>>>>>>>> UPDATE CALL");
      queryClient
        .refetchQueries({
          queryKey: RQ_KEYS.UPDATE_TASK_ASSIGNED_STATUS,
        })
        .then();
    },
    // queryKey: RQ_KEYS.UPDATE_TASK_ASSIGNED_STATUS,
  });

  const { mutate: assignChapterToUser , isLoading:assignChapterToUserLoading } = _useMutation({
    onSuccess: ({ message }: any) => {
      queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
        message: message,
        toastType: "SUCCESS",
      });
      queryClient.refetchQueries({ queryKey: RQ_KEYS.ALL_CHAPTERS });
      queryClient.refetchQueries({ queryKey: RQ_KEYS.REMEDIATION_TASKS });
      queryClient.refetchQueries({ queryKey: RQ_KEYS.QUESTIONS });
      onClickCancel();
    },
  });

  const { mutate: reAssignChapterToUser , isLoading:reAssignChapterToUserLoading } = _useMutation({
    onSuccess: ({ message }: any) => {
      queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
        message: message,
        toastType: "SUCCESS",
      });

      queryClient.refetchQueries({ queryKey: RQ_KEYS.ALL_CHAPTERS });
      queryClient.refetchQueries({ queryKey: RQ_KEYS.REMEDIATION_TASKS });
      queryClient.refetchQueries({ queryKey: RQ_KEYS.QUESTIONS });
      onClickCancel();
    },
  });
  const renderRoles = useMemo(() => {
    if (
      userAuthData &&
      userAuthData.role &&
      (userAuthData.role === APP_ROLES.ORG_ADMIN ||
        userAuthData.role === APP_ROLES.BRANCH_ADMIN)
    ) {
      // if (data?.assessmentType?.assigned_to_role === APP_ROLES.VENDOR_USER) {
      //   return [{ label: "Vendor User", value: APP_ROLES.VENDOR_USER }];
      // } else

      if (type === "Assign_Assessment") {
        if(userAuthData.role === APP_ROLES.BRANCH_ADMIN){
            return [
              { label: "Org User", value: APP_ROLES.ORG_USER },
            ];
        }else{
          return [
            { label: "Org User", value: APP_ROLES.ORG_USER },
            
            { label: "Branch Admin", value: APP_ROLES.BRANCH_ADMIN },
            { label: "Vendor User", value: APP_ROLES.VENDOR_USER },
          ];
        }
        
      } else {
        if (userAuthData.role === APP_ROLES.BRANCH_ADMIN) {
          return [{ label: "Org User", value: APP_ROLES.ORG_USER }];
        } else {
          return [
            { label: "Org User", value: APP_ROLES.ORG_USER },
            { label: "Branch Admin", value: APP_ROLES.BRANCH_ADMIN },
            // { label: "Vendor User", value: APP_ROLES.VENDOR_USER },
            // { label: "Auditor", value: APP_ROLES.AUDITOR },
          ];
        }
      }
    } else if (
      userAuthData &&
      userAuthData.role &&
      userAuthData.role === APP_ROLES.ORG_USER
    ) {
      return [
        { label: "Org User", value: APP_ROLES.ORG_USER },
        // { label: "Vendor User", value: APP_ROLES.VENDOR_USER },
      ];
    }
     else if (
      userAuthData &&
      userAuthData.role &&
      userAuthData.role === APP_ROLES.VENDOR_USER
    ) {
      return [
        // { label: "Org User", value: APP_ROLES.ORG_USER },
        { label: "Vendor User", value: APP_ROLES.VENDOR_USER },
      ];
    }
    else {
      return [];
    }
  }, [userAuthData]);
  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={`invitePopupContainer ${styles.invitePopupContainer}`}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.assignQuestionHeader}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>
          <Formik
            initialValues={AssignAssessmentInitialValue}
            validationSchema={AssignAssessmentSchema}
            onSubmit={(values) => {
              console.log("values++", values);
              
              if (type === "Question") {
                assignQuestionTasksToUser({
                  url: API_ROUTE.ASSSIGN_QUESTION_TASK_TO_USER,
                  requestType: REQUEST_TYPE._POST,
                  requestData: {
                    ...values,
                    task_id: data?.task_id,
                    article_id: data?.article_id,
                    assessment_id: data?.assessment_id,
                    assessment_type: type,
                    chapter_id: data?.chapter_id,
                    question_id: data?.question_id,
                    question_oaa_id: question_oaa_id,
                    document_ids: data?.document_ids ? `[${data?.document_ids}]` : null,
                    // follw_up_questions: data?.followUp
                    follow_up_questions: data?.followUp
                      ? data.followUp.map(({ question_id, chapter_id, assessment_type, assessment_id, article_id, task_id, parent_assign_question_id, org_assign_assessment_id, comment_thread_id }: any) => ({
                        question_id,
                        chapter_id,
                        assessment_type,
                        assessment_id,
                        article_id,
                        task_id,
                        parent_assign_question_id,
                        org_assign_assessment_id,
                        comment_thread_id
                      }))
                      : null,
                  },
                });

                updateAssignedStatus({
                  url: API_ROUTE.UPDATE_TASK_ASSIGNED_STATUS,
                  requestType: REQUEST_TYPE._POST,
                  requestData: {
                    org_assign_assessment_id: data?.org_assign_assessment_id ? data?.org_assign_assessment_id : question_oaa_id,
                    assigned_status: ASSIGNED_STATUS.ASSIGNED,
                  },
                });
              } else if (type === "Task") {
                assignTaskToUser({
                  url: API_ROUTE.ASSIGN_TASK_TO_USER,
                  requestType: REQUEST_TYPE._POST,
                  requestData: {
                    ...values,
                    task_id: data?.task_id,
                    article_id: data?.article_id,
                    assessment_id: data?.assessment_id,
                    assessment_type: type,
                    chapter_id: data?.chapter_id,
                    question_id: data?.question_id,
                    question_oaa_id: data?.org_assign_assessment_id,
                    document_ids: data?.document_ids ? `[${data?.document_ids}]` : null,
                  },
                });

                updateAssignedStatus({
                  url: API_ROUTE.UPDATE_TASK_ASSIGNED_STATUS,
                  requestType: REQUEST_TYPE._POST,
                  requestData: {
                    org_assign_assessment_id: data?.org_assign_assessment_id ? data?.org_assign_assessment_id : question_oaa_id,
                    assigned_status: ASSIGNED_STATUS.ASSIGNED,
                  },
                });
              }
              else if (type === "Chapter") {
                assignChapterToUser({
                  url: API_ROUTE.ASSSIGN_CHAPTER_TO_USER,
                  requestType: REQUEST_TYPE._POST,
                  requestData: {
                    ...values,
                    assessment_id: data?.assessment_id,
                    chapter_id: data?.chapter_id,
                  },
                });
              } else if (type === "Chapter_Reassign") {
                reAssignChapterToUser({
                  url: API_ROUTE.RE_ASSSIGN_CHAPTER_TO_USER,
                  requestType: REQUEST_TYPE._PATCH,
                  requestData: {
                    ...values,
                    assessment_id: data?.assessment_id,
                    chapter_id: data?.chapter_id,
                  },
                });
              } else if (
                type === "Question_Reassign"
              ) {
                assignQuestionTasksToUser({
                  url: API_ROUTE.RE_ASSSIGN_QUESTION_TASK_TO_USER,
                  requestType: REQUEST_TYPE._PATCH,
                  requestData: {
                    ...values,
                    // org_assign_assessment_id: data?.org_assign_assessment_id,
                    org_assign_assessment_id: assigned_to_org_assign_assessment_id,
                    follow_up_questions: data?.followUp
                      ? data.followUp.map(({ question_id, chapter_id, assessment_type, assessment_id, parent_assign_question_id, assignee_org_assign_assessment_id, comment_thread_id }: any) => ({
                        question_id,
                        chapter_id,
                        assessment_type,
                        assessment_id,
                        parent_assign_question_id,
                        assignee_org_assign_assessment_id,
                        comment_thread_id
                      }))
                      : null,
                  },
                });
              } else if (type === "Task_Reassign") {
                assignTaskToUser({
                  url: API_ROUTE.RE_ASSSIGN_QUESTION_TASK_TO_USER,
                  requestType: REQUEST_TYPE._PATCH,
                  requestData: {
                    ...values,
                    org_assign_assessment_id: data?.org_assign_assessment_id,
                  },
                });
              }
              else if (type === "Assign_Assessment") {
                assignAssessmentToUser({
                  url: API_ROUTE.ASSSIGN_ASSESSMENT_TO_USER,
                  requestType: REQUEST_TYPE._POST,
                  requestData: {
                    ...values,
                    ...data,
                  },
                });
              }
            }}
          >
            {({ setFieldValue, handleChange, handleBlur }) => (
              <Form>
                <div className={styles.assignQuestionBody}>
                  <div className={styles.assignQuestionContent}>
                    <p>
                      <label>Assessment Name:</label> {data?.assessment_name}
                    </p>
                    {data?.chapter_name && (
                      <p>
                        <label>Chapter:</label> {data?.chapter_name}
                      </p>
                    )}
                    {data?.article_name && (
                      <p>
                        <label>Article: </label> {data?.article_name}
                      </p>
                    )}
                    {type === "Task" ? (
                      <p>
                        <label>Task:</label> {data?.task}
                      </p>
                    ) : (
                      type === "Question" && (
                        <p>
                          {" "}
                          <label>Question:</label> {data?.question}
                        </p>
                      )
                    )}
                  </div>
                  <div className={styles.assigneeBlock}>
                    <MultiSelect
                      label="Select Role"
                      name="role"
                      placeholder="Select"
                      isRequired={true}
                      options={renderRoles}
                      onChange={(selectedRole: any) => {
                        getUserList({
                          url: `${API_ROUTE.GET_ORG_USERS_BY_ROLE}?role=${selectedRole}`,
                          requestType: REQUEST_TYPE._GET,
                        });
                      }}
                      //   isDisabled={userList && userList.length > 0 ? false : true}
                      isMulti={false}
                      setFieldValue={setFieldValue}
                    ></MultiSelect>
                    <MultiSelect
                      label="Select Assignee"
                      name="assigned_to_user_id"
                      placeholder="Select"
                      isRequired={true}
                      options={
                        userList &&
                        userList.length > 0 &&
                        userList.map(({ user_name, user_id }: any) => {
                          return { label: user_name, value: user_id };
                        })
                      }
                      isDisabled={
                        userList && userList.length > 0 ? false : true
                      }
                      setFieldValue={setFieldValue}
                      isMulti={false}
                    ></MultiSelect>
                  </div>
                  <div>
                    <InputField
                      type="Date"
                      placeholder={"placeholder"}
                      isRequired={true}
                      name="due_date"
                      label="Due Date"
                      display="flex"
                      onChange={handleChange}
                      alignItems="flex-start"
                      gap="5px"
                      fontWeight="400"
                      onBlur={handleBlur}
                      min={new Date().toISOString().split("T")[0]}
                    />
                  </div>
                  {type === "Question" ||
                    type === "Question_Reassign" ||
                    type === "Task_Reassign" ||
                    (type === "Task" && (
                      <div className={styles.assigneeBlock}>
                        <Textarea
                          cols={30}
                          rows={3}
                          name="description"
                          placeholder="Enter description"
                          label="Description"
                          isRequired={false}
                          width="100%"
                          padding="5px 10px"
                          maxHeight="250px"
                          onChange={handleChange}
                        ></Textarea>
                      </div>
                    ))}
                  <div className={styles.buttonPanel}>
                    <Button
                      label="Cancel"
                      id="cancelbtn"
                      borderRadius="5px"
                      fontWeight="bold"
                      // maxWidth="100px"
                      fontSize="var(--secondaryFont)"
                      background="var(--whiteColor)"
                      border="1px solid var(--secondaryColor)"
                      color="var(--secondaryColor)"
                      Icon={
                        <CancelBtn
                          fill="#0097ce"
                          style={{ maxWidth: "12px" }}
                        />
                      }
                      onClick={onClickCancel}
                    />
                    <Button
                      type="submit"
                      label={
                        type === "Question_Reassign" ? "Re-assign" : "Assign"
                      }
                      id="Assignbtn"
                      fontWeight="bold"
                      color="var(--whiteColor)"
                      fontSize="var(--secondaryFont)"
                      Icon={<AssignTask fill="#fff" />}
                   disabled={(assignedAssessmentLoading || 
                    reAssignChapterToUserLoading || 
                    assignChapterToUserLoading || assignTaskToUserLoading||assignedQuestionLoading) 
                     ? true : false}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default AssignAssessmentAndOptions;
