import React from "react";
import styles from "./style.module.scss";
import { InputField } from "../../../components";
import { Button } from "../../../components";
import { Formik, Form, ErrorMessage, Field, FieldArray } from "formik";
import cancel_icon from "../../../../assests/svg/cancel_icon.svg";
import { ReactComponent as SaveIcon } from "../../../../assests/svg/save_close.svg";
import { ReactComponent as CancelBtn } from "../../../../assests/svg/close.svg";
import { ILocationModal } from "../../../../interfaces/ILocation";
import { UserDetailsInitialValue, UserDetailsSchema } from "./validator";
import { REQUEST_TYPE, RQ_KEYS } from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import FormikErrorMessage from "../../../components/FormikErrorMessage";

import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";

import { useQueryClient } from "react-query";
import { Modal } from "@mui/material";

const AddEditUsetDetailsModal: React.FC<ILocationModal> = ({
  onClickCancel,
  heading,
  type,
  data,
  onSubmit, openState,
}) => {
  console.log("data in add edit location", data);



  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.addChapterHeader}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>
          <Formik
            initialValues={type === "UPDATE" ? data : UserDetailsInitialValue}
            validationSchema={UserDetailsSchema}
            isInitialValid={false}
            enableReinitialize={true}
            onSubmit={async (values) => {
              console.log("FORMIK ON BUBMIT CALLED");
            }}
          >
            {({ handleChange, isValid, values, setFieldValue, handleBlur }) => (
              <Form>
                <div className={styles.userLogin}>
                  <div className={`${styles.userLoginInner} ${styles.full}`}>
                    <InputField
                      name="first_name"
                      alignItems="left"
                      type="text"
                      label="First Name"
                      placeholder="Enter First Name"
                      isRequired={true}
                      display="flex"
                      fontWeight="600"
                      fontSize="12px"
                      gap="5px"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.first_name}
                    />
                  </div>

                  <div className={`${styles.userLoginInner} ${styles.full}`}>
                    <InputField
                      name="last_name"
                      alignItems="left"
                      type="text"
                      label="Last Nmae"
                      placeholder="Enter Last Name"
                      isRequired={true}
                      display="flex"
                      fontWeight="600"
                      fontSize="12px"
                      gap="5px"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.last_name}
                    />
                  </div>







                </div>
                <div className={styles.inviteUserBtn}>
                  <Button
                    label="Cancel"
                    id="cancelbtn"
                    type="button"
                    borderRadius="5px"
                    fontWeight="bold"
                    fontSize="var(--secondaryFont)"
                    background="var(--whiteColor)"
                    border="1px solid var(--secondaryColor)"
                    color="var(--secondaryColor)"
                    Icon={
                      <CancelBtn fill="#0097ce" style={{ maxWidth: "12px" }} />
                    }
                    onClick={onClickCancel}
                  />
                  <Button
                    label="Update User Details"
                    id="saveAssessmentbtn"
                    borderRadius="5px"
                    fontWeight="bold"
                    color="var(--whiteColor)"
                    fontSize="var(--secondaryFont)"
                    border="none"
                    onClick={() => {
                      onSubmit && onSubmit(values);

                      onClickCancel();
                    }}
                    type={"button"}
                    Icon={<SaveIcon fill="#ffffff" className="svg_image_icon" />}

                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default AddEditUsetDetailsModal;