import * as Yup from "yup";
const ForgetPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required("This field is required"),
});

const ForgetPasswordValue = {
  email: "",
};

export { ForgetPasswordSchema, ForgetPasswordValue };
