import queryString from "query-string";
import {
  _useMutation,
  useApiQuery,
} from "../../../../../services/useAxiosQuery";
import {
  APP_PATHS,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../../utils/constants";
import { API_ROUTE } from "../../../../../utils/api";
import { Button } from "../../../../../common/components";
import { ReactComponent as PlusIcon } from "../../../../../assests/svg/plus.icon.svg";
import { ReactComponent as ViewIcon } from "../../../../../assests/svg/view_icon.svg";
import { ReactComponent as BackArrow } from "../../../../../assests/svg/back_btn.svg";
import { ReactComponent as SucessImage } from "../../../../../assests/svg/success_check.svg";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import LoadingHoc from "../../../../../common/components/LoadingHoc";

const QuestionSuccessScreen = ({
  chapter_name,
  assessment_id,
  setEntryComplete,
  assessment,
}: any) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // const parsedQuery = queryString.parse(window.location.search);

  // const assessment_id =
  //   parsedQuery?.assessment_id && atob(parsedQuery?.assessment_id as string);

  // const entry_id =
  //   parsedQuery?.entry_id && atob(parsedQuery?.entry_id as string);

  let { data: chapterData, isLoading }: any = useApiQuery({
    queryKey: RQ_KEYS.ALL_CHAPTERS,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_ASSESSMENT_CHAPTERS}/${assessment_id}`,
  });
  console.log("chapters in questionaire success", chapterData);

  const { mutate: cloneArticle, isLoading: cloneArticleIsLoading } =
    _useMutation({
      queryKey: RQ_KEYS.CLONE_CHAPTER,
      onSuccess: (data: any) => {
        console.log("data after cloning assessment", data);
        queryClient
          .refetchQueries({
            queryKey: RQ_KEYS.ALL_CHAPTERS,
          })
          .then();
        queryClient
          .refetchQueries({
            queryKey: RQ_KEYS.QUESTIONS,
          })
          .then();
        queryClient
          .refetchQueries({
            queryKey: RQ_KEYS.REMEDIATION_TASKS,
          })
          .then();
        queryClient
          .refetchQueries({
            queryKey: RQ_KEYS.DOCUMENTS,
          })
          .then();
        // setEntryComplete(false);

        localStorage.removeItem("questionIndex");

        navigate(
          `${APP_PATHS.QUESTION_SCREEN}?assessment_id=${
            assessment_id && btoa(assessment_id)
          }&chapter_id=${
            data?.data?.assign_chapter_id && btoa(data?.data?.assign_chapter_id)
          }`,
          { replace: true }
        );

        navigate(0);
      },
    });

  const handleViewRopa = () => {
    queryClient.refetchQueries({ queryKey: RQ_KEYS.ALL_CHAPTERS }).then();
    navigate(
      `${APP_PATHS.ASSESSMENT_CHAPTER_LIST}?assessment_id=${btoa(
        assessment_id!.toString()
      )}`
    );

    // navigate(0);
  };

  const handleCloneArticle = () => {
    cloneArticle({
      url: API_ROUTE.CLONE_CHAPTER,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        chapter: chapterData[0],
        chapters_length: chapterData.length,
      },
    });
  };
  const handleGoBack = () => {
    navigate(
      `${APP_PATHS.ASSESSMENT_CHAPTER_LIST}?assessment_id=${btoa(
        assessment_id!.toString()
      )}`
    );
  };

  return (
    <LoadingHoc isLoading={isLoading}>
      {assessment?.assessment_name === "Record of Processing Activities" ? (
        <div className={styles.successQuestionaireContainer}>
          {/* <div className={styles.sucessContainer}>
        <SucessImage width={100} height={100} className={styles.successCheck} />
        <h3>Success!</h3>
      </div> */}

          <div className={styles.headingBlock}>
            <h3>You have successfully submitted {chapter_name}</h3>
            <div className={styles.topBtnContainer}>
              {/* <div className={styles.ropaButtonContainer}>
          <Button
            label="Go Back"
            id="go_back"
            type="button"
            Icon={
              <BackArrow
                fill="#ffffff"
                width={50}
                height={50}
                className="svg_image_icon"
              />
            }
            padding="6px 20px"
            onClick={handleGoBack}
          />
        </div> */}

              <div className={styles.ropaButtonContainer}>
                <Button
                  label="Add Another ROPA Entry"
                  id="add_ropa"
                  type="button"
                  Icon={
                    <PlusIcon
                      fill="#ffffff"
                      width={50}
                      height={50}
                      className="svg_image_icon"
                    />
                  }
                  padding="6px 20px"
                  loading={cloneArticleIsLoading}
                  disabled={cloneArticleIsLoading}
                  onClick={handleCloneArticle}
                />
                <Button
                  label="View ROPA Entries"
                  id="view_ropa"
                  type="button"
                  Icon={
                    <ViewIcon
                      fill="#ffffff"
                      width={50}
                      height={50}
                      className="svg_image_icon"
                    />
                  }
                  padding="6px 20px"
                  onClick={handleViewRopa}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.successQuestionaireContainer}>
          <div className={styles.headingBlock}>
            <h3>You have successfully submitted</h3>
            <div className={styles.topBtnContainer}>
              <div className={styles.ropaButtonContainer}>
                <Button
                  label="Go Back"
                  id="go_back"
                  type="button"
                  Icon={
                    <BackArrow
                      fill="#ffffff"
                      width={50}
                      height={50}
                      className="svg_image_icon"
                    />
                  }
                  padding="6px 20px"
                  onClick={handleGoBack}
                />
              </div>

              {/* <div className={styles.ropaButtonContainer}>
                <Button
                  label="Add Another ROPA Entry"
                  id="add_ropa"
                  type="button"
                  Icon={
                    <PlusIcon
                      fill="#ffffff"
                      width={50}
                      height={50}
                      className="svg_image_icon"
                    />
                  }
                  padding="6px 20px"
                  loading={cloneArticleIsLoading}
                  disabled={cloneArticleIsLoading}
                  onClick={handleCloneArticle}
                />
                <Button
                  label="View ROPA Entries"
                  id="view_ropa"
                  type="button"
                  Icon={
                    <ViewIcon
                      fill="#ffffff"
                      width={50}
                      height={50}
                      className="svg_image_icon"
                    />
                  }
                  padding="6px 20px"
                  onClick={handleViewRopa}
                /> 
              </div>*/}
            </div>
          </div>
        </div>
      )}
    </LoadingHoc>
  );
};

export default QuestionSuccessScreen;
