import CryptoJS from "crypto-js";

interface IObj {
  [key: string]: string | boolean;
}

// Encrypt
export const setEncryptedObj = (key: string, obj: IObj): void => {
  console.log("set Encrypt", key, obj, process.env.REACT_APP_CRYPTOJS_KEY);
  const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(obj),
    process.env.REACT_APP_CRYPTOJS_KEY as string
  ).toString();
  console.log("setEncryptedObj", { ciphertext });
  localStorage.setItem(key, ciphertext);
};

// Decrypt
export const getEncryptedObj = (key: string): IObj | undefined => {
  const ciphertext = localStorage.getItem(key);
  if (ciphertext) {
    const bytes = CryptoJS.AES.decrypt(
      ciphertext,
      process.env.REACT_APP_CRYPTOJS_KEY as string
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) as IObj;
  }
};
