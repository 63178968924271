import React, { useState } from "react";
import { Breadcrumb } from "../../../../common/Parts";
import styles from "./style.module.scss";
import { Pagination } from "../../../../common/components/Pagination";
// import { ReactComponent as View } from "../../../../assests/svg/view_icon.svg";
import {
  Button,
  Dropdown,
  SearchBar,
  Table,
} from "../../../../common/components";
import {
  APP_PATHS,
  LIMIT,
  ORGANIZATION_MODAL_NAMES,
  REQUEST_TYPE,
  RQ_KEYS,
  USER_ROLES,
} from "../../../../utils/constants";
import {
  _useMutation,
  useApiQuery,
  _getQueryState,
} from "../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../utils/api";
// import invite_user from "../../../../assests/svg/invite_user.svg";
import { ReactComponent as InviteUserIcon } from "../../../../assests/svg/invite_user.svg";
import { useQueryClient } from "react-query";
import useDebounce from "../../../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../../../assests/svg/edit_icon.svg";
import { ReactComponent as ViewIcon } from "../../../../assests/svg/view_icon.svg";
import Tooltip from "../../../../common/Parts/ToolTip";
import InviteOrgUserPopup from "../../../../common/Parts/ModalPopups/OrganizationModals/InviteOrgUserPopup";
import { IModal } from "../../../../interfaces/IModal";
import ResendInvitationButton from "../../../../common/components/ResendInvitationButton";
import LoadingHoc from "../../../../common/components/LoadingHoc";
import InviteOrgVendorPopup from "../../../../common/Parts/ModalPopups/OrganizationModals/InviteOrgVendorPopup";
import {
  capitalizeFirstLetter,
  getAdminRoleFromKey,
} from "../../../../utils/helpers";
import CustomizedMenus from "../../../../common/components/MenuList";

const ManageVendors: React.FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = React.useState<string>("null");
  const [showModal, setModal] = React.useState<IModal>({
    state: false,
    modalName: "",
    type: "CREATE",
  });
  const [dataLimit, setDataLimit] = useState<any>({ limit: LIMIT, offset: 0 });

  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  const onSuccessInviteAndUpdateUser = () => {
    setModal((prev) => ({ ...prev, state: false }));
    queryClient.refetchQueries({ queryKey: RQ_KEYS.VENDOR_USERS_LIST });
  };

  const inviteOrgUser = _useMutation({
    onSuccess: onSuccessInviteAndUpdateUser,
  });

  const updateOrgUser = _useMutation({
    onSuccess: onSuccessInviteAndUpdateUser,
  });

  const onSubmitUpdateUser = (values: any, organizationData: any) => {
    queryClient.setQueryData(RQ_KEYS.LOADING, true);
    updateOrgUser.mutate({
      url: API_ROUTE.ORG_USER_UPDATE,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        ...values,
      },
    });
    queryClient.removeQueries(RQ_KEYS.TOAST_STATE);
  };

  const onSubmitInviteUser = (values: any, organizationData: any) => {
    // console.log("in on submit ", values);
    // queryClient.setQueryData(RQ_KEYS.LOADING, true);
    // inviteOrgUser.mutate({
    //   url: API_ROUTE.ORG_USER_SEND_INVITE,
    //   requestType: REQUEST_TYPE._POST,
    //   requestData: {
    //     ...values,
    //     branch_id: organizationData?.branch_locations.find(
    //       (loc: any) => loc.branch_name === values.branch_name
    //     ).olr_id,
    //     role: USER_ROLES.find((role: any) => role.name === values.role)!.key,
    //     user_org_id: organizationData?.org_id,
    //     org_name: organizationData?.org_name,
    //   },
    // });
    // queryClient.removeQueries(RQ_KEYS.TOAST_STATE);

    console.log("in on submit ", values);
    queryClient.setQueryData(RQ_KEYS.LOADING, true);
    inviteOrgUser.mutate({
      url: API_ROUTE.ORG_VENDOR_SEND_INVITE,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        ...values,
        branch_id: organizationData?.branch_locations.find(
          (loc: any) => loc.branch_name === values.branch_name
        ).olr_id,
        // role: USER_ROLES.find((role: any) => role.name === values.role)!.key,
        user_org_id: organizationData?.org_id,
        org_name: organizationData?.org_name,
      },
    });
    queryClient.removeQueries(RQ_KEYS.TOAST_STATE);
  };

  const usersLoadingState: any = _getQueryState({
    key: `${RQ_KEYS.VENDOR_USERS_LIST}_loading`,
  });

  // let { data, isLoading } = useApiQuery({
  //   queryKey: RQ_KEYS.ORG_USERS_LIST,
  //   requestType: REQUEST_TYPE._GET,
  //   url:
  //     API_ROUTE.GET_ORG_USERS_LIST +
  //     `/${dataLimit.limit}/${dataLimit.offset}/${searchKey}`,
  // });

  let { data, isLoading } = useApiQuery({
    queryKey: RQ_KEYS.VENDOR_USERS_LIST,
    requestType: REQUEST_TYPE._GET,
    url:
      API_ROUTE.GET_VENDOR_USERS_LIST +
      `/${dataLimit.limit}/${dataLimit.offset}/${searchKey}`,
  });

  let totalCount = data && data.totalCount;
  data = data && data.userData;

  const getVendorUsersLists = _useMutation({
    queryKey: RQ_KEYS.VENDOR_USERS_LIST,
  });
  const onSuccess = () => {
    getVendorUsersLists.mutate({
      url:
        API_ROUTE.GET_VENDOR_USERS_LIST +
        `/${dataLimit.limit}/${dataLimit.offset}/null`,
      requestType: REQUEST_TYPE._GET,
    });
  };
  const onPaginzationChange = ({ offset }: any) => {
    setDataLimit((prev: any) => ({
      ...prev,
      offset,
    }));
    getVendorUsersLists.mutate({
      url: API_ROUTE.GET_VENDOR_USERS_LIST + `/${LIMIT}/${offset}/null`,
      requestType: REQUEST_TYPE._GET,
    });
  };
  const { mutate } = _useMutation({
    onSuccess,
  });

  const changeUserActiveStatus = _useMutation({ onSuccess });
  console.log("data orgadmin**", data);
  const changeActiveStatus = async (action: string, user_id: number) => {
    queryClient.setQueryData(RQ_KEYS.LOADING, true);
    await changeUserActiveStatus.mutate({
      url: API_ROUTE.ACTIVE_SUSPEND_USER,
      requestType: REQUEST_TYPE._PATCH,
      requestData: { action, user_id },
    });
    queryClient.refetchQueries({ queryKey: RQ_KEYS.ORG_USERS_LIST });
  };
  const searchQuery = useDebounce(searchKey, 2000);
  React.useMemo(() => {
    if (searchQuery || searchKey.length < 0) {
      getVendorUsersLists.mutate({
        url:
          API_ROUTE.GET_VENDOR_USERS_LIST +
          `/${dataLimit.limit}/${dataLimit.offset}/${searchKey}`,
        requestType: REQUEST_TYPE._GET,
      });
    } else if (searchKey.length === 0) {
      getVendorUsersLists.mutate({
        url:
          API_ROUTE.GET_VENDOR_USERS_LIST +
          `/${dataLimit.limit}/${dataLimit.offset}/null`,
        requestType: REQUEST_TYPE._GET,
      });
    }
  }, [searchQuery]);

  const handleResendInvitation = (item: any) => {
    queryClient.setQueryData(RQ_KEYS.LOADING, true);
    mutate({
      url: API_ROUTE.RESEND_ORG_USER_INVITE,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        email: item.email,
        vendor_org_name: item.vendor_org_name,
        org_id: item?.org_id,
        first_name: item?.first_name,
        last_name: item?.last_name,
        role: item?.role,
        user_id: item?.user_id,
      },
    });
  };
  const organizationData: any = _getQueryState({
    key: RQ_KEYS.USER_ORGANIZATION,
  });

  function ShowUserProfile() {
    // console.log(userAuthData)
    if (
      userAuthData.role !== "OBOEDIO_ADMIN" &&
      userAuthData.role !== "SUPPORT_USER"
    ) {
      navigate(APP_PATHS.USER_LOGIN_DETAILS);
    }
  }

  return (
    <React.Fragment>
      <div className={styles.userManagementContainer}>
        {/* <Breadcrumb
          pageName="Manage Vendors"
          path={["Vendors"]}
          org_name={organizationData?.org_name}
        /> */}

        <div className={styles.userManagementBody}>
          <div className={styles.userDataRowOne}>
            <SearchBar
              type="text"
              placeholder="Search"
              isRequired={false}
              name="searchBar"
              display="flex"
              onChange={(event: any) => setSearchKey(event.target.value)}
            />
            <Button
              label="Invite Vendor"
              id="invite user btn"
              borderRadius="5px"
              // padding="0px"
              fontWeight="bold"
              color="var(--whiteColor)"
              // maxWidth="140px"
              fontSize="var(--secondaryFont)"
              onClick={() => {
                setModal((prev) => ({
                  ...prev,
                  state: true,
                  modalName: "INVITE USER",
                  type: "CREATE",
                }));
              }}
              border="none"
              // icon={invite_user}
              Icon={<InviteUserIcon />}
            />
          </div>
          <div className={styles.userDataRowTable}>
            <LoadingHoc isLoading={usersLoadingState}>
              <Table
                tableHeaders={[
                  "User Id",
                  "User Name",
                  "Organization Name", // IMPROVEMENTS>> Need to rename Office Location to something else  */
                  "User Email",
                  "Role",
                  "Status",
                ]}
                tableData={
                  data && data.length > 0
                    ? data.map((item: any) => {
                        console.log({ item });
                        return {
                          user_id: item.user_id,
                          user_name: item.user_name,
                          vendor_org_name: item.vendor_org_name,
                          email: item.email,
                          Role: USER_ROLES.find(
                            (appRole: any) => appRole.key === item.role
                          )!.name,
                          ...item,
                        };
                      })
                    : []
                }
                hideColumns={["first_name", "last_name", "role"]}
                showActionWithColumns={[
                  {
                    key: "is_active",
                    button: (data: any) => {
                      console.log("resend data", data);
                      if (
                        data &&
                        (data.is_active === "PENDING" || data.is_active === "")
                      ) {
                        // IMPROVEMENTS DONE>>  Make it as reuseable  */
                        return (
                          <td>
                            <ResendInvitationButton
                              data={data}
                              handleResendInvitation={handleResendInvitation}
                            />
                          </td>
                        );
                      } else {
                        return (
                          <Dropdown
                            label={data?.is_active}
                            classParent={styles.dropdownUl}
                            dropdownData={[
                              {
                                name: "ACTIVE",
                                value: "ACTIVE",
                              },
                              {
                                name: "SUSPENDED",
                                value: "SUSPENDED",
                              },
                            ]}
                            onClick={(selectedItem: any) => {
                              changeActiveStatus(
                                selectedItem.value,
                                data?.user_id
                              );
                            }}
                          />
                        );
                      }
                    },
                  },
                ]}
                action={{
                  name: "Action",
                  buttonOne: (data: any) => {
                    return (
                      // <Tooltip text="View">
                      //   <ViewIcon
                      //     fill="#000000"
                      //     className="svg_image_icon"
                      //     onClick={() => {
                      //       navigate(
                      //         `${APP_PATHS.VIEW_VENDOR}/${data.user_id}`
                      //       );
                      //     }}
                      //   />
                      // </Tooltip>
                      <Button
                        label="View"
                        id="View"
                        textDecoration="none"
                        type="button"
                        onClick={() => {
                          navigate(`${APP_PATHS.VIEW_VENDOR}/${data.user_id}`);
                        }}
                        Icon={
                          <ViewIcon
                            fill="var(--whiteColor)"
                            className="svg_image_icon"
                          />
                        }
                      />
                    );
                  },
                  buttonTwo: (data: any) => {
                    return (
                      // <Tooltip text="Edit">
                      //   <EditIcon
                      //     fill="#000000"
                      //     className="svg_image_icon"
                      //     onClick={() => {
                      //       setModal({
                      //         state: true,
                      //         modalName: ORGANIZATION_MODAL_NAMES.UPDATE_USER,
                      //         type: "UPDATE",
                      //         data,
                      //       });
                      //     }}
                      //   />
                      // </Tooltip>
                      <CustomizedMenus
                        actions={[
                          {
                            label: "Edit",
                            icon: <EditIcon fill="var(--whiteColor)" />,
                            condition: true,
                            onClick: () => {
                              setModal({
                                state: true,
                                modalName: ORGANIZATION_MODAL_NAMES.UPDATE_USER,
                                type: "UPDATE",
                                data,
                              });
                            },
                          },
                        ]}
                      />
                    );
                  },
                }}
              />
            </LoadingHoc>

            {data && data.length === 0 && (
              <h6 style={{ paddingTop: "1rem", textAlign: "center" }}>
                No Organization Data Found
              </h6>
            )}
              <Pagination
            data={data}
            totalCount={totalCount}
            onPaginzationChange={onPaginzationChange}
            setDataLimit={setDataLimit}
            dataLimit={dataLimit}
          />
          </div>

        
        </div>
      </div>
      {/* // IMPROVEMENTS DONE>>  manage modal state as we're doing for other modals for e.g. LocationModal */}

      {showModal?.state && (
        <InviteOrgVendorPopup
          openState={showModal?.state}
          heading={
            showModal.type === "CREATE"
              ? "Invite Vendor"
              : "Edit Vendor User"
          }
          onClickCancel={() => {
            setModal((prev) => ({ ...prev, state: false }));
          }}
          onSubmit={
            showModal.type === "CREATE"
              ? onSubmitInviteUser
              : onSubmitUpdateUser
          }
          data={showModal?.data}
          type={showModal?.type}
        />
      )}
    </React.Fragment>
  );
};
export default ManageVendors;
