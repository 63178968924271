import React, { useState, useCallback, useEffect } from "react";
import styles from "./style.module.scss";
// import { Button } from "../../../../common/components";
// import { ReactComponent as Menu } from "../../../../assests/svg/menu.svg";
// import { ReactComponent as View } from "../../../../assests/svg/view.svg";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from "../../../../common/Parts";
import { Button, MultiSelect } from "../../../../common/components";
import LoadingHoc from "../../../../common/components/LoadingHoc";
import QuestionCard from "../../../../common/components/QuestionCard";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../utils/api";
import {
  APP_PATHS,
  ASSIGNED_STATUS,
  LIMIT,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../utils/constants";
import { Pagination } from "../../../../common/components/Pagination";

const DEFAULT_VALUE = {
  value: null,
  label: "ALL",
};

const AssignedAssessments = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mutate: updateAssignedStatus } = _useMutation({
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: RQ_KEYS.PENDING_TASKS,
      });
      queryClient.refetchQueries({
        queryKey: RQ_KEYS.TO_REVIEW_TASKS,
      });
      queryClient.refetchQueries({
        queryKey: RQ_KEYS.COMPLETED_TASKS,
      });
      queryClient.refetchQueries({
        queryKey: RQ_KEYS.TASKS_COUNT,
      });
      setQuestionDetailModal({ state: false, task: null });
    },
    queryKey: RQ_KEYS.UPDATE_TASK_ASSIGNED_STATUS,
  });
  // const updateTaskAssignedStatusLoading = _getQueryState({
  //   key: `${RQ_KEYS.UPDATE_TASK_ASSIGNED_STATUS}_loading`,
  // });
  const [dataLimit, setDataLimit] = useState<any>({
    limit: LIMIT,
    offset: location?.state?.offset ? location.state.offset : 0,
  });
  console.log({ dataLimit });
  const [selectedAnswer, setSelectedAnswer] = useState<string>("");
  const [activeTab, setActiveTab] = useState(0);
  const [questionDetailModal, setQuestionDetailModal] = useState<any>({
    state: false,
    task: null,
  });

  const [selectedAssessmentId, setSelectedAssessmentId] = useState(
    location?.state?.selectedAssessmentId
      ? location.state.selectedAssessmentId
      : null
  );

  console.log({ selectedAssessmentId });

  const queryClient = useQueryClient();
  const { mutate: getCompletedQuestions } = _useMutation({
    queryKey: RQ_KEYS.COMPLETED_QUESTIONS,
  });
  const { mutate: getAssignedQuestions } = _useMutation({
    queryKey: RQ_KEYS.INPROGRESS_QUESTIONS,
  });
  const organizationData: any = _getQueryState({
    key: RQ_KEYS.USER_ORGANIZATION,
  });

  const { mutate: getQuestionCount } = _useMutation({
    queryKey: RQ_KEYS.QUESTION_COUNT,
  });

  // const { data: questionCount } = useApiQuery({
  //   queryKey: RQ_KEYS.QUESTION_COUNT,
  //   url: `${API_ROUTE.ASSIGNED_QUESTION_COUNT}`,
  //   requestType: REQUEST_TYPE._GET,
  //   staleTime: 10,
  // });

  const questionCount: any = _getQueryState({
    key: RQ_KEYS.QUESTION_COUNT,
  });

  let selectAssessments: any = _getQueryState({
    key: RQ_KEYS.SELECT_ASSESSMENT,
  });

  console.log("selectedAssessemnts", selectAssessments, selectedAssessmentId);

  useApiQuery({
    queryKey: RQ_KEYS.SELECT_ASSESSMENT,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.SELECT_ASSESSMENT}?assigned_status=Assigned`,
  });

  useEffect(() => {
    if (selectAssessments && selectAssessments.length > 0) {
      // setSelectedAssessmentId(
      //   selectedAssessmentId
      //     ? selectedAssessmentId
      //     : selectAssessments[0]?.value
      // );
      console.log({ selectedAssessmentId });
      getAssignedQuestions({
        url: `${
          API_ROUTE.GET_ASSIGNED_QUESTIONS_TO_USER
        }?assigned_status=Assigned&assessment_id=${
          selectedAssessmentId ? selectedAssessmentId : null
        }&limit=${dataLimit.limit}&offset=${dataLimit.offset}`,
        // url: `${API_ROUTE.GET_ASSIGNED_QUESTIONS_TO_USER}?assigned_status=Assigned&limit=${dataLimit.limit}&offset=${dataLimit.offset}`,
        requestType: REQUEST_TYPE._GET,
      });
      getQuestionCount({
        url: `${API_ROUTE.ASSIGNED_QUESTION_COUNT}?assessment_id=${
          selectedAssessmentId
            ? selectedAssessmentId
            : //  : selectAssessments[0]?.value
              null
        }`,
        // url: `${API_ROUTE.ASSIGNED_QUESTION_COUNT}`,
        requestType: REQUEST_TYPE._GET,
      });
    }
  }, [
    selectAssessments,
    dataLimit,
    getAssignedQuestions,
    getQuestionCount,
    selectedAssessmentId,
  ]);

  // useApiQuery({
  //   queryKey: RQ_KEYS.INPROGRESS_QUESTIONS,
  //   requestType: REQUEST_TYPE._GET,
  //   url: `${API_ROUTE.GET_ASSIGNED_QUESTIONS_TO_USER}?assigned_status=Assigned&limit=${dataLimit.limit}&offset=${dataLimit.offset}`,
  // });

  const assignedQuestions: any = _getQueryState({
    key: RQ_KEYS.INPROGRESS_QUESTIONS,
  });
  console.log("assigned questions", assignedQuestions);
  const assignedQuestionsLoadingState: any = _getQueryState({
    key: `${RQ_KEYS.INPROGRESS_QUESTIONS}_loading`,
  });
  const completedQuestionsLoadingState: any = _getQueryState({
    key: `${RQ_KEYS.COMPLETED_QUESTIONS}_loading`,
  });
  const completedQuestions: any = _getQueryState({
    key: `${RQ_KEYS.COMPLETED_QUESTIONS}`,
  });
  console.log("selectedAnswer", selectedAnswer);
  const renderTabs = React.useMemo(() => {
    return [
      {
        title: `In Progress (${
          questionCount?.pendingQuestionCount > 0
            ? questionCount?.pendingQuestionCount
            : 0
        })`,
        id: "IN_PROGRESS",
      },
      {
        title: `Completed (${
          questionCount?.completedQuestionCount > 0
            ? questionCount?.completedQuestionCount
            : 0
        })`,
        id: "COMPLETED",
      },
    ];
  }, [questionCount]);

  // const onPaginzationChange = ({ offset }: any) => {
  //   setDataLimit((prev: any) => ({
  //     ...prev,
  //     offset,
  //   }));
  //   getAssingedQuestions.mutate({
  //     url: `${API_ROUTE.GET_ASSIGNED_QUESTIONS_TO_USER}?limit=${dataLimit.limit}&offset=${offset}`,
  //     requestType: REQUEST_TYPE._GET,
  //   });

  //   window.scrollTo(0, 0);
  // };

  const handleTabClick = (index: any) => {
    setActiveTab(index);
    setDataLimit({ limit: LIMIT, offset: 0 });
    if (index === 0) {
      queryClient.refetchQueries({ queryKey: RQ_KEYS.INPROGRESS_QUESTIONS });
    } else if (index === 1) {
      getCompletedQuestions({
        url: `${
          API_ROUTE.GET_ASSIGNED_QUESTIONS_TO_USER
        }?assigned_status=Completed&assessment_id=${selectedAssessmentId}&limit=${LIMIT}&offset=${0}`,
        requestType: REQUEST_TYPE._GET,
      });
    }
  };
  const onSelectQuestion = useCallback(
    ({ question_id, chapter_id, assessment_id }: any) => {
      navigate(
        `${APP_PATHS.QUESTION_SCREEN}?assessment_id=${
          assessment_id && btoa(assessment_id)
        }&chapter_id=${chapter_id && btoa(chapter_id)}&question_id=${
          question_id && btoa(question_id)
        }`,
        {
          state: {
            offset: dataLimit.offset,
            selectedAssessmentId,
          },
        }
      );
    },
    [navigate, dataLimit, selectedAssessmentId]
  );

  const showSidePopupHandler = (question: any) => {
    setQuestionDetailModal({ state: true, question });
  };

  const { mutate: submitAnswer } = _useMutation({
    onSuccess: () => {
      // queryClient.refetchQueries({ queryKey: RQ_KEYS.COMPLETED_QUESTIONS });
      // queryClient.refetchQueries({ queryKey: RQ_KEYS.INPROGRESS_QUESTIONS });
      // queryClient.refetchQueries({ queryKey: RQ_KEYS.TASKS_COUNT });
      // setQuestionDetailModal({ state: false, question: null });
    },
  });

  const onSubmitAnswer = (org_assign_assessment_id: number, answer: string) => {
    submitAnswer({
      url: API_ROUTE.SUBMIT_ASSESSMENT_ANSWER,
      requestType: REQUEST_TYPE._PATCH,
      requestData: {
        answer: answer,
        org_assign_assessment_id,
      },
    });
  };
  const handleSendToReview = ({ org_assign_assessment_id, notes }: any) => {
    // console.log("org_assign_assessment_id", assignee_notes);
    updateAssignedStatus({
      url: API_ROUTE.UPDATE_TASK_ASSIGNED_STATUS,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        org_assign_assessment_id,
        assigned_status: ASSIGNED_STATUS.UNDER_REVIEW,
        assignee_notes: notes,
      },
    });
  };

  const onPaginzationChange = ({ offset }: any) => {
    setDataLimit((prev: any) => ({
      ...prev,
      offset,
    }));
    if (renderTabs[activeTab].id === "COMPLETED") {
      getCompletedQuestions({
        url: `${API_ROUTE.GET_ASSIGNED_QUESTIONS_TO_USER}?assigned_status=Completed&assessment_id=${selectedAssessmentId}limit=${dataLimit.limit}&offset=${offset}`,
        requestType: REQUEST_TYPE._GET,
      });
    } else {
      getAssignedQuestions({
        url: `${API_ROUTE.GET_ASSIGNED_QUESTIONS_TO_USER}?assigned_status=Assigned&assessment_id=${selectedAssessmentId}&limit=${dataLimit.limit}&offset=${offset}`,
        requestType: REQUEST_TYPE._GET,
      });
    }
    getQuestionCount({
      url: `${API_ROUTE.ASSIGNED_QUESTION_COUNT}?assessment_id=${selectedAssessmentId}`,
    });
  };

  const renderQuestions: any = React.useCallback(() => {
    switch (renderTabs[activeTab].id) {
      case "IN_PROGRESS":
        return (
          <LoadingHoc isLoading={assignedQuestionsLoadingState}>
            <QuestionCard
              tab={renderTabs[activeTab].id}
              heading="In Progress"
              questions={assignedQuestions}
              onSelectQuestion={onSelectQuestion}
              isAnswerAllowed={true}
              showSidePopupHandler={showSidePopupHandler}
            />
          </LoadingHoc>
        );
      case "COMPLETED":
        return (
          <LoadingHoc isLoading={completedQuestionsLoadingState}>
            <QuestionCard
              tab={renderTabs[activeTab].id}
              heading="Completed"
              questions={completedQuestions}
              isAnswerAllowed={false}
              showSidePopupHandler={showSidePopupHandler}
            />
          </LoadingHoc>
        );
      default:
        <p>No Assessment Found</p>;
    }
  }, [
    activeTab,
    assignedQuestions,
    completedQuestions,
    renderTabs,
    assignedQuestionsLoadingState,
    completedQuestionsLoadingState,
    onSelectQuestion,
  ]);
  return (
    <div className={styles.userTasksContainer}>
      <Breadcrumb
        pageName="Assessments"
        // org_name={organizationData?.org_name}
      />

      {selectAssessments && selectAssessments.length > 0 && (
        <div className={styles.selectAssessOrgUser}>
          <MultiSelect
            label="Select Assessment"
            name="assessment"
            placeholder="Select"
            controlHeight="30px"
            // isRequired={true}
            includeNone={true}
            defaultValue={
              selectedAssessmentId
                ? selectAssessments &&
                  selectAssessments.find(
                    (ass: any) => ass.value === selectedAssessmentId
                  )
                : DEFAULT_VALUE
            }
            options={[DEFAULT_VALUE].concat(selectAssessments)}
            onChange={(selectedValue: any) => {
              console.log("selectef vlaue", selectedValue);
              setSelectedAssessmentId(selectedValue);
              if (renderTabs[activeTab].id === "COMPLETED") {
                getCompletedQuestions({
                  url: `${API_ROUTE.GET_ASSIGNED_QUESTIONS_TO_USER}?assigned_status=Completed&assessment_id=${selectedValue}&limit=${dataLimit.limit}&offset=${dataLimit.offset}`,
                  requestType: REQUEST_TYPE._GET,
                });
              } else {
                getAssignedQuestions({
                  url: `${API_ROUTE.GET_ASSIGNED_QUESTIONS_TO_USER}?assigned_status=Assigned&assessment_id=${selectedValue}&limit=${dataLimit.limit}&offset=${dataLimit.offset}`,
                  requestType: REQUEST_TYPE._GET,
                });
              }
              getQuestionCount({
                url: `${API_ROUTE.ASSIGNED_QUESTION_COUNT}?assessment_id=${selectedValue}`,
                requestType: REQUEST_TYPE._GET,
              });
              setDataLimit({ ...dataLimit, offset: 0 });
              // setArticleIndex(selectedValue);
              // onChangeQuestionIndex(0);
            }}
            isMulti={false}
          ></MultiSelect>
        </div>
      )}
      <div className={styles.tabsHeader}>
        {renderTabs &&
          renderTabs.map((tab, index) => (
            <div
              key={index}
              className={
                activeTab === index
                  ? `${styles.active} ${styles.tabIn}`
                  : `${styles.tabIn}`
              }
            >
              <Button
                label={tab.title}
                id="tabTitle"
                fontWeight="bold"
                fontSize="calc(var(--secondaryFont) + 2px)"
                border="none"
                background="transparent"
                color="var(--blackColor)"
                onClick={() => handleTabClick(index)}
                borderRadius="0"
              />
            </div>
          ))}
      </div>
      <div className={styles.tasksBody}>{renderQuestions()}</div>
      {/* {questionDetailModal && questionDetailModal?.state && (
        <UserQuestionDetail
          heading="Track Questions"
          onClickCancel={() => {
            setQuestionDetailModal({ state: false, question: null })
          }}
          question={questionDetailModal && questionDetailModal.question}
          isReadOnly={
            questionDetailModal.question.assigned_status ===
            ASSIGNED_STATUS.COMPLETED
          }
          yesButton={() => {
            return (
              <Button
                id="yesbtn"
                label="Yes"
                background="var(--whiteColor)"
                border="1px solid var(--secondaryColor)"
                color="var(--secondaryColor)"
                btnClass={selectedAnswer === 'Yes' ? styles.active : ''}
                onClick={() => {
                  setSelectedAnswer('Yes')
                  // onSubmitAnswer(org_assign_assessment_id, "Yes");
                  // handleAddComment();
                }}
              />
            )
          }}
          naButton={() => {
            return (
              <Button
                id="nabtn"
                label="N/A"
                background="var(--whiteColor)"
                border="1px solid var(--secondaryColor)"
                color="var(--secondaryColor)"
                btnClass={selectedAnswer === 'NA' ? styles.active : ''}
                onClick={() => {
                  setSelectedAnswer('NA')
                  // onSubmitAnswer(org_assign_assessment_id, "NA");
                  // handleAddComment();
                }}
              />
            )
          }}
          buttonPrimary={({
            org_assign_assessment_id,
            notes,
            handleAddComment,
          }: any) => {
            return (
              <Button
                type="button"
                label="Submit"
                id="primary"
                fontSize="calc(var(--secondaryFont) + 2px)"
                onClick={() => {
                  onSubmitAnswer(org_assign_assessment_id, selectedAnswer)
                  handleSendToReview({ org_assign_assessment_id, notes })
                  handleAddComment()
                }}
                loading={updateTaskAssignedStatusLoading}
                disabled={
                  updateTaskAssignedStatusLoading || selectedAnswer === ''
                }
                Icon={
                  <SendIcon
                    fill="var(--whiteColor)"
                    style={{ maxWidth: '14px' }}
                  />
                }
              />
            )
          }}
          buttonSecondary={() => {
            return (
              <Button
                label="Cancel"
                id="secondary"
                fontSize="calc(var(--secondaryFont) + 2px)"
                onClick={() => {
                  setQuestionDetailModal({ state: false, question: null })
                }}
                Icon={
                  <CancelIcon
                    fill="var(--whiteColor)"
                    style={{ maxWidth: '14px' }}
                  />
                }
              />
            )
          }}
        />
      )} */}
      {!(assignedQuestionsLoadingState || completedQuestionsLoadingState) && (
        <Pagination
          data={
            renderTabs[activeTab].id === "IN_PROGRESS"
              ? assignedQuestions
              : completedQuestions
          }
          totalCount={
            renderTabs[activeTab].id === "IN_PROGRESS"
              ? questionCount?.pendingQuestionCount
              : questionCount?.completedQuestionCount
          }
          onPaginzationChange={onPaginzationChange}
          setDataLimit={setDataLimit}
          dataLimit={dataLimit}
        />
      )}
    </div>
  );
};

export default React.memo(AssignedAssessments);
