import React, { useState } from "react";
import styles from "./style.module.scss";
import { Button, SearchBar, Table } from "../../../../common/components";
import {
  APP_PATHS,
  LIMIT,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../utils/constants";
import { ReactComponent as UnArchiveIcon } from "../../../../assests/svg/un_archive_icon.svg";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../utils/api";
import { Spinner } from "react-bootstrap";
import { useQueryClient } from "react-query";
import Tooltip from "../../../../common/Parts/ToolTip";

const ManageAssessments = () => {
  const queryClient = useQueryClient();
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });

  // let { data } = useApiQuery({
  //   queryKey: RQ_KEYS.ARCHIVE_ASSESSMENT_LIST,
  //   requestType: REQUEST_TYPE._GET,
  //   url: `${API_ROUTE.GET_ARCHIVE_ASSESSMENT_LIST}`,
  // });
  const [dataLimit, setDataLimit] = useState<any>({ limit: LIMIT, offset: 0 });
  const { data }: any = useApiQuery({
    queryKey: RQ_KEYS.ARCHIVE_ASSESSMENT_LIST,
    requestType: REQUEST_TYPE._POST,
    url: `${API_ROUTE.GET_ASSESSMENTS}?limit=${dataLimit?.limit}&offset=${dataLimit?.offset}&publish_status=Published&archive_status=YES`,
  });
  console.log("DATA>>>>", data);
  const onSuccess = async () => {
    queryClient.refetchQueries({ queryKey: RQ_KEYS.ARCHIVE_ASSESSMENT_LIST });
    queryClient.refetchQueries({ queryKey: RQ_KEYS.PUBLISHED_ASSESSMENT });
  };
  const updateArchiveStatus = _useMutation({ onSuccess });
  const onUnArchiveAssessment = async (assessment_id: number) => {
    await updateArchiveStatus.mutate({
      url: `${API_ROUTE.UPDATE_ARCHIVE_STATUS}`,
      requestType: REQUEST_TYPE._PATCH,
      requestData: {
        assessment_id,
        action: "NO",
      },
    });
  };
  return (
    <React.Fragment>
      <div className={`${styles.assessmentMain} ${styles.overflowTable}`}>
        <div className={styles.assessmentheader}>
          <h4>Archived Assessments</h4>
          {/* <SearchBar
            type="text"
            placeholder="Search by Assessment Name"
            isRequired={false}
            name="searchBar"
            display="flex"
            // onChange={(event: any) => setSearchKey(event.target.value)}
          /> */}
        </div>
        {!loadingState ? (
          <Table
            tableHeaders={[
              "Assessment Name",
              "Region",
              "Jurisdiction",
              "Assessment Category",
              "Archived Date",
            ]}
            tableData={data?.assessments}
            hideColumns={[
              "archived",
              "updated_at",
              "assessment_id",
              "assessment_creater_id",
              "assessment_industry",
              "assessment_jurisdiction_country",
              "assessment_jurisdiction_state",
              "assessment_location",
              "publish_status",
            ]}
            action={{
              name: "Action",
              buttonOne: (data: any) => {
                return (
                  // <Tooltip text="Unarchive" rightSide={true}>
                  //   <UnArchiveIcon
                  //     fill="#000000"
                  //     className="svg_image_icon"
                  //     onClick={() => onUnArchiveAssessment(data?.assessment_id)}
                  //   ></UnArchiveIcon>
                  // </Tooltip>
                  <Button
                    // background=" var(--primaryColor)"
                    onClick={() => onUnArchiveAssessment(data?.assessment_id)}
                    label="Unarchive"
                    id="1"
                    Icon={
                      <UnArchiveIcon
                        fill="#ffffff"
                        className="svg_image_icon"
                      />
                    }
                    // fill="#000000"
                    // className="svg_image_icon"
                  />
                );
              },
            }}
          />
        ) : (
          <div className={styles.assesmentLoader}>
            <Spinner animation="border" variant="primary" />
          </div>
        )}

        {data && data?.assessments?.length === 0 && (
          <h6 style={{ paddingTop: "1rem", textAlign: "center" }}>
            No Assessment found in Archive
          </h6>
        )}
      </div>
    </React.Fragment>
  );
};

export default ManageAssessments;
