import React, { useState } from "react";
import styles from "./style.module.scss";
import { IPublishModal } from "../../../../interfaces/IAssessmentModals";
import { Button } from "../../../components";
import { Formik, Form } from "formik";
import cancel_icon from "../../../../assests/svg/cancel_icon.svg";
// import { InitialChapterValue, ChatperSchema } from "./validator";
import { useParams } from "react-router-dom";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import { REQUEST_TYPE, RQ_KEYS } from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import { useQueryClient } from "react-query";
import Toast from "../../../components/Toast";
import { ITOAST_STATE } from "../../../../interfaces/IToastState";
import { ReactComponent as CancelBtn } from "../../../../assests/svg/close.svg";
import { ReactComponent as UnPublishIcon } from "../../../../assests/svg/un_publish.svg";
import { ReactComponent as PublishIcon } from "../../../../assests/svg/publish.svg";
import { Modal } from "@mui/material";

const PublishUnPublishModal: React.FC<IPublishModal> = ({
  onClickCancel,
  heading,
  assessment_id,
  type,
  openState,
}) => {
  const [errorPublish, setErrorPublish] = useState("");
  const queryClient = useQueryClient();
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });
  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  // const { assessment_id } = useParams();
  // const getChatpers = _useMutation({
  //   queryKey: RQ_KEYS.ALL_CHAPTERS,
  // });

  // const { data: assessmentQuestionCount }: any = useApiQuery({
  //   queryKey: RQ_KEYS.ASSESSMENT_QUESTION_COUNT,
  //   requestType: REQUEST_TYPE._GET,
  //   url: `${API_ROUTE.GET_ASSESSMENT_QUESTION_COUNT}?assessment_id=${assessment_id}`,
  // });

  const { data: assessmentChaptersQuestionCount, isLoading }: any = useApiQuery({
    queryKey: RQ_KEYS.ASSESSMENT_CHAPTER_QUESTION_COUNT,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_ASSESSMENT_CHAPTER_QUESTION_COUNT}?assessment_id=${assessment_id}`,
  });

  // console.log("assessmentQuestionCOunt", assessmentQuestionCount);
  console.log("assessmentChaptersQuestionCount", assessmentChaptersQuestionCount);

  const onSuccess = (data: any) => {
    queryClient.refetchQueries({ queryKey: RQ_KEYS.PUBLISHED_ASSESSMENT });
    queryClient.refetchQueries({ queryKey: RQ_KEYS.DRAFT_ASSESSMENT });
    if (data?.data?.type === "error") {
      setErrorPublish(data?.message);
      return;
    }
    onClickCancel();
  };

  const { mutate } = _useMutation({ onSuccess });
  console.log("assessment_id", assessment_id);
  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.addChapterHeader}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>
          <Toast toastState={toastState} />
          <Formik
            initialValues={{ assessment_id }}
            // validationSchema={ChatperSchema}
            onSubmit={async ({ assessment_id }) => {
              const hasCompleteChapter = assessmentChaptersQuestionCount?.every((chapter: any) => 
                chapter.chapter_data.articles.every((article: any) => article.question_count > 0)
              ) && assessmentChaptersQuestionCount?.length > 0;
              
              console.log("hasCompleteChapter", hasCompleteChapter);
              
              if (!hasCompleteChapter) {
                setErrorPublish(
                  "Please add at least one question in each article of an chapter to publish the assessment."
                );
                return;
              }
              
              queryClient.setQueryData(RQ_KEYS.LOADING, true);
              // if (type === "DELETE") {

              mutate({
                url: `${API_ROUTE.UPDATE_PUBLISH_STATUS}`,
                requestType: REQUEST_TYPE._PATCH,
                requestData: {
                  assessment_id,
                  action: type,
                },
              });
              // }
            }}
          >
            {({ handleChange, isValid, values, setFieldValue, handleBlur }) => (
              <div className={styles.addChapterRow}>
                <Form>
                  <div className={styles.userLogin}>
                    {type === "Published" ? (
                      <p>Are you sure you want to publish this assessment?</p>
                    ) : (
                      <p>Are you sure you want to unpublish this assessment?</p>
                    )}
                  </div>
                  <div className={styles.inviteUserBtn}>
                    <Button
                      label="Cancel"
                      id="cancelbtn"
                      borderRadius="5px"
                      fontWeight="bold"
                      // maxWidth="100px"
                      fontSize="var(--secondaryFont)"
                      background="var(--whiteColor)"
                      border="1px solid var(--secondaryColor)"
                      color="var(--secondaryColor)"
                      Icon={
                        <CancelBtn
                          fill="#0097ce"
                          style={{ maxWidth: "12px" }}
                        />
                      }
                      onClick={onClickCancel}
                    />
                    {type === "Published" ? (
                      <Button
                        label="Publish"
                        id="deleteChapterbtn"
                        borderRadius="5px"
                        fontWeight="bold"
                        color="var(--whiteColor)"
                        background="var(--secondaryColor)"
                        fontSize="var(--secondaryFont)"
                        border="none"
                        type="submit"
                        Icon={<PublishIcon fill="#ffffff" />}
                        disabled={!isValid || loadingState || isLoading}
                      />
                    ) : (
                      <Button
                        label="UnPublish"
                        id="deleteChapterbtn"
                        borderRadius="5px"
                        fontWeight="bold"
                        color="var(--whiteColor)"
                        background="red"
                        fontSize="var(--secondaryFont)"
                        border="none"
                        type="submit"
                        Icon={<UnPublishIcon fill="#ffffff" />}
                        disabled={!isValid || loadingState|| isLoading}
                      />
                    )}
                  </div>

                  <div className={styles.errorPublish}>{errorPublish}</div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default PublishUnPublishModal;
