import React from "react";
import styles from "./style.module.scss";
import { MultiSelect } from "../../../components";
import { IInviteUser } from "../../../../interfaces/IAssessmentModals";
import { Button } from "../../../components";
import { Formik, Form } from "formik";
import cancel_icon from "../../../../assests/svg/cancel_icon.svg";
import { AssessmentInitialValue, AddAssessmentSchema } from "./validator";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import { REQUEST_TYPE, RQ_KEYS } from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import { ReactComponent as CancelBtn } from "../../../../assests/svg/close.svg";
import { useQueryClient } from "react-query";
import Toast from "../../../components/Toast";
import { ITOAST_STATE } from "../../../../interfaces/IToastState";
import LoadingHoc from "../../../components/LoadingHoc";
import { Modal } from "@mui/material";

const AddAssessmentByAdmin: React.FC<IInviteUser> = ({
  onClickCancel,
  openState,
  heading,
  user_org_id,
  // data: orgData,
  data,
  assignedAssessmentIds,
}) => {
  console.log(
    "user_org_id <><><>",
    data
  );
  const queryClient = useQueryClient();
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });
  // let data = useApiQuery({
  //   queryKey: RQ_KEYS.USER_ORGANIZATION,
  //   requestType: REQUEST_TYPE._GET,
  //   url: `${API_ROUTE.GET_USER_ORGANIZATION}?user_org_id=${
  //     user_org_id && user_org_id > 0 ? user_org_id : null
  //   }`,
  // });

  // console.log("data in by admin", data);
  const onSuccess = async () => {
    queryClient.resetQueries({ queryKey: RQ_KEYS.ASSESSMENTS_BY_ORG_ID });
    queryClient.invalidateQueries({ queryKey: RQ_KEYS.ORG_ADMIN_BY_ID });
    queryClient.invalidateQueries({
      queryKey: RQ_KEYS.ASSESSMENTS_BY_ORG_ID_CATEGORIZED,
    });
    queryClient.resetQueries({ queryKey: RQ_KEYS.TOAST_STATE });
    onClickCancel();
  };
  const addAssessment = _useMutation({ onSuccess });
  const getAssessmentsByRegion = _useMutation({
    queryKey: RQ_KEYS.FILTERED_ASSESSMENT_LIST,
  });

  // const data1 = useApiQuery({
  //   queryKey: RQ_KEYS.USER_ORGANIZATION,
  //   requestType: REQUEST_TYPE._GET,
  //   url: `${API_ROUTE.GET_USER_ORGANIZATION}/${user_org_id}`,
  // });

  let organizationData: any = _getQueryState({
    key: RQ_KEYS.USER_ORGANIZATION,
  });

  organizationData = organizationData
    ? organizationData
    : { ...data, branch_locations: data?.location };

  console.log({ organizationData });

  const organizationDataLoading = _getQueryState({
    key: `${RQ_KEYS.USER_ORGANIZATION}_loading`,
  });

  const categoryList = useApiQuery({
    queryKey: RQ_KEYS.ASSESSMENT_CATEGORIES,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_ASSESSMENT_CATEGORY,
  });
  let assessments: any = _getQueryState({
    key: RQ_KEYS.FILTERED_ASSESSMENT_LIST,
  });

  assessments = assessments?.filter(
    (assessment: any) =>
      !assignedAssessmentIds?.includes(assessment.assessment_id)
  );

  console.log("{assessments", assessments);

  const isLoading: any = _getQueryState({
    key: RQ_KEYS.LOADING,
  });
  const onChangeFields = (values: any) => {
    getAssessmentsByRegion.mutate({
      url: API_ROUTE.GET_FILTERED_ASSESSMENT_LIST,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        filterRegion: values?.region ? [values?.region] : null,
        filterJudiriction: values?.country
          ? [{ country: values?.country, state: values?.state }]
          : null,
        filterCategory: values?.assessment_catgory
          ? [values?.assessment_catgory]
          : null,
        filterIndustries: values?.assessment_industry
          ? [values?.assessment_industry]
          : null,
      },
    });
  };

  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.invitePopupRow1}>
            <p>{heading}</p>
            <img
              className="svg_image_icon"
              src={cancel_icon}
              alt="invite"
              onClick={onClickCancel}
            ></img>
          </div>
          <Formik
            initialValues={AssessmentInitialValue}
            validationSchema={AddAssessmentSchema}
            onSubmit={async (values) => {
              await addAssessment.mutate({
                url: API_ROUTE.ASSIGN_ORG_ASSESSMENT,
                requestType: REQUEST_TYPE._PATCH,
                requestData: {
                  assessment_ids: values.assessments.map(
                    (assessment: any) => assessment.value
                  ),
                  org_id:
                    organizationData.user_org_id || organizationData.org_id,
                  assigned_to_user_id: organizationData?.org_admin_id,
                },
              });
              // onClickCancel();
            }}
          >
            {({ handleChange, isValid, values, setFieldValue }) => (
              <div className={styles.invitePopupRow2}>
                <Form>
                  <div className={styles.userLogin}>
                    {/* {JSON.stringify(values)} */}
                    {toastState && toastState.message && (
                      <Toast toastState={toastState} />
                    )}
                    <div className={`${styles.userLoginInner} ${styles.full}`}>
                      <div className={styles.orgRegionsParent}>
                        <LoadingHoc isLoading={organizationDataLoading}>
                          <div className={styles.orgRegions}>
                            <div className={styles.editField}>
                              <MultiSelect
                                label="Region"
                                name="region"
                                placeholder="Select"
                                isRequired={true}
                                options={
                                  data &&
                                  data?.location?.length > 0 && [
                                    { value: "Worldwide", label: "Worldwide" },
                                    ...data?.location
                                      ?.map(({ region }: any) => {
                                        return {
                                          value: region,
                                          label: region,
                                        };
                                      })
                                      .reduce(
                                        (accumulator: any, current: any) => {
                                          if (
                                            !accumulator.find(
                                              (item: any) =>
                                                item.value === current.value
                                            )
                                          ) {
                                            accumulator.push(current);
                                          }
                                          return accumulator;
                                        },
                                        []
                                      )
                                  ]
                                }
                                values={
                                  values.region
                                    ? {
                                        value: values?.region,
                                        label: values?.region,
                                      }
                                    : ""
                                }
                                setFieldValue={setFieldValue}
                                onChange={(selectedValue: any) => {
                                  onChangeFields({
                                    ...values,
                                    region: selectedValue,
                                  });
                                  setFieldValue("country", null);
                                  setFieldValue("assessments", []);
                                }}
                                isMulti={false}
                                isDisabled={!data}
                              ></MultiSelect>
                            </div>
                            {/* {values?.region && ( */}
                            <div className={styles.editField}>
                              <MultiSelect
                                label="Country"
                                name="country"
                                placeholder="Select"
                                isRequired={false}
                                isDisabled={!values.region}
                                options={
                                  data?.branch_locations?.length > 0
                                    ? data?.branch_locations
                                        ?.filter(
                                          ({ region }: any) =>
                                            region === values.region
                                        )
                                        .map(({ country }: any) => {
                                          return {
                                            value: country,
                                            label: country,
                                          };
                                        })
                                        .reduce(
                                          (accumulator: any, current: any) => {
                                            if (
                                              !accumulator.find(
                                                (item: any) =>
                                                  item.value === current.value
                                              )
                                            ) {
                                              accumulator.push(current);
                                            }
                                            return accumulator;
                                          },
                                          []
                                        )
                                    : []
                                }
                                values={
                                  values.country
                                    ? {
                                        value: values?.country,
                                        label: values?.country,
                                      }
                                    : ""
                                }
                                setFieldValue={setFieldValue}
                                onChange={(selectedValue: any) => {
                                  onChangeFields({
                                    ...values,
                                    country: selectedValue,
                                  });
                                }}
                                isMulti={false}
                              ></MultiSelect>
                            </div>
                            {values?.country === "United States" && (
                              <div className={styles.editField}>
                                <MultiSelect
                                  label="State"
                                  name="state"
                                  placeholder="Select"
                                  isRequired={false}
                                  isDisabled={!values.region}
                                  options={organizationData?.branch_locations
                                    ?.filter(
                                      ({ region }: any) =>
                                        region === values.region
                                    )
                                    .map(({ state }: any) => {
                                      return {
                                        value: state,
                                        label: state,
                                      };
                                    })
                                    .reduce(
                                      (accumulator: any, current: any) => {
                                        if (
                                          !accumulator.find(
                                            (item: any) =>
                                              item.value === current.value
                                          )
                                        ) {
                                          accumulator.push(current);
                                        }
                                        return accumulator;
                                      },
                                      []
                                    )}
                                  values={
                                    values.state
                                      ? {
                                          value: values?.state,
                                          label: values?.state,
                                        }
                                      : ""
                                  }
                                  setFieldValue={setFieldValue}
                                  onChange={(selectedValue: any) => {
                                    onChangeFields({
                                      ...values,
                                      state: selectedValue,
                                    });
                                  }}
                                  isMulti={false}
                                ></MultiSelect>
                              </div>
                            )}
                            {/* )} */}
                            <div className={styles.editField}>
                              <MultiSelect
                                label="Category"
                                name="assessment_catgory"
                                placeholder="Select"
                                isRequired={false}
                                isDisabled={!values.region}
                                options={
                                  categoryList &&
                                  categoryList.data &&
                                  categoryList.data.length > 0
                                    ? categoryList.data
                                    : []
                                }
                                values={
                                  values?.assessment_catgory
                                    ? {
                                        label: values?.assessment_catgory,
                                        value: values?.assessment_catgory,
                                      }
                                    : null
                                }
                                setFieldValue={setFieldValue}
                                onChange={(selectedValue: any) => {
                                  onChangeFields({
                                    ...values,
                                    assessment_catgory: selectedValue,
                                  });
                                }}
                                isMulti={false}
                              ></MultiSelect>
                            </div>
                            {/* <div className={styles.editField}>
                            <MultiSelect
                              label="Industry"
                              name="assessment_industry"
                              placeholder="Select"
                              isRequired={false}
                              isDisabled={!values?.region}
                              options={industryList?.data}
                              values={
                                values?.assessment_industry
                                  ? {
                                      value: values?.assessment_industry,
                                      label: values?.assessment_industry,
                                    }
                                  : null
                              }
                              setFieldValue={setFieldValue}
                              onChange={(selectedValue: any) => {
                                onChangeFields({
                                  ...values,
                                  assessment_industry: selectedValue,
                                });
                              }}
                              isMulti={false}
                            ></MultiSelect>
                          </div> */}

                            <MultiSelect
                              label="Select Assessment"
                              name="assessments"
                              placeholder="Select"
                              menuPlacement='top'
                              isRequired={true}
                              isDisabled={
                                !assessments ||
                                assessments?.length === 0 ||
                                !values.region
                              }
                              options={
                                assessments && assessments.length > 0
                                  ? assessments?.map(
                                      ({
                                        assessment_name,
                                        assessment_id,
                                      }: any) => {
                                        return {
                                          value: assessment_id,
                                          label: assessment_name,
                                        };
                                      }
                                    )
                                  : []
                              }
                              values={values?.assessments}
                              setFieldValue={setFieldValue}
                              // onChange={handleChange}
                              isMulti
                            ></MultiSelect>
                            {/* )} */}
                          </div>
                        </LoadingHoc>
                      </div>
                    </div>
                  </div>
                  <div className={styles.inviteUserBtn}>
                    <Button
                      label="Cancel"
                      id="cancelbtn"
                      borderRadius="5px"
                      fontWeight="bold"
                      // maxWidth="100px"
                      fontSize="var(--secondaryFont)"
                      background="var(--whiteColor)"
                      border="1px solid var(--secondaryColor)"
                      color="var(--secondaryColor)"
                      Icon={
                        <CancelBtn
                          fill="#0097ce"
                          style={{ maxWidth: "12px" }}
                        />
                      }
                      onClick={onClickCancel}
                    />
                    <Button
                      label={`+ ${heading}`}
                      id="saveAssessmentbtn"
                      borderRadius="5px"
                      fontWeight="bold"
                      color="var(--whiteColor)"
                      // maxWidth="180px"
                      fontSize="var(--secondaryFont)"
                      border="none"
                      type="submit"
                      // Icon={
                      //   <EditIcon fill="#ffffff" className="svg_image_icon" />
                      // }
                      loading={addAssessment.isLoading}
                      disabled={addAssessment.isLoading}
                    />
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default AddAssessmentByAdmin;
