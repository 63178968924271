import { ICheckbox } from "../../interfaces/ICheckbox";
import FormikErrorMessage from "../FormikErrorMessage";
import styles from "./style.module.scss";

const Checkbox: React.FC<ICheckbox> = ({
  name,
  type,
  label,
  isRequired,
  onChange,
  display,
  width,
  maxWidth,
  gap,
  flexDirection,
  alignItems,
  justifyContent,
  id,
  fontWeight,
  fontSize,
  borderRadius,
  padding,
  value,
  checked,
  disabled,
}) => {
  const inputStyle = {
    display: display ? display : "flex",
    width: width ? width : "100%",
    maxWidth: maxWidth ? maxWidth : "100%",
    flexDirection: flexDirection ? flexDirection : "column",
    gap: gap ? gap : "0px",
    alignItems: alignItems ? alignItems : "center",
    justifyContent: justifyContent ? justifyContent : "left",
    fontWeight: fontWeight ? fontWeight : "normal",
    borderRadius: borderRadius,
    padding: padding,
    fontSize: fontSize,
  };

  return (
    <div
      className={`${styles.checkbox} ${styles.checkContainer}`}
      style={inputStyle}
      id={id}
    >
      <label htmlFor={name}>{label} </label>
      <input
        type={type}
        id={name}
        name={name}
        onChange={onChange}
        value={value}
        checked={checked}
        disabled={disabled ? disabled : false}
      />
      <span className={styles.checkmark}></span>
      {isRequired && <FormikErrorMessage name={name} />}
    </div>
  );
};

export default Checkbox;
