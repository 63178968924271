import React from "react";
import styles from "./style.module.scss";
import { InputField, Button, Dropdown, MultiSelect } from "../../../components";
import { ISteps } from "../../../../interfaces/ISteps";
import page_icon from "../../../../assests/svg/form.svg";
import { ReactComponent as RightArrowIcon } from "../../../../assests/svg/next_arrow_fill.svg";

const StepOne_OrgDetails: React.FC<ISteps> = ({
  nextPageHandler,
  handleChange,
  handleBlur,
  values,
  setFieldTouched,
  setFieldValue,
}) => {
  console.log("values in step 1l", values);
  return (
    <div className={styles.stepCommonInner}>
      <div className={styles.stepCommonForm}>
        <div className={styles.stepCommonHeading}>
          <img
            style={{
              width: 30,
              alignSelf: "center",
            }}
            src={page_icon}
            alt="forgot_icon"
          ></img>
          <h3>Organization Details</h3>
          {/* <p>Tell us your organization's name, and we'll create your profile</p> */}
        </div>
        <InputField
          name="org_name"
          label="Organization Name"
          type="text"
          alignItems="left"
          placeholder="Organization Name"
          isRequired={true}
          borderRadius="14px"
          width="100%"
          fontSize="16px"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.org_name}
          gap="10px"
          display="flex"
        />
        <MultiSelect
          name="org_type"
          label="Organization Type"
          isRequired={true}
          menuPlacement='top'
          placeholder="Select"
          options={[
            // { value:"", label: "Select" },
            { value: "Private", label: "Private" },
            { value: "Public", label: "Public" },
          ]}
          className={styles.editOrgReactSelect}
          setFieldValue={setFieldValue}
          onChange={handleChange}
          values={
            values && values.org_type
              ? {
                  value: values?.org_type,
                  label: values?.org_type,
                }
              : null
          }
          isMulti={false}
        />

        <div className={styles.pageBtn}>
          <Button
            label="Next"
            type="submit"
            id="nextbtn"
            border="none"
            background="var(--secondaryColor)"
            fontSize="var(--secondaryFont)"
            flexDirection="row-reverse"
            Icon={<RightArrowIcon fill="#fff" />}
          />
        </div>
      </div>
    </div>
  );
};

export default StepOne_OrgDetails;
